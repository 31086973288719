import React, { useCallback } from 'react';
import ChatMessage from '@pages/components/ChatMessage';
import { Container, ListContainer, LoadingWrap } from './style';
import AutoSizer from 'react-virtualized-auto-sizer';
import LoadingCircle from '@components/Loading';
import { useMessageBox } from '@providers/MessageBoxProvider';
import { useRecoilValue } from 'recoil';
import { selectedUserState } from '@store/EngagementUserListAtom';
const MessageBox: React.FC = () => {
  const { current, listRef, handleScroll, handleResend, onJumpToMessage, _renderDownButton } = useMessageBox();
  const { chatHistory } = current.context;
  const selectedUser = useRecoilValue(selectedUserState);
  const _renderListContainer = useCallback(
    ({ height, width }) => {
      return (
        <ListContainer style={{ height, width }}>
          {chatHistory.messages?.map((message) => {
            return (
              <ChatMessage
                key={message.getId}
                message={message}
                channel={selectedUser?.channel?.type}
                onResend={handleResend}
                onJumpToMessage={onJumpToMessage}
              />
            );
          })}
          {/* spacing */}
          <div className="h-[40px]" />
        </ListContainer>
      );
    },
    [
      chatHistory.messages,
      current.matches({ chatHistoryState: 'sendMessageFailed' }),
      current.matches({ chatHistoryState: 'sendMessageSuccess' }),
    ],
  );

  return (
    <>
      <Container ref={listRef} onScroll={handleScroll}>
        {current.hasTag('loading-previous') && (
          <LoadingWrap>
            <LoadingCircle />
          </LoadingWrap>
        )}
        <AutoSizer>{_renderListContainer}</AutoSizer>
        {current.hasTag('loading-next') && (
          <LoadingWrap>
            <LoadingCircle />
          </LoadingWrap>
        )}
      </Container>
      {_renderDownButton()}
    </>
  );
};

export default MessageBox;
