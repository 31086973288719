import { InputState } from 'react-input-mask';

export const beforeMaskedValueChange = (newState: InputState) => {
  let { value, selection } = newState;
  const currentPosition = selection?.start; // positon of cursor
  if (currentPosition === 1) {
    // first position of hour X0 : 00 can be only 0,1 or 2
    if (!/[0-2]/.test(value[0])) {
      value = '0' + value.slice(2);
      selection = {
        start: 0,
        end: 0,
      };
    }
  }

  if (currentPosition === 5) {
    // second position of hour 0X : 00 can be only 0-3 if first position is 2
    if (value[0] === '2') {
      if (!/[0-3]/.test(value[1])) {
        value = value.slice(0, 1) + '0' + value.slice(3);
        selection = {
          start: 1,
          end: 1,
        };
      }
    }
  }

  if (currentPosition === 6) {
    // first postion of minute 00 : X0 can be only 0-5
    if (!/[0-5]/.test(value[5])) {
      value = value.slice(0, 4) + '0' + value.slice(7);
      selection = {
        start: 5,
        end: 5,
      };
    }
  }

  return {
    value,
    selection,
  };
};
