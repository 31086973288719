import { AnyEventObject, createMachine, send } from 'xstate';
import { getGeneralConfig, saveGeneralConfig } from '@api/livechat/Settings';
import { IGeneralConfig } from '@types';

export type IGeneralConfigEvent = {
  type: string;
  autoAssign: boolean;
  maxTicket: number;
  SLATime: {
    hour: string;
    minute: string;
  };
  isCollaborationModeEnabled: boolean;
  isWrapUpTicketRequired: boolean;
  autoAssignRoundRobin: boolean;
  autoAssignLoadBalanced: boolean;
  autoAssignRandom: boolean;
  isCustomNameEditorEnabled: boolean;
};
export type IHook = {
  enrich: string | null;
  outgoing: string | null;
  template: string | null;
  thread: string | null;
};

export type IPublicHolidayPayload = {
  year: number;
  dates: {
    date: string;
    name: string;
  }[];
};

export type IPublicHoliday = {
  year: number;
  dates: {
    date: string;
    name: string;
    dateErrorMessage?: string;
    nameErrorMessage?: string;
  }[];
};
export type IAccountConfig = {
  _id: string;
  autoResolve: {
    enable: boolean;
    after: number;
    defaultMessage: string;
  };
  loadingAnimation: {
    enabled: boolean;
    loadingSeconds: number;
  };
  hooks: IHook;
  enabledFeatures: string[];
  maxTicket: number;
  autoAssign: boolean;
  isCollaborationModeEnabled: boolean;
  isWrapUpTicketRequired: boolean;
  queue: {
    enable: boolean;
    every: number;
    defaultMessage: string;
  };
  SLATime: {
    hour: string;
    minute: string;
  };
  autoAssignMethod: 'RANDOM' | 'ROUND_ROBIN' | 'LOAD_BALANCED';
  isCustomNameEditorEnabled: boolean;
  publicHolidays?: IPublicHoliday[];
};

const getConfig = async (context: IGeneralConfig) => {
  try {
    const result = await getGeneralConfig();
    const currentAutoAssignMethod = {
      autoAssignRandom: false,
      autoAssignRoundRobin: false,
      autoAssignLoadBalanced: false,
    };
    if (result.data.autoAssignMethod === 'RANDOM') {
      currentAutoAssignMethod.autoAssignRandom = true;
    } else if (result.data.autoAssignMethod === 'ROUND_ROBIN') {
      currentAutoAssignMethod.autoAssignRoundRobin = true;
    } else {
      currentAutoAssignMethod.autoAssignLoadBalanced = true;
    }
    context.accountConfig = {
      ...result.data,
      ...currentAutoAssignMethod,
    };
    return context;
  } catch (error) {
    throw error;
  }
};

const saveConfig = async (_context: IGeneralConfig, event: IGeneralConfigEvent) => {
  try {
    const {
      maxTicket,
      autoAssign,
      SLATime,
      isCollaborationModeEnabled,
      autoAssignLoadBalanced,
      autoAssignRoundRobin,
      isCustomNameEditorEnabled,
    } = event;
    const newSla = {
      hour: Number(SLATime.hour),
      minute: Number(SLATime.minute),
    };
    let autoAssignMethod: 'RANDOM' | 'ROUND_ROBIN' | 'LOAD_BALANCED' = 'RANDOM';
    if (autoAssignLoadBalanced) {
      autoAssignMethod = 'LOAD_BALANCED';
    } else if (autoAssignRoundRobin) {
      autoAssignMethod = 'ROUND_ROBIN';
    }

    const result = await saveGeneralConfig({
      maxTicket,
      autoAssign,
      SLATime: newSla,
      isCollaborationModeEnabled,
      isWrapUpTicketRequired: _context.accountConfig.isWrapUpTicketRequired,
      autoAssignMethod,
      isCustomNameEditorEnabled,
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const defaultConfig: IAccountConfig = {
  _id: '',
  autoResolve: {
    enable: false,
    after: 0,
    defaultMessage: '',
  },
  hooks: {
    enrich: null,
    outgoing: null,
    template: null,
    thread: null,
  },
  loadingAnimation: {
    enabled: false,
    loadingSeconds: 5,
  },
  enabledFeatures: [],
  maxTicket: 0,
  autoAssign: false,
  isCollaborationModeEnabled: false,
  isWrapUpTicketRequired: false,
  autoAssignMethod: 'RANDOM',
  isCustomNameEditorEnabled: true,
  queue: {
    enable: false,
    every: 0,
    defaultMessage: '',
  },
  SLATime: {
    hour: '0',
    minute: '0',
  },
};

const generalSettingMachine = (initial: IAccountConfig = defaultConfig) =>
  createMachine<IGeneralConfig, IGeneralConfigEvent>(
    {
      initial: 'idle',
      context: {
        accountConfig: {
          ...initial,
          autoAssignRandom: initial.autoAssignMethod === 'RANDOM',
          autoAssignRoundRobin: initial.autoAssignMethod === 'ROUND_ROBIN',
          autoAssignLoadBalanced: initial.autoAssignMethod === 'LOAD_BALANCED',
          // if initial is null or undefined set to true otherwise set to initial value
          isCustomNameEditorEnabled:
            initial.isCustomNameEditorEnabled === null || initial.isCustomNameEditorEnabled === undefined
              ? true
              : initial.isCustomNameEditorEnabled,
        },
      },
      states: {
        idle: {},
        fetching: {
          invoke: {
            id: 'fetching-config',
            src: getConfig,
            onDone: 'fetchSuccess',
            onError: 'fetchFail',
          },
        },
        fetchSuccess: {
          on: {
            EDITING: {
              target: 'editing',
            },
            SAVE: {
              target: 'saving',
            },
          },
        },
        editing: {
          on: {
            SAVE: {
              target: 'saving',
            },
            DISCARD: {
              target: 'fetchSuccess',
            },
          },
        },
        saving: {
          invoke: {
            id: 'save-config',
            src: saveConfig,
            onDone: {
              target: 'saveSuccess',
              actions: 'updateConfig',
            },
            onError: 'saveFail',
          },
        },
        fetchFail: {
          on: {
            EDITING: {
              target: 'editing',
            },
          },
        },
        saveSuccess: {},
        saveFail: {
          on: {
            EDITING: {
              target: 'editing',
            },
            IDLE: {
              target: 'idle',
            },
          },
        },
      },
      on: {
        FETCHING: {
          target: 'fetching',
        },
        EDITING: {
          target: 'editing',
        },
        SAVE: {
          target: 'saving',
        },
        UPDATE_CONFIG: {
          actions: ['updateConfig'],
        },
      },
    },
    {
      actions: {
        updateConfig: (context, event: AnyEventObject) => {
          context.accountConfig = {
            ...event.data,
            autoAssignRandom: event.data?.autoAssignMethod === 'RANDOM',
            autoAssignRoundRobin: event.data?.autoAssignMethod === 'ROUND_ROBIN',
            autoAssignLoadBalanced: event.data?.autoAssignMethod === 'LOAD_BALANCED',
          };
        },
      },
    },
  );

export default generalSettingMachine;
