import dayjs from 'dayjs';

export const formatDate = (time?: string | number, pattern = 'MMMM D, YYYY HH:mm') => {
  if (!time) return null;
  return dayjs(time).format(pattern);
};

export const convertSecToHrs = (seconds: number) => {
  if (!seconds) return '00:00:00';
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  const newHours = hours < 10 ? `0${hours}` : hours;
  const newMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const newSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
  return `${newHours}:${newMinutes}:${newSeconds}`;
};

export const getCurrentDate = () => {
  return dayjs();
};

export const getStartOfMonth = (date: dayjs.Dayjs) => {
  return date.startOf('month');
};

export const getDate = (date: string, format?: string) => {
  if (format) return dayjs(date, format);
  return dayjs(date);
};
