import React from 'react';
import useMeasure from 'react-use-measure';

type IPopoverCustomPosProps = {
  dimensions: {
    top: number;
    left: number;
    width: number;
    height: number;
  };
  children: React.ReactNode;
};

export const PopoverCustomPos = ({ dimensions, children }: IPopoverCustomPosProps) => {
  const [ref, dimen] = useMeasure();

  return (
    <div
      id="popover-custom-pos"
      className="fixed z-10"
      ref={ref}
      style={{
        left: dimensions.left + dimensions.width,
        top: dimensions.top,
        display: dimensions.top === 0 && dimensions.left === 0 ? 'none' : 'block',
      }}
    >
      {children}
    </div>
  );
};
