import { Font12G5W400, Font14G3W500, Font16G1W400 } from '@components/UtilsComponent';
import { TimelineHeader } from '@components/WorkingHour/TimelineHeader';
import { WorkingHourBar } from '@components/WorkingHour/WorkingHourBar';
import useTranslation from '@hooks/useTranslation';
import { Row } from 'antd';
import React, { useState } from 'react';
import { CheckBoxWrap, Item } from '../NewTeamEditor/style';
import FormItem from '@components/FormItem';
import { Checkbox } from '@components/Checkbox';
import { faPen } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITeamData } from '../EditTeamEditor/editTeamEditorMachine';
import { IPublicHolidayFE, IWorkHourTimeSlot } from '@types';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { PublicHolidayModal } from './PublicHolidayModal';
import { PUBLIC_HOLIDAY_KEYS } from '@configs/constants';

type IWorkingHourBlockProps = {
  days: ITeamData['workHours']['days'];
  publicHolidayTimes: IPublicHolidayFE;
  onCheckPublicHoliday: (e: CheckboxChangeEvent) => void;
  onUpdateWorkHours: (day: string, payload: IWorkHourTimeSlot[]) => void;
  onUpdatePublicHolidayTimes: (payload: IWorkHourTimeSlot[]) => void;
};

export const WorkingHourBlock = ({
  days,
  publicHolidayTimes,
  onUpdateWorkHours,
  onCheckPublicHoliday,
  onUpdatePublicHolidayTimes,
}: IWorkingHourBlockProps) => {
  const t = useTranslation();
  const [publicHolidayVisibile, setPublicHolidayVisibile] = useState(false);
  const onUpdateGeneralWorkHours = (day: string, payload: IWorkHourTimeSlot[], isApplyToAll: boolean) => {
    if (isApplyToAll) {
      replaceSameSetting(payload);
    } else {
      onUpdateWorkHours(day, payload);
    }
  };

  const onUpdatePublicHoildayWorkHours = (payload: IWorkHourTimeSlot[], isApplyToAll: boolean) => {
    if (isApplyToAll) {
      replaceSameSetting(payload);
    } else {
      onUpdatePublicHolidayTimes(payload);
    }
  };

  const replaceSameSetting = (payload: IWorkHourTimeSlot[]) => {
    const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    days.forEach((day) => {
      onUpdateWorkHours(day, payload);
    });
    onUpdatePublicHolidayTimes(payload);
  };

  return (
    <>
      <Font14G3W500>{t('livechat.team.management.team.editor.working.hour.hint')}</Font14G3W500>
      <div className="h-[12px]" />
      <TimelineHeader />
      <div className="mt-[16px]">
        <div className="flex flex-col gap-y-[12px]">
          {Object.keys(days).map((key: string) => {
            const day = days[key as keyof typeof days];
            return (
              <div key={key}>
                <WorkingHourBar name={key} workingHourTimeSlots={day} onUpdateWorkHours={onUpdateGeneralWorkHours} />
              </div>
            );
          })}
          {publicHolidayTimes?.enabled && (
            <WorkingHourBar
              name={PUBLIC_HOLIDAY_KEYS}
              workingHourTimeSlots={publicHolidayTimes.timeslots}
              onUpdateWorkHours={(_, payload, isApplytoAll) => onUpdatePublicHoildayWorkHours(payload, isApplytoAll)}
            />
          )}
        </div>
      </div>
      <div className="mt-[16px] flex justify-center items-center gap-x-[16px]">
        <div className="flex gap-x-[8px] items-center">
          <div className="w-[8px] h-[8px] rounded-full bg-primary" />
          <Font12G5W400>{t('livechat.team.management.team.editor.working.hour.label')}</Font12G5W400>
        </div>
        <div className="flex gap-x-[8px] items-center">
          <div className="w-[8px] h-[8px] rounded-full bg-[#F5F5F5]" />
          <Font12G5W400>{t('livechat.team.management.team.editor.outide.of.work.label')}</Font12G5W400>
        </div>
      </div>
      {/* Public holiday */}
      <Row className="mt-[32px]">
        <CheckBoxWrap>
          <Item>
            <FormItem noStyle name="enablePublicHolidays" valuePropName="checked">
              <Checkbox onChange={onCheckPublicHoliday} />
            </FormItem>
          </Item>
          <Item>
            <Font16G1W400>
              {t('livechat.team.management.team.editor.public.holiday.label', {
                publicHoliday: (
                  <div className="inline-block text-primary border-b border-primary border-solid">
                    <button type="button" onClick={() => setPublicHolidayVisibile(true)}>
                      <div className="flex gap-x-[4px] items-center">
                        <div className="text-primary">
                          {t('livechat.team.management.team.editor.public.holiday.word')}
                        </div>

                        <FontAwesomeIcon icon={faPen} />
                      </div>
                    </button>
                  </div>
                ),
              })}
            </Font16G1W400>
          </Item>
        </CheckBoxWrap>
      </Row>

      <Row>
        <CheckBoxWrap>
          <Item>
            <FormItem noStyle name="enableOffHourAssignment" valuePropName="checked">
              <Checkbox />
            </FormItem>
          </Item>
          <Item>
            <Font16G1W400>{t('livechat.team.management.team.editor.receive.ticket.label')}</Font16G1W400>
          </Item>
        </CheckBoxWrap>
      </Row>

      <Row>
        <CheckBoxWrap>
          <Item>
            <FormItem noStyle name="enableSLAPause" valuePropName="checked">
              <Checkbox />
            </FormItem>
          </Item>
          <Item>
            <Font16G1W400>{t('livechat.team.management.team.editor.pause.sla.label')}</Font16G1W400>
          </Item>
        </CheckBoxWrap>
      </Row>

      {/* <Row>
        <CheckBoxWrap className="items-start">
          <Item>
            <FormItem noStyle name="enableOffHourAutoReply" valuePropName="checked">
              <Checkbox />
            </FormItem>
          </Item>
          <Item>
            <Font16G1W400>{t('livechat.team.management.team.editor.auto.reply.label')}</Font16G1W400>
            <Font12G3W400 className="mt-[4px]">
              {t('livechat.team.management.team.editor.auto.reply.description', {
                notWord: <span className="text-[#F72C40]">{t('not')}</span>,
              })}
            </Font12G3W400>
          </Item>
        </CheckBoxWrap>
      </Row> */}
      <PublicHolidayModal
        visible={publicHolidayVisibile}
        onToggle={() => {
          setPublicHolidayVisibile(!publicHolidayVisibile);
        }}
      />
    </>
  );
};
