import React, { useCallback, useEffect, useRef } from 'react';
import { DropdownDynamicBtn } from '@components/Dropdown';
import { useInfinityLoadByCursorId } from '@hooks/useInfinityLoadByCursorId';
import { IChannel } from '@types';
import { IOption } from '@components/Select';
import { IChannelParams, getChannels } from '@api/livechat/Settings';
import { SelectBtn } from '@components/SelectBtn';
import { Checkbox } from '@components/Checkbox';
import { DropdownItem } from './style';
import { cloneDeep } from 'lodash';
import getLogoByChannelType from '@utils/getLogoByChannelType';
import EChannelType from '@enums/ChannelType';

type IChannelFilterProps = {
  selectedChannels: string[];
  onSelectChannel: (items: string[]) => void;
};

export const ChannelFilter = ({ selectedChannels = [], onSelectChannel }: IChannelFilterProps) => {
  const { data, searchKeyword, firstLoading, hasMore, onOpenDropdown, onLoadmore, onUpdateSearchKeyword } =
    useInfinityLoadByCursorId<IChannel, IOption>({
      fetchFn: async (filter, cancelToken) => {
        const newFilter: IChannelParams = {
          ...filter,
        };
        const { data }: { data: IChannel[] } = await getChannels(newFilter, cancelToken);
        const result = data.map((item) => {
          return {
            ...item,
            label: item.name,
            value: item.id,
          };
        });
        return result;
      },
    });
  const selectedRef = useRef<string[]>([]);
  const _renderSelectedText = useCallback((): React.ReactNode => {
    // if length more than 1
    if (selectedChannels.length > 1) {
      return `${selectedChannels.length} channels selected`;
    }
    if (selectedChannels.length === 1) {
      return `1 channel selected`;
    }
    return '';
  }, [selectedChannels]);

  useEffect(() => {
    selectedRef.current = selectedChannels;
  }, [selectedChannels]);

  return (
    <>
      <DropdownDynamicBtn
        showClearAll
        onClearAllSelection={() => {
          selectedRef.current = [];
          onSelectChannel(selectedRef.current);
        }}
        searchKeyword={searchKeyword}
        onUpdateSearchKeyword={onUpdateSearchKeyword}
        options={data}
        isFirstLoading={firstLoading}
        renderOptions={(options) => {
          return options.map((item) => {
            return (
              <DropdownItem
                title={item.label}
                key={item.value}
                onClick={() => {
                  const clone = cloneDeep(selectedRef.current);
                  const index = clone.findIndex((i) => {
                    return i === item.id;
                  });
                  // check if exist then remove
                  if (index > -1) {
                    clone.splice(index, 1);
                    onSelectChannel(clone);
                    return;
                  }
                  clone.push(item.id);
                  selectedRef.current = clone;
                  onSelectChannel(clone);
                }}
              >
                <img className="mr-2" width={24} height={24} src={getLogoByChannelType(item.type as EChannelType)} />
                <span>{item.label}</span>
                <Checkbox
                  checked={
                    !!selectedChannels.find((i) => {
                      return i === item.id;
                    })
                  }
                />
              </DropdownItem>
            );
          });
        }}
        renderBtn={function (
          prevState: boolean,
          setter: (status: React.SetStateAction<boolean>) => void,
        ): React.ReactNode {
          return (
            <SelectBtn
              data-testid="test-channel-filter-btn"
              selectedText={_renderSelectedText()}
              placeholder={'All Channels'}
              onClick={() => {
                // toggle dropdown
                setter(!prevState);
                if (!prevState) {
                  onOpenDropdown();
                }
              }}
            />
          );
        }}
        hasMore={hasMore}
        onLoadMore={onLoadmore}
      />
    </>
  );
};
