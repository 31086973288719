import React from 'react';
import Avatar from '@components/Avatar';
import { Font12G1W400, Font14G1W400, Font14G5W400 } from '@components/UtilsComponent';
import { EMessageType } from '@enums/MessageType';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IReplyMessageInstanceData } from '@model/MessageItem/ReplyMessage';
import useTranslation from '@hooks/useTranslation';
import { STICKER_PREFIX } from '@configs/constants';
import useSticker from '../Sticker/useSticker';
import { twMerge } from 'tailwind-merge';

type IReplyMessageProps = {
  side: 'left' | 'right';
} & IReplyMessageInstanceData;

const getMedia = (messageType: EMessageType, side: 'left' | 'right', url?: string, text?: string) => {
  const mediaClass = side === 'right' ? 'order-1' : '';
  if (messageType === EMessageType.IMAGE) {
    return (
      <div className={`flex items-center justify-center ${mediaClass}`}>
        <img src={url} alt="media" className="w-[44px] h-[44px] rounded-[4px] object-cover" />
      </div>
    );
  }
  if (messageType === EMessageType.VIDEO) {
    return (
      <div className={`flex items-center justify-center relative ${mediaClass}`}>
        <FontAwesomeIcon
          icon={faPlay}
          className="text-[12px] absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-white z-10"
        />
        <div className="absolute w-[44px] h-[44px] rounded-[4px] bg-[rgba(0,0,0,0.5)]" />
        <div className="w-[44px] h-[44px] rounded-[4px]" />
      </div>
    );
  }

  if (messageType === EMessageType.TEXT && text) {
    if (text.includes(STICKER_PREFIX)) {
      return (
        <div className={`flex items-center justify-center ${mediaClass}`}>
          <StickerReplyMessage url={text || ''} />
        </div>
      );
    }
    return null;
  }

  return null;
};

export const ReplyMessage = (props: IReplyMessageProps) => {
  const t = useTranslation();
  const { side } = props;
  const getDescription = (messageType: EMessageType, text: string, url?: string, altText?: string) => {
    if (messageType === EMessageType.IMAGE && url) {
      return <Font14G5W400>{t('reply.message.image.label')}</Font14G5W400>;
    }
    if (messageType === EMessageType.VIDEO && url) {
      return <Font14G5W400>{t('reply.message.video.label')}</Font14G5W400>;
    }
    if (messageType === EMessageType.LINK && url) {
      return <Font14G5W400>{url}</Font14G5W400>;
    }
    if (messageType === EMessageType.TEXT && text && text.includes(STICKER_PREFIX)) {
      return <Font14G5W400>{t('reply.message.sticker.label')}</Font14G5W400>;
    }
    if (
      (messageType === EMessageType.FLEX ||
        messageType === EMessageType.OPTION ||
        messageType === EMessageType.CAROUSEL ||
        messageType === EMessageType.IMAGE_CAROUSEL) &&
      altText
    ) {
      return <Font14G5W400>{altText}</Font14G5W400>;
    }
    return <Font14G5W400>{text}</Font14G5W400>;
  };
  const wrapperClass =
    side === 'left'
      ? twMerge(
          'rounded-[12px] rounded-tl-none bg-[#EEE] p-[8px] max-w-[400px] flex flex-col items-start justify-center gap-[8px] text-left',
        )
      : twMerge(
          'rounded-[12px] rounded-tr-none bg-[#424242] p-[8px] max-w-[400px] flex flex-col items-end justify-center gap-[8px] text-left',
        );
  const contentClass =
    side === 'left'
      ? twMerge('flex gap-[12px] rounded-tr-[4px] w-full rounded-br-[4px] bg-[#FAFAFA] pr-[8px]')
      : twMerge('flex gap-[12px] rounded-tr-[4px] w-full rounded-br-[4px] bg-[#616161] pr-[8px] order-');

  const lineClass =
    side === 'left'
      ? twMerge('min-w-[2px] h-auto rounded-[8px] bg-[#424242]')
      : twMerge('min-w-[2px] h-auto rounded-[8px] bg-[#EEEE] order-last');

  const replyToWrapClass =
    side === 'left'
      ? twMerge('flex py-[8px] flex-col gap-[4px]')
      : twMerge('flex py-[8px] flex-col gap-[4px] items-end');
  const replyTextClassName = side === 'right' ? twMerge('!text-white') : undefined;
  return (
    <div className={wrapperClass}>
      <div className={contentClass}>
        <div className={lineClass} />

        {props.replyTo
          ? getMedia(props.replyTo.data.messageType, side, props.replyTo.data.url, props.replyTo.data.text)
          : null}
        {props.replyTo ? (
          <div className={replyToWrapClass}>
            <ReplyTopSection
              side={side}
              senderName={props.replyTo.data.senderName || ''}
              senderImage={props.replyTo.data.senderImage || ''}
            />
            {getDescription(
              props.replyTo.data.messageType,
              props.replyTo.data.text,
              props.replyTo.data.url,
              props.replyTo.data.altText,
            )}
          </div>
        ) : null}
      </div>
      <div>
        {props.text.includes(STICKER_PREFIX) ? (
          <StickerReplyMessage url={props.text} width={200} height={200} />
        ) : (
          <Font14G1W400 className={replyTextClassName}>{props.text}</Font14G1W400>
        )}
      </div>
    </div>
  );
};

const getSenderImage = (name: string, img?: string) => {
  if (img) {
    return <Avatar width={20} height={20} img={img} />;
  }
  return <Avatar fontSize={9} width={20} height={20} name={name} />;
};

const ReplyTopSection = ({
  senderName,
  senderImage,
  side,
}: {
  senderName: string;
  senderImage?: string;
  side: 'left' | 'right';
}) => {
  const avatarClass = side === 'left' ? twMerge('w-[20px] h-[20px] order-0') : twMerge('w-[20px] h-[20px] order-2');
  const senderClass = side === 'right' ? twMerge('!text-white') : undefined;
  return (
    <div className="flex gap-[4px] items-center">
      <div className={avatarClass}>{getSenderImage(senderName, senderImage)}</div>
      <Font12G1W400 className={senderClass}>{senderName}</Font12G1W400>
    </div>
  );
};

const StickerReplyMessage = ({ url, width = 40, height = 40 }: { url: string; width?: number; height?: number }) => {
  const stickerData = url.replace(STICKER_PREFIX, '').split('_').pop() || '';
  const { ref, onError } = useSticker(stickerData);
  return <img ref={ref} className="object-contain" width={width} height={height} onError={onError} />;
};
