import React from 'react';
import { Font16G1W400 } from '@components/UtilsComponent';
import { faPen } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IWorkHourTimeSlot } from '@types';
import { convertTimeToMin } from '@utils/time';
import { useConfirm } from '@hooks/useConfirm';
import { WorkingHourModal } from '@pages/desktop/team_management/WorkingHourBlock/WorkingHourModal';
import { PUBLIC_HOLIDAY_KEYS } from '@configs/constants';
import useTranslation from '@hooks/useTranslation';

type IWorkingHourBarProps = {
  name: string;
  workingHourTimeSlots: IWorkHourTimeSlot[];
  onUpdateWorkHours: (day: string, payload: IWorkHourTimeSlot[], isApplytoAll: boolean) => void;
};
const DIVIDER = 16.66667;
const TIME_ARRAY = ['00:00', '04:00', '08:00', '12:00', '16:00', '20:00', '24:00'];
const TIME_SPAN = 1440;
export const WorkingHourBar = ({ name, workingHourTimeSlots, onUpdateWorkHours }: IWorkingHourBarProps) => {
  const t = useTranslation();
  const { visible, onToggle } = useConfirm();
  return (
    <div className="flex w-full gap-x-[16px]">
      <div className="flex min-w-[120px] justify-end">
        {name === PUBLIC_HOLIDAY_KEYS ? (
          <Font16G1W400 className="first-letter:capitalize whitespace-nowrap !text-primary">
            {t('livechat.team.management.team.editor.public.holiday')}
          </Font16G1W400>
        ) : (
          <Font16G1W400 className="first-letter:capitalize whitespace-nowrap">{name}</Font16G1W400>
        )}
      </div>
      <div className="flex w-full bg-[#F5F5F5] relative">
        {TIME_ARRAY.map((_, index) => {
          return (
            <div
              key={index}
              className="absolute border-r h-full z-10"
              style={{
                left: `${index * DIVIDER}%`,
                borderRight: index === 0 || index === TIME_ARRAY.length - 1 ? 'none' : '1px solid #E5E5E5',
              }}
            />
          );
        })}

        {workingHourTimeSlots.map((timeSlot, index) => {
          const startTime = convertTimeToMin(timeSlot.startHour, timeSlot.startMinute);
          const endTime = convertTimeToMin(timeSlot.endHour, timeSlot.endMinute);
          const left = (startTime / TIME_SPAN) * 100;
          const width = ((endTime - startTime) / 1440) * 100;
          return (
            <div
              key={index}
              className="working-hour-range absolute bg-primary h-full"
              style={{
                left: `${left}%`,
                width: `${width}%`,
              }}
            />
          );
        })}
      </div>
      <div className="flex justify-end">
        <button onClick={onToggle} type="button">
          <FontAwesomeIcon icon={faPen} className="text-[16px]" />
        </button>
      </div>
      <WorkingHourModal
        label={name}
        timeSlots={workingHourTimeSlots}
        visible={visible}
        onToggle={onToggle}
        onConfirm={(newTimeSlot, isApplytoAll) => {
          onUpdateWorkHours(name, newTimeSlot, isApplytoAll);
          onToggle();
        }}
      />
    </div>
  );
};
