import React, { useCallback, useState } from 'react';
import LoadingCircle from '@components/Loading';
import { CombineAllMessage, LoadingMessage, TicketMessage, DateMessage, IRealMessage } from '@model/MessageItem';
import { BadgeDate, BadgeTicket } from '../Message/Badge';
import { isPersonMessage } from '@providers/MessageBoxProvider/fns';
import {
  Container,
  Badge,
  AgentSide,
  UserSide,
  MessageWrap,
  CustomFontAweSomeIcon,
  TimeText,
  FailAction,
  RetryButton,
} from './style';
import Avatar from '@components/Avatar';
import {
  CarouselMessage,
  ImageMessage,
  OptionMessage,
  TextMessage,
  VideoPlayerMessage,
  LinkMessage,
  FlexMessage,
  ImagemapMessage,
  ImageCarouselMessage,
  ReplyMessage,
} from '../Message';
import * as MessageItemModel from '@model/MessageItem';
import { getSplitName } from '@utils/splitName';
import { isMobile } from 'react-device-detect';
import { AudioPlayerMessage } from '../Message/AudioPlayer';
import { StickerMessage } from '../Message/Sticker';
import { EMessageStatus } from '@enums/MessageStatus';
import { Font14G5W500, Font14PrimaryW500, Icon } from '@components/UtilsComponent';
import { useTheme } from 'styled-components';
import {
  faSpinnerThird as fasSpinnerThird,
  faExclamationCircle as fasExclamtionCircle,
  faBullseyePointer,
  faReply,
} from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { LocationMessage } from '../Message/Location';
import { Popover } from '@components/Popover';
import { PreviewContent } from '@components/MessagePreview/PreviewContent';
import './style.css';
import useTranslation from '@hooks/useTranslation';
import { PostbackMessage } from '../Message/Postback';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EPostbackType } from '@model/MessageItem/PostbackMessage';
import root from 'react-shadow';
import { decodeHTML } from '@utils/html';
import { IJumpMessageParams } from '@types';
import { useReplyMessage } from '@providers/ReplyMessageProvider';
import EChannelType from '@enums/ChannelType';
export type IChatMessageV2 = {
  message: CombineAllMessage;
  channel?: EChannelType;
  onResend?: (message: IRealMessage) => void;
  onJumpToMessage?: (params: IJumpMessageParams) => void;
};

const ChatMessage: React.FC<IChatMessageV2> = React.memo(
  ({ message, channel, onJumpToMessage, onResend }: IChatMessageV2) => {
    const theme: any = useTheme();
    const { setSelectedReplyMessage } = useReplyMessage();
    const [onHover, setOnHover] = useState(false);
    const t = useTranslation();
    const _renderRetryIcon = useCallback((messageData: IRealMessage) => {
      // send message failed and message status is sent
      if (messageData.getRetry && messageData.getMessageStatus === EMessageStatus.FAILED) {
        return <CustomFontAweSomeIcon icon={fasExclamtionCircle as IconProp} />;
      }

      return null;
    }, []);

    const _renderRetryButton = useCallback((messageData: IRealMessage) => {
      // retry is true and message status is failed
      if (messageData.getRetry && messageData.getMessageStatus === EMessageStatus.FAILED) {
        return (
          <FailAction style={{ marginRight: messageData.instanceData.level === 2 && !isMobile ? 40 : 0 }}>
            <RetryButton onClick={() => (onResend ? onResend(messageData) : false)}>
              {t('chat.message.resend.text')}
            </RetryButton>
          </FailAction>
        );
      }
      // retry is true and message status is sending
      if (messageData.getRetry && messageData.getMessageStatus === EMessageStatus.SENDING) {
        return (
          <FailAction style={{ marginRight: messageData.instanceData.level === 2 && !isMobile ? 40 : 0 }}>
            <Icon icon={fasSpinnerThird as IconProp} spin style={{ color: theme.PRIMARY }} />
          </FailAction>
        );
      }
      return null;
    }, []);

    const _renderMessageType = (messageData: CombineAllMessage) => {
      if (messageData instanceof LoadingMessage) {
        return (
          <Badge>
            <LoadingCircle />
          </Badge>
        );
      }

      if (messageData instanceof TicketMessage) {
        return (
          <Badge>
            <BadgeTicket>
              <span>{messageData.instanceData.text}</span>
            </BadgeTicket>
          </Badge>
        );
      }
      if (messageData instanceof DateMessage) {
        return (
          <>
            {messageData.isToday() ? (
              <Badge>
                <BadgeDate>{t('chat.message.today.text')}</BadgeDate>
              </Badge>
            ) : (
              <Badge>
                <BadgeDate>{messageData.formatDate('DD MMM')}</BadgeDate>
              </Badge>
            )}
          </>
        );
      }
      if (messageData instanceof isPersonMessage(messageData) && (messageData.isAgent() || messageData.isBot())) {
        const { level, senderName } = messageData.instanceData;
        return (
          <>
            {isMobile ? (
              <AgentSide style={{ marginRight: 12, paddingTop: level === 1 ? 16 : 0 }}>
                <div>
                  {level === 1 && <span>{senderName}</span>}
                  <MessageWrap style={{ marginTop: 4 }}>
                    <MessageItem
                      messageItem={messageData}
                      side={'right'}
                      onResend={onResend}
                      onJumpToMessage={onJumpToMessage}
                    />
                    {_renderRetryIcon(messageData)}
                    <TimeText>{messageData.formatDate('HH:mm')}</TimeText>
                  </MessageWrap>
                  {_renderRetryButton(messageData)}
                </div>
              </AgentSide>
            ) : (
              <AgentSide style={{ paddingTop: level === 1 ? 16 : 0 }}>
                {level === 1 && (
                  <span>
                    <Avatar width={40} height={40} img={messageData.getSenderImage} name={getSplitName(senderName)} />
                  </span>
                )}
                <div>
                  {level === 1 && <span>{senderName}</span>}
                  <MessageWrap
                    style={{ marginRight: level === 2 ? 40 : 0, marginTop: 4 }}
                    onMouseEnter={() => {
                      if (
                        (message.messageType === 'text' ||
                          message.messageType === 'image' ||
                          message.messageType === 'video') &&
                        channel === EChannelType.LINE
                      ) {
                        setOnHover(true);
                      }
                    }}
                    onMouseLeave={() => {
                      if (
                        (message.messageType === 'text' ||
                          message.messageType === 'image' ||
                          message.messageType === 'video') &&
                        channel === EChannelType.LINE
                      ) {
                        setOnHover(false);
                      }
                    }}
                  >
                    <MessageItem
                      messageItem={messageData}
                      side={'right'}
                      onResend={onResend}
                      onJumpToMessage={onJumpToMessage}
                    />
                    {_renderRetryIcon(messageData)}
                    {!onHover ? (
                      <TimeText>{messageData.formatDate('HH:mm')}</TimeText>
                    ) : (
                      <button
                        className="focus:bg-[#EEE] w-[32px] h-[32px] rounded-[24px] p-[8px] transform-gpu"
                        style={{
                          transform: 'rotateY(180deg) rotateX(360deg)',
                        }}
                        onClick={() => {
                          setSelectedReplyMessage(messageData);
                          const composer = document.getElementById('message-composer');
                          if (composer) {
                            composer.focus();
                          }
                        }}
                      >
                        <FontAwesomeIcon className="text-[#9E9E9E]" icon={faReply} />
                      </button>
                    )}
                  </MessageWrap>
                  {_renderRetryButton(messageData)}
                </div>
              </AgentSide>
            )}
          </>
        );
      } else {
        const { level, senderName } = messageData.instanceData;
        return (
          <>
            {isMobile ? (
              <UserSide style={{ marginLeft: 12, paddingTop: level === 1 ? 16 : 0 }}>
                <div>
                  {level === 1 && <span>{senderName}</span>}
                  <MessageWrap style={{ marginTop: 4 }}>
                    <MessageItem messageItem={messageData} side={'left'} onJumpToMessage={onJumpToMessage} />
                    <TimeText>{messageData.formatDate('HH:mm')}</TimeText>
                  </MessageWrap>
                </div>
              </UserSide>
            ) : (
              <UserSide style={{ paddingTop: level === 1 ? 16 : 0 }}>
                {level === 1 && (
                  <span>
                    <Avatar width={40} height={40} img={messageData.getSenderImage} />
                  </span>
                )}
                <div>
                  {level === 1 && <span>{senderName}</span>}
                  <MessageWrap
                    style={{ marginLeft: level === 2 ? 40 : 0, marginTop: 4 }}
                    onMouseEnter={() => {
                      if (
                        (message.messageType === 'text' ||
                          message.messageType === 'image' ||
                          message.messageType === 'video') &&
                        channel === EChannelType.LINE
                      ) {
                        setOnHover(true);
                      }
                    }}
                    onMouseLeave={() => {
                      if (
                        (message.messageType === 'text' ||
                          message.messageType === 'image' ||
                          message.messageType === 'video') &&
                        channel === EChannelType.LINE
                      ) {
                        setOnHover(false);
                      }
                    }}
                  >
                    <MessageItem messageItem={messageData} side={'left'} onJumpToMessage={onJumpToMessage} />
                    {!onHover ? (
                      <TimeText>{messageData.formatDate('HH:mm')}</TimeText>
                    ) : (
                      <button
                        className="focus:bg-[#EEE] w-[32px] h-[32px] rounded-[24px] p-[8px]"
                        onClick={() => {
                          setSelectedReplyMessage(messageData);
                          const composer = document.getElementById('message-composer');
                          if (composer) {
                            composer.focus();
                          }
                        }}
                      >
                        <FontAwesomeIcon className="text-[#9E9E9E]" icon={faReply} />
                      </button>
                    )}
                  </MessageWrap>
                </div>
              </UserSide>
            )}
          </>
        );
      }
    };

    return (
      <Container id={`${message.getId}`} data-testid="message-item">
        <div>{_renderMessageType(message)}</div>
      </Container>
    );
  },
);

type IMessageItemProps = {
  messageItem: CombineAllMessage;
  side: 'left' | 'right';
  onResend?: (message: IRealMessage) => void;
  onJumpToMessage?: (params: IJumpMessageParams) => void;
};

const MessageItem = ({ messageItem, side, onJumpToMessage, onResend }: IMessageItemProps) => {
  const t = useTranslation();
  const theme: any = useTheme();
  if (messageItem instanceof MessageItemModel.TemplateFailed) {
    return (
      <Popover
        destroyTooltipOnHide
        overlayClassName="message-preview-popover"
        content={
          <div>
            <PreviewContent messages={messageItem.instanceData.previewMessage} />
          </div>
        }
        placement="top"
      >
        <div className="flex gap-x-[4px] items-center">
          {messageItem.getMessageStatus === EMessageStatus.SENDING ? (
            <Icon icon={fasSpinnerThird as IconProp} spin style={{ color: theme.PRIMARY }} />
          ) : (
            <Font14PrimaryW500 role="button" onClick={() => (onResend ? onResend(messageItem) : false)}>
              {t('chat.message.try.again.text')}
            </Font14PrimaryW500>
          )}

          <Font14G5W500>{t('chat.message.could.not.send.template')}</Font14G5W500>
          <CustomFontAweSomeIcon icon={fasExclamtionCircle as IconProp} />
        </div>
      </Popover>
    );
  }
  if (messageItem instanceof MessageItemModel.TextMessage) {
    const { textType, text } = messageItem.instanceData;
    return (
      <div>
        <TextMessage data-testid="text-message" side={side} type={textType ?? 'first'}>
          {text}
        </TextMessage>
      </div>
    );
  }
  if (messageItem instanceof MessageItemModel.LocationMessage) {
    const { lat, lng, text } = messageItem.instanceData;
    return (
      <div>
        <LocationMessage data-testid="location-message" lat={lat} lng={lng} text={text} />
      </div>
    );
  }
  if (messageItem instanceof MessageItemModel.StickerMessage) {
    const { text } = messageItem.instanceData;
    return <StickerMessage text={text} />;
  }
  if (messageItem instanceof MessageItemModel.LinkMessage) {
    const { url } = messageItem.instanceData;
    return <LinkMessage data-testid="link-message" url={url} />;
  }

  if (messageItem instanceof MessageItemModel.CarouselMessage) {
    const { items } = messageItem.instanceData;
    return <CarouselMessage items={items} />;
  }

  if (messageItem instanceof MessageItemModel.OptionMessage) {
    const { label, actions } = messageItem.instanceData;
    return <OptionMessage label={label} actions={actions} />;
  }

  if (messageItem instanceof MessageItemModel.VideoMessage) {
    const { url } = messageItem.instanceData;
    return <VideoPlayerMessage controls key={url} url={url} width="100%" />;
  }

  if (messageItem instanceof MessageItemModel.ImageCarousel) {
    const { columns } = messageItem.instanceData;
    return <ImageCarouselMessage columns={columns} />;
  }

  if (messageItem instanceof MessageItemModel.AudioMessage) {
    const { url } = messageItem.instanceData;
    return <AudioPlayerMessage key={url} url={url} width="100%" />;
  }

  if (messageItem instanceof MessageItemModel.ImageMessage) {
    const { url } = messageItem.instanceData;
    return <ImageMessage url={url} />;
  }

  if (messageItem instanceof MessageItemModel.ImagemapMessage) {
    const { url } = messageItem.instanceData;
    return <ImagemapMessage url={url} />;
  }

  if (messageItem instanceof MessageItemModel.FlexMessage) {
    const { altText, contents } = messageItem.instanceData;
    return <FlexMessage altText={altText} side={side} contents={contents} />;
  }

  if (messageItem instanceof MessageItemModel.ReplyMessage) {
    const data = messageItem.instanceData;
    return (
      <button
        onClick={() => {
          if (!data.replyTo || !onJumpToMessage) return;
          onJumpToMessage({ messageId: data.replyTo.data.id, createdAt: data.replyTo.data.createdAt });
        }}
      >
        <ReplyMessage {...data} side={side} />
      </button>
    );
  }

  if (messageItem instanceof MessageItemModel.HtmlMessage) {
    return (
      <root.div className="border border-[#e5e5e5] border-solid p-[12px] rounded-[4px]">
        <div
          dangerouslySetInnerHTML={{
            __html: decodeHTML(messageItem.instanceData.htmlContent),
          }}
        />
      </root.div>
    );
  }

  if (messageItem instanceof MessageItemModel.PostbackMessage) {
    const { text, textType, postbackType } = messageItem.instanceData;
    if (postbackType === EPostbackType.BUTTON) {
      return (
        <PostbackMessage side={side} type={textType}>
          <div className="flex gap-x-[4px] items-center">
            <FontAwesomeIcon icon={faBullseyePointer} color={theme.PRIMARY} className="text-[16px]" />
            <div className="bg-[#b0ebd8] p-1 rounded-[3px] px-[8px]">
              <span className="text-[#04BE8C]">Button : </span>
              {text}
            </div>
          </div>
        </PostbackMessage>
      );
    }
    return (
      <PostbackMessage side={side} type={textType}>
        <div className="flex gap-x-[4px] items-center">
          <FontAwesomeIcon icon={faBullseyePointer} color={theme.PRIMARY} className="text-[16px]" />
          <div className="bg-[#b0ebd8] p-1 rounded-[3px] px-[8px]">{text}</div>
        </div>
      </PostbackMessage>
    );
  }
  return null;
};

ChatMessage.displayName = 'ChatMessage';

export default ChatMessage;
