import React, { useEffect, useMemo, useState } from 'react';
import { ConfirmModal } from '@components/Modal';
import useTranslation from '@hooks/useTranslation';
import { Font16G1W400, Font16G1W600, Font20G1W600, ValidateMessage } from '@components/UtilsComponent';
import { IWorkHourTimeSlot } from '@types';
import { Button } from '@components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { TimeInput } from '@components/TimeInput';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { cloneDeep } from 'lodash';
import { beforeMaskedValueChange } from '@utils/inputMask';
import { Row } from 'antd';
import { Item } from '../NewTeamEditor/style';
import { Checkbox } from '@components/Checkbox';
import { v4 as uuid } from 'uuid';

type IWorkingHourModalProps = {
  label: string;
  visible: boolean;
  timeSlots: IWorkHourTimeSlot[];
  onConfirm: (timeSlotState: IWorkHourTimeSlot[], isApplyToAll: boolean) => void;
  onToggle: () => void;
};

type ITimeSlotState = {
  startHour: string;
  startMinute: string;
  endHour: string;
  endMinute: string;
  validation: {
    isInvalid: boolean;
    reason: string;
  };
};

const convertTimeSlot = (timeSlot: IWorkHourTimeSlot) => {
  return {
    startHour: timeSlot.startHour.toString().padStart(2, '0'),
    startMinute: timeSlot.startMinute.toString().padStart(2, '0'),
    endHour: timeSlot.endHour.toString().padStart(2, '0'),
    endMinute: timeSlot.endMinute.toString().padStart(2, '0'),
    validation: {
      isInvalid: false,
      reason: '',
    },
  };
};

const MAX_TIME_SLOTS = 10;

export const WorkingHourModal = ({ label, timeSlots, visible, onToggle, onConfirm }: IWorkingHourModalProps) => {
  const [isApplyToAll, setIsApplyToAll] = useState(false);
  const [timeSlotState, setTimeSlotState] = useState<ITimeSlotState[]>(timeSlots.map(convertTimeSlot));
  const [isOverlap, setIsOverlap] = useState(false);

  const t = useTranslation();
  const labelId = uuid();
  useEffect(() => {
    setTimeSlotState(timeSlots.map(convertTimeSlot));
  }, [timeSlots]);

  useEffect(() => {
    checkOverlap(timeSlotState);
  }, [timeSlotState]);

  const onAddTimeSlot = () => {
    if (timeSlotState.length >= MAX_TIME_SLOTS) return;
    setTimeSlotState([
      ...timeSlotState,
      {
        startHour: '00',
        startMinute: '00',
        endHour: '00',
        endMinute: '00',
        validation: {
          isInvalid: false,
          reason: '',
        },
      },
    ]);
  };

  const onRemove = (index: number) => {
    const newTimeSlotState = [...timeSlotState];
    newTimeSlotState.splice(index, 1);
    setTimeSlotState(newTimeSlotState);
  };

  const validateTimeSlot = (currentTime: string, currentTimeSlot: ITimeSlotState, isStartTime: boolean) => {
    const curInterval = parseInt(currentTime.slice(0, 2)) * 60 + parseInt(currentTime.slice(5));
    // validation logic
    if (isStartTime) {
      const endInterval = parseInt(currentTimeSlot.endHour) * 60 + parseInt(currentTimeSlot.endMinute);
      // check start time is more than end time
      if (curInterval >= endInterval) {
        return {
          isInvalid: true,
          reason: t('start.time.more.than.end.time.error'),
        };
      }
    } else {
      const startInterval = parseInt(currentTimeSlot.startHour) * 60 + parseInt(currentTimeSlot.startMinute);

      // check end time is less than start time
      if (curInterval <= startInterval) {
        return {
          isInvalid: true,
          reason: t('start.time.more.than.end.time.error'),
        };
      }
    }

    return {
      isInvalid: false,
      reason: '',
    };
  };

  const checkOverlap = (timeSlotState: ITimeSlotState[]) => {
    const overlapArray: boolean[] = [];
    // Convert intervals to a comparable format (total minutes)
    const convertedIntervals = timeSlotState.map((interval) => {
      const start = parseInt(interval.startHour) * 60 + parseInt(interval.startMinute);
      const end = parseInt(interval.endHour) * 60 + parseInt(interval.endMinute);
      return { ...interval, start, end };
    });

    // Sort intervals by start time
    convertedIntervals.sort((a, b) => a.start - b.start);
    // Validate for overlap
    for (let i = 0; i < convertedIntervals.length - 1; i++) {
      const current = convertedIntervals[i];
      const next = convertedIntervals[i + 1];
      if (current.end >= next.start) {
        overlapArray.push(true);
      }
    }

    if (overlapArray.length > 0) {
      setIsOverlap(true);
    } else {
      setIsOverlap(false);
    }
  };

  const isInvalid = useMemo(() => {
    return timeSlotState.some((timeSlot) => timeSlot.validation.isInvalid) || isOverlap;
  }, [timeSlotState, isOverlap]);

  return (
    <ConfirmModal
      bodyStyle={{ padding: 0 }}
      visible={visible}
      maxWidth={400}
      bodyWidth={'400px'}
      confirmBtnTxt={t('update')}
      cancelBtnTxt={t('cancel.btn')}
      handleClose={() => {
        setIsApplyToAll(false);
        onToggle();
      }}
      isDisabledConfirmBtn={isInvalid}
      handleConfirm={() => {
        if (isInvalid) return;
        const newTimeSlot = timeSlotState.map((timeSlot) => {
          const startHour = parseInt(timeSlot.startHour);
          const startMinute = parseInt(timeSlot.startMinute);
          const endHour = parseInt(timeSlot.endHour);
          const endMinute = parseInt(timeSlot.endMinute);
          return {
            startHour,
            startMinute,
            endHour,
            endMinute,
          };
        });
        setIsApplyToAll(false);
        onConfirm(newTimeSlot, isApplyToAll);
      }}
    >
      <div className="header p-[24px] border-b border-solid border-[#E5E5E5] !w-full">
        <Font20G1W600 className="capitalize">{label}</Font20G1W600>
      </div>
      <div className="body p-[24px] border-b border-solid border-[#E5E5E5] !w-full mb-[24px]">
        <div className="flex flex-col gap-y-[16px] max-h-[250px] overflow-y-auto pr-2">
          {timeSlotState.map((timeSlot, index) => {
            return (
              <div className="flex justify-between" key={index}>
                <div className="flex flex-col">
                  <div className="flex items-center">
                    <TimeInput
                      mask={'99 : 99'}
                      maskChar={'0'}
                      alwaysShowMask={false}
                      placeholder={'00 : 00'}
                      value={`${timeSlot.startHour} : ${timeSlot.startMinute}`}
                      onChange={(e) => {
                        // update timeSlotState
                        const newTimeSlotState = cloneDeep(timeSlotState);
                        const startHour = e.target.value.slice(0, 2);
                        const startMinute = e.target.value.slice(5);
                        newTimeSlotState[index] = {
                          ...newTimeSlotState[index],
                          startHour: startHour,
                          startMinute: startMinute,
                          validation: validateTimeSlot(e.target.value, newTimeSlotState[index], true),
                        };
                        // detech overlap
                        setTimeSlotState(newTimeSlotState);
                      }}
                      beforeMaskedValueChange={beforeMaskedValueChange}
                    />
                    <div className="text-[#9E9E9E] mx-[8px]"> - </div>
                    <TimeInput
                      mask={'99 : 99'}
                      maskChar={'0'}
                      alwaysShowMask={false}
                      placeholder={'00 : 00'}
                      value={`${timeSlot.endHour} : ${timeSlot.endMinute}`}
                      onChange={(e) => {
                        // update timeSlotState
                        const newTimeSlotState = cloneDeep(timeSlotState);
                        newTimeSlotState[index] = {
                          ...newTimeSlotState[index],
                          endHour: e.target.value.slice(0, 2),
                          endMinute: e.target.value.slice(5),
                          validation: validateTimeSlot(e.target.value, newTimeSlotState[index], false),
                        };
                        setTimeSlotState(newTimeSlotState);
                      }}
                      beforeMaskedValueChange={beforeMaskedValueChange}
                    />
                  </div>
                  {
                    // show validation error message
                    timeSlot.validation.isInvalid && <ValidateMessage>{timeSlot.validation.reason}</ValidateMessage>
                  }
                </div>
                <button
                  type="button"
                  className="flex items-center justify-center"
                  onClick={() => onRemove(index)}
                  tabIndex={-1}
                >
                  <FontAwesomeIcon icon={faTimes} className="text-[18px]" />
                </button>
              </div>
            );
          })}
        </div>

        <Button
          className="w-full !text-[16px] mt-[16px] !h-[40px]"
          onClick={onAddTimeSlot}
          disabled={timeSlotState.length >= MAX_TIME_SLOTS}
          icon={<FontAwesomeIcon className="mr-[8px]" icon={faPlus} />}
        >
          {t('add.new')}
        </Button>
        <Row className="mt-[16px]">
          <div className="flex gap-x-[8px] items-center">
            <Item>
              <Checkbox id={labelId} checked={isApplyToAll} onChange={() => setIsApplyToAll(!isApplyToAll)} />
            </Item>
            <Item>
              <Font16G1W400>
                <label htmlFor={labelId} className="cursor-pointer">
                  {t('time.slot.apply.all.days')}
                </label>
              </Font16G1W400>
            </Item>
          </div>
        </Row>
        {
          // show overlap error message
          isOverlap && (
            <div className="mt-[16px]">
              <ValidateMessage>{t('time.slot.overlap.error')}</ValidateMessage>
            </div>
          )
        }
      </div>
    </ConfirmModal>
  );
};
