import React, { useMemo, useRef, useState } from 'react';
import { useVaSuggestion } from '@hooks/useVaSuggestion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { animated, useSpring } from '@react-spring/web';
import useMeasure from 'react-use-measure';
import { CombineAllMessage } from '@model/MessageItem';
import { Font12G1W400, Font12WhiteW400, Font14G3W400 } from '@components/UtilsComponent';
import { useKeyPress } from '@hooks/useKeyPress';
import { useVaPlaceholder } from './useVaPlaceholder';
import { useQuery } from 'react-query';
import axios from 'axios';
import { useScrollBottom } from '@hooks/useScrollBottom';
import { createPortal } from 'react-dom';

const CURSOR_CLASS_NAME = 'custom-type-animation-cursor';

const ACTIVE_ICON = 'https://megabot-fe-assets.s3.ap-southeast-1.amazonaws.com/va-icons/va-active.svg';
const INACTIVE_ICON = 'https://megabot-fe-assets.s3.ap-southeast-1.amazonaws.com/va-icons/va-inactive.svg';
const TOOLTIP_TEXT_ENDPOINT = 'https://megabot-fe-assets.s3.ap-southeast-1.amazonaws.com/va-text/va-text.json';

type IVAPlaceholderProps = {
  placeholder?: string;
  currentMessage: string;
  lastMessage: CombineAllMessage;
  ticketId: string;
  handleInsertIntoField: (text: string) => void;
};

let shakeTimeout: NodeJS.Timeout | null = null;

export const VAPlaceholder = React.memo(
  ({
    placeholder = 'Message',
    currentMessage = '',
    lastMessage,
    ticketId,
    handleInsertIntoField,
  }: IVAPlaceholderProps) => {
    const [placeholderWrapRef, { height: placeholderWrapHeight }] = useMeasure();
    const [placeHolderRef, { height: placeholderHeight }] = useMeasure();
    const [showInsert, setShowInsert] = useState(false);
    const { vaMessage, errorMessage } = useVaSuggestion(
      lastMessage,
      ticketId,
      () => {
        setShowInsert((prev) => {
          if (prev) {
            return false;
          }
          return prev;
        });
      },
      () => {
        setShowInsert(true);
        shakeKatik();
        tootip.display();
      },
    );
    const { placeholderHandler, katik, composer, tootip } = useVaPlaceholder(
      lastMessage,
      currentMessage,
      vaMessage,
      errorMessage,
    );

    useKeyPress(['Tab'], () => {
      // if there are some text in composer and katik is not showing then return or if placeholder is not showing and katik is not showing then return
      if ((currentMessage && !katik.isShowKatik) || (!placeholderHandler.isShowPlaceholder && !katik.isShowKatik)) {
        return;
      }
      if (showInsert) {
        placeholderHandler.hidePlaceholder();
        katik.hideKatik();
        composer.texting();
        handleInsertIntoField(vaMessage);
      }
    });

    const { contentRef, onScroll } = useScrollBottom(placeholderHeight);

    const message = useMemo(() => {
      if (vaMessage) {
        return (
          <div ref={placeHolderRef} className="relative">
            <span>{vaMessage}</span>
            <span className={CURSOR_CLASS_NAME} />
            {showInsert && (
              <div
                className="inline-flex ml-[4px] gap-x-[4px] "
                onClick={() => {
                  handleInsertIntoField(vaMessage);
                  placeholderHandler.hidePlaceholder();
                }}
                role="button"
              >
                <div
                  className="border border-solid border-primary rounded-[2px] flex items-center justify-center text-primary text-[8px] py-[2px] px-[4px] font-bold gap-x-1"
                  style={{
                    background:
                      'var(--Primary-Selected, linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #04BE8C)',
                  }}
                >
                  Tab
                  <FontAwesomeIcon icon={faTimesCircle} className="text-primary" />
                </div>
                <Font12G1W400
                  style={{
                    color: 'var(--Primary-Active,#04BE8C)',
                  }}
                >
                  to use
                </Font12G1W400>
              </div>
            )}
          </div>
        );
      }
      if (errorMessage) {
        return (
          <p>
            {errorMessage} <span className={CURSOR_CLASS_NAME} />
          </p>
        );
      }
      return <p>{placeholder}</p>;
    }, [vaMessage, errorMessage, showInsert]);

    const shakeKatik = () => {
      const vaIconWrap = document.getElementById('va-icon-wrap');
      // if katik is closed and steam finished add shake animation to image
      if (vaIconWrap) {
        // Remove 'shake' class if it's already present to restart the animation
        vaIconWrap.classList.remove('shake');

        // Clear any existing timeout to prevent multiple calls
        if (shakeTimeout) {
          clearTimeout(shakeTimeout);
        }

        // Add 'shake' class and set up a timeout to remove it
        vaIconWrap.classList.add('shake');
        shakeTimeout = setTimeout(() => {
          vaIconWrap.classList.remove('shake');
          tootip.display();
        }, 500);
      }
    };

    const onClickPlaceholder = () => {
      placeholderHandler.hidePlaceholder();
      // shake katik
      shakeKatik();
      // if katik is showing then hide katik
      if (katik.isShowKatik) {
        katik.hideKatik();
      }
      composer.focus();
    };

    return (
      <>
        <div className="placeholder-wrap relative z-[1]" ref={placeholderWrapRef}>
          {placeholderHandler.isShowPlaceholder
            ? createPortal(
                <div
                  ref={contentRef}
                  onScroll={onScroll}
                  onClick={onClickPlaceholder}
                  role="button"
                  className="text-[#bfbfbf] cursor-auto py-[8px] px-[11px] leading-[1.5715] min-h-[40px] max-h-[106px] border border-solid border-[rgb(224,224,224)] rounded-[8px] overflow-auto"
                >
                  {message}
                </div>,
                document.getElementById('va-composer-container')!,
              )
            : null}
        </div>
        <Katik
          handleInsertIntoField={handleInsertIntoField}
          vaMesssage={vaMessage}
          errorMessage={errorMessage}
          isShow={katik.isShowKatik}
          isShowInsert={showInsert}
          placeholderWrapHeight={placeholderWrapHeight}
          onShow={katik.showKatik}
          onHide={katik.hideKatik}
          tooltipVisible={tootip.isShow}
        />
      </>
    );
  },
);

type IKatikProps = {
  vaMesssage: string;
  errorMessage: string;
  isShow: boolean;
  isShowInsert: boolean;
  placeholderWrapHeight: number;
  tooltipVisible: boolean;
  onShow: () => void;
  onHide: () => void;
  handleInsertIntoField: (text: string) => void;
};

const Katik = ({
  vaMesssage,
  errorMessage,
  isShow,
  isShowInsert,
  tooltipVisible,
  onShow,
  onHide,
  handleInsertIntoField,
}: IKatikProps) => {
  const tooltipQuery = useQuery(
    'tooltip',
    () => {
      return axios.get<{ text: string }>(TOOLTIP_TEXT_ENDPOINT);
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );
  const wrapElementRef = useRef<HTMLDivElement>(null);
  const [tooltipRef, tooltipDimen] = useMeasure();
  const [katikContentRef, { height: katikContentHeight }] = useMeasure();
  const [innerRef, { height }] = useMeasure();
  const tooltipStyle = useSpring({
    opacity: tooltipVisible ? 1 : 0,
  });
  const katikContainerStyle = useSpring({
    y: isShow ? -height - 40 : -32,
  });
  const katikStyle = useSpring({
    opacity: isShow ? 1 : 0,
  });

  const { contentRef, onScroll } = useScrollBottom(katikContentHeight);

  return (
    <>
      <animated.div
        id="katik-wrap"
        style={katikContainerStyle}
        className="w-[40%]  right-[60px] mb-[8px] absolute z-0"
        ref={wrapElementRef}
      >
        <animated.div
          className="flex absolute right-[30px] top-[-55px]"
          style={{ ...tooltipStyle, top: `${-tooltipDimen.height - 15}px` }}
        >
          <div className="relative">
            <div ref={tooltipRef} className="bg-gray-800 text-white px-[12px] py-[10px] rounded-lg relative">
              <Font12WhiteW400>{tooltipQuery.data?.data?.text || 'เนี่ยๆที่เราคิดให้อยู่นี่'}</Font12WhiteW400>
              <div className="absolute right-[20px] -bottom-2 w-4 h-4">
                <div className="absolute w-4 h-4 bg-gray-800 transform rotate-45 rounded-sm" />
              </div>
            </div>
          </div>
        </animated.div>
        <div
          id="va-icon-wrap"
          className="absolute top-0 z-[9] right-[40px]"
          role="button"
          onClick={() => {
            if (isShow) {
              onHide();
              return;
            }
            onShow();
          }}
        >
          <img
            src={isShow ? ACTIVE_ICON : INACTIVE_ICON}
            className="w-[35px] h-[35px] pointer-events-none select-none"
          />
        </div>

        <animated.div
          style={katikStyle}
          className="shadow-va relative top-[30px] bg-[#F5F5F5] border border-solid border-[#E5E5E5] rounded-[8px] px-[16px] py-[16px]"
          ref={innerRef}
        >
          <div ref={contentRef} onScroll={onScroll} className="overflow-y-auto max-h-[150px] relative">
            <div ref={katikContentRef}>
              {errorMessage ? (
                <Font14G3W400 className="!text-[gray]">{errorMessage}</Font14G3W400>
              ) : (
                <Font14G3W400>{vaMesssage}</Font14G3W400>
              )}

              <span className={CURSOR_CLASS_NAME} />
              {isShowInsert && (
                <span
                  className="inline-flex ml-[4px] gap-x-[4px] items-center"
                  onClick={() => {
                    handleInsertIntoField(vaMesssage);
                    onHide();
                  }}
                  role="button"
                >
                  <div
                    className="border border-solid border-primary rounded-[2px] flex items-center justify-center text-primary text-[8px] py-[2px] px-[4px] font-bold gap-x-1"
                    style={{
                      background:
                        'var(--Primary-Selected, linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #04BE8C)',
                    }}
                  >
                    Tab
                    <FontAwesomeIcon icon={faTimesCircle} className="text-primary" />
                  </div>
                  <Font12G1W400
                    style={{
                      color: 'var(--Primary-Active,#04BE8C)',
                    }}
                  >
                    to use
                  </Font12G1W400>
                </span>
              )}
            </div>
          </div>
        </animated.div>
      </animated.div>
    </>
  );
};
