import React from 'react';
import { Font12G5W400 } from '@components/UtilsComponent';

const TIME_ARRAY = ['00:00', '04:00', '08:00', '12:00', '16:00', '20:00', '24:00'];
const DIVIDER = 16.66667;
export const TimelineHeader = () => {
  return (
    <div id="timeline-header" className="flex h-[20px] gap-x-[16px]">
      <div className="flex w-[120px]" />
      <div className="relative w-full">
        {TIME_ARRAY.map((time, index) => {
          return (
            <div key={index} className="absolute ml-[-18px]" style={{ left: `${index * DIVIDER}%` }}>
              <Font12G5W400>{time}</Font12G5W400>
            </div>
          );
        })}
      </div>
      <div className="min-w-[16px]" />
    </div>
  );
};
