import { isEmpty } from 'lodash';
import { EMessageType } from '@enums/MessageType';
import { BaseMessage, BaseMessageJson } from './BaseMessage';
import { ITextType, IMessageLevel } from '@types';
import qs from 'qs';
export type PostbackMessageJson = {
  text: string;
  type: ITextType;
} & BaseMessageJson;

export const enum EPostbackType {
  BUTTON = 'button',
  TEXT = 'text',
}
// sample incomming text 'action=Inthanon_Media"'
export class PostbackMessage extends BaseMessage {
  private text: string;
  private level: IMessageLevel;
  private textType: ITextType;
  private postbackType: EPostbackType = EPostbackType.TEXT;
  constructor(data: PostbackMessageJson) {
    const { id, createdAt } = data;
    super({ ...data, id, createdAt, senderImage: data.senderImage });
    this.text = this.generateText(data.text);
    this.textType = data.type ?? 'first';
    this.level = 1;
    this.postbackType = this.generatePostbackType(data.text);
  }

  get instanceData() {
    return {
      id: this.id,
      text: this.text,
      createdAt: this.createdAt,
      textType: this.textType,
      level: this.level,
      retry: this.retry,
      postbackType: this.postbackType,
      senderImage: this.senderImage,
      senderName: this.senderName,
    };
  }

  get messagePayload() {
    return {
      text: this.text,
    };
  }

  get getText() {
    return this.text;
  }

  get getMessageType() {
    return EMessageType.POSTBACK;
  }

  set setTextType(textType: ITextType) {
    this.textType = textType;
  }

  set setLevel(level: IMessageLevel) {
    this.level = level;
  }

  public generateText(str: string) {
    const query = qs.parse(str);
    const optionName = (query.userJourney as qs.ParsedQs)?.optionName as string | undefined;
    const displayMsg = query.displayMsg as string | undefined;

    // If neither optionName nor displayMsg is provided, return the default message
    if (!optionName && !displayMsg) {
      return 'Click untitled button';
    }

    // Prefer displayMsg over optionName if both are provided
    return displayMsg || optionName || '';
  }

  public generatePostbackType(str: string) {
    let type: EPostbackType = EPostbackType.TEXT;
    const query = qs.parse(str);
    const optionName = (query.userJourney as qs.ParsedQs)?.optionName as string | undefined;
    const displayMsg = query.displayMsg as string | undefined;
    if (optionName || displayMsg) {
      type = EPostbackType.BUTTON;
    }

    return type;
  }
}
