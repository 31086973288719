import React from 'react';
import { ModalContainer, ActionContainer, LoadingIcon } from './style';
import { Button, PrimaryButton } from '@components/Button';
import { faSyncAlt } from '@fortawesome/pro-solid-svg-icons';

export type IConfirmModal = {
  visible: boolean;
  confirmBtnTxt: string;
  cancelBtnTxt: string;
  isLoading?: boolean;
  destroyOnClose?: boolean;
  isDisabledConfirmBtn?: boolean;
  isDisabledCancelBtn?: boolean;
  width?: number;
  maxWidth?: number;
  bodyWidth?: string;
  centered?: boolean;
  zIndex?: number;
  bodyStyle?: React.CSSProperties;
  customFooter?: React.ReactNode;
  handleClose: (status: boolean) => void;
  handleConfirm: (event?: React.MouseEvent<HTMLElement>) => void;
};

export const ConfirmModal: React.FC<React.PropsWithChildren<IConfirmModal>> = ({
  visible,
  isLoading,
  cancelBtnTxt,
  confirmBtnTxt,
  children,
  destroyOnClose,
  isDisabledConfirmBtn,
  isDisabledCancelBtn,
  width,
  bodyWidth,
  maxWidth,
  centered = false,
  zIndex,
  bodyStyle,
  customFooter,
  handleClose,
  handleConfirm,
}: React.PropsWithChildren<IConfirmModal>) => {
  return (
    <ModalContainer
      zIndex={zIndex}
      centered={centered}
      destroyOnClose={destroyOnClose}
      visible={visible}
      bodyWidth={bodyWidth}
      bodyStyle={bodyStyle}
      style={{ minWidth: width, maxWidth }}
      onCancel={() => handleClose(false)}
      footer={[
        customFooter ? (
          customFooter
        ) : (
          <ActionContainer key="1">
            <Button
              size="large"
              onClick={() => handleClose(false)}
              data-testid="confirm-modal-cancel-btn"
              disabled={isLoading || isDisabledCancelBtn}
            >
              {cancelBtnTxt}
            </Button>
            <PrimaryButton
              onClick={handleConfirm}
              size="large"
              disabled={isLoading || isDisabledConfirmBtn}
              data-testid="confirm-modal-confirm-btn"
            >
              {isLoading && <LoadingIcon icon={faSyncAlt} />}
              {confirmBtnTxt}
            </PrimaryButton>
          </ActionContainer>
        ),
        ,
      ]}
    >
      {children}
    </ModalContainer>
  );
};
