import { DEBOUNCE_MARK_READ_TIME, GLOBAL_MESSAGE } from '@configs/constants';
import EChannelType from '@enums/ChannelType';
import { CombineAllMessage } from '@model/MessageItem';
import { IMember, ITicket } from '@types';
import filterFileByChannel from '@utils/filterFileByChannel';
import { RcFile } from 'antd/lib/upload';
import dayjs from 'dayjs';
import { assign, createMachine } from 'xstate';
import { IMessageBoxOptions } from '.';
import {
  fetchMessageWithTime,
  fetchMessageWithTimeEvent,
  fetchNextMessagesWithTime,
  fetchPreviousMessages,
  findMessage,
  firstfetchMessage,
  resendAgentMessage,
  sendAgentMessage,
  sendAgentTemplate,
  sendMessageWithTime,
  sendTemplateWithTime,
  uploadFile,
} from './fns';
import { markReadTicket } from './fns/markReadTicket';
import { getMessage, messageGenerator, updateMessagesAfterRetrySuccess } from './fns/messageGenerator';
import { cloneDeep, isEmpty } from 'lodash';
import { EMessageStatus } from '@enums/MessageStatus';
import { v4 as uuid } from 'uuid';
import { TemplateFailed } from '@model/MessageItem/TemplateFailed';

export type IChatHistory = {
  previousRemain: boolean;
  nextRemain: boolean;
  messages: CombineAllMessage[];
};

export type IMessageBoxMachineContext = {
  userId: string;
  channelType: string;
  messageTime: number | null;
  chatHistory: IChatHistory;
  spareHistroy?: IChatHistory;
  scrollBottom: boolean;
  previousScrollHeight: number | null;
  currentScrollPosition: number;
  textMessage: string;
  selectedFiles: RcFile[];
  rejectFiles: File[];
  currentTicket: ITicket;
  fileErrorMessage: string;
  filesListAfterUpload: { originalname: string; location: string }[];
  errorResponse: {
    name: string;
    message: string;
  };
};

const messageBoxMachine = (
  userId: string,
  channelType: string,
  member: IMember,
  messageTime: number | null,
  currentTicket: ITicket,
  options?: IMessageBoxOptions,
) => {
  const asReader = options?.asReader;
  return createMachine<IMessageBoxMachineContext>(
    {
      id: 'message-box-machine',
      initial: 'idle',
      type: 'parallel',
      context: {
        userId,
        channelType,
        messageTime,
        textMessage: '',
        currentTicket,
        chatHistory: {
          nextRemain: false,
          previousRemain: false,
          messages: [],
        },
        spareHistroy: {
          nextRemain: false,
          previousRemain: false,
          messages: [],
        },
        fileErrorMessage: '',
        currentScrollPosition: 0,
        previousScrollHeight: null,
        scrollBottom: false,
        selectedFiles: [],
        filesListAfterUpload: [],
        rejectFiles: [],
        errorResponse: {
          name: '',
          message: '',
        },
      },
      states: {
        chatHistoryState: {
          initial: 'idle',
          states: {
            idle: {
              on: {
                FIRST_FETCH: {
                  target: 'firstFetching',
                },
                FIRST_FETCH_WITH_TIME: {
                  target: 'firstFetchingWithTime',
                },
              },
            },
            fileCountExceed: {
              on: {
                CLEAR_SELECTING_FILE: {
                  target: 'standby',
                  actions: 'clearSelectedFile',
                },
              },
            },
            //normal fetch
            firstFetching: {
              tags: ['normal-fetch-task', 'loading-previous'],
              invoke: {
                id: 'messageBox-first-fetch',
                src: firstfetchMessage,
                onDone: [
                  {
                    target: 'sendReadMark',
                    cond: 'shouldSendReadMark',
                  },
                  {
                    target: 'standby',
                  },
                ],
                onError: 'fetchFailed',
              },
            },
            standby: {
              entry: ['resetRejectedFiles'],
              tags: 'normal-fetch-task',
              on: {
                SCROLL_UP: {
                  target: 'fetchingPreviousMessage',
                },
                SELECTING_FILE: {
                  target: 'selectingFile',
                  actions: 'updateSelectedFile',
                },
                SOCKET_RECONNECT: {
                  target: 'firstFetching',
                },
              },
            },
            fetchFailed: {},
            fetchingPreviousMessage: {
              tags: ['normal-fetch-task', 'loading-previous'],
              invoke: {
                id: 'messageBox-fetching-previous-message',
                src: fetchPreviousMessages,
                onDone: 'fetchPreviousMessageSuccess',
                onError: 'fetchPreviousMessageFailed',
              },
            },
            fetchPreviousMessageSuccess: {
              tags: 'normal-fetch-task',
              on: {
                STANDBY: {
                  target: 'standby',
                },
              },
            },
            fetchPreviousMessageFailed: {
              on: {
                STANDBY: {
                  target: 'standby',
                },
              },
            },

            //send message
            sendMessage: {
              invoke: {
                id: 'send-message',
                src: (context, event) => async (cb) => {
                  const { actionType, messagePayload, trackId, newMessage, previewMessage } = event;

                  try {
                    if (actionType === 'message') {
                      await sendAgentMessage(context, event);
                      cb({
                        type: 'SEND_MESSAGE_SUCCESS',
                        messagePayload,
                        actionType,
                      });
                    } else {
                      await sendAgentTemplate(context, event);
                      cb({
                        type: 'SEND_TEMPLATE_SUCCESS',
                        messagePayload: messagePayload,
                        status: EMessageStatus.SENT,
                        actionType,
                      });
                    }
                  } catch (error) {
                    if (actionType === 'message') {
                      cb({
                        type: 'SEND_MESSAGE_FAILED',
                        data: messagePayload,
                        error: error,
                      });
                    } else {
                      cb({
                        type: 'SEND_TEMPLATE_FAILED',
                        data: {
                          messagePayload,
                          trackId,
                          newMessage: newMessage,
                          previewMessage: previewMessage,
                        },
                        error: error,
                      });
                    }
                  }
                },
              },
              on: {
                STANDBY: {
                  target: 'standby',
                },
              },
            },
            sendMessageSuccess: {
              tags: 'send-message-result',
              on: {
                STANDBY: {
                  target: 'standby',
                },
              },
            },
            sendMessageFailed: {
              tags: 'send-message-result',
              on: {
                STANDBY: {
                  target: 'standby',
                },
              },
            },
            //resend messageBox
            resendMessage: {
              invoke: {
                id: 'messageBox-send-message',
                src: (context, event) => async (cb) => {
                  const { messagePayload, actionType, trackId, template, livechatMessage, previewMessage } = event;
                  try {
                    if (actionType === 'message') {
                      await resendAgentMessage(context, event);
                      cb({
                        type: 'RESEND_MESSAGE_SUCCESS',
                        data: messagePayload,
                        actionType,
                      });
                    } else {
                      await sendAgentTemplate(context, event);
                      cb({
                        type: 'SEND_TEMPLATE_SUCCESS',
                        messagePayload: messagePayload,
                        status: EMessageStatus.SENT,
                        actionType,
                        fromResend: true,
                        trackId: trackId,
                        livechatMessage: livechatMessage,
                      });
                    }
                  } catch (error) {
                    if (actionType === 'message') {
                      cb({
                        type: 'RESEND_MESSAGE_FAILED',
                        data: messagePayload,
                        error: error,
                      });
                    } else {
                      cb({
                        type: 'SEND_TEMPLATE_FAILED',
                        data: {
                          messagePayload: messagePayload,
                          fromResend: true,
                          trackId: trackId,
                          template: template,
                          previewMessage: previewMessage,
                        },
                        error: error,
                      });
                    }
                  }
                },
              },
            },
            // fetch with time
            firstFetchingWithTime: {
              tags: 'loading-previous',
              invoke: {
                id: 'messageBox-first-fetch-with-time',
                src: fetchMessageWithTime,
                onDone: 'fetchMessagesWithTimeSuccess',
                onError: 'fetchWithTimeFailed',
              },
            },
            fetchMessagesWithTimeSuccess: {
              on: {
                STANDBY_WITH_TIME: {
                  target: 'standByWithTime',
                },
              },
            },
            fetchWithTimeFailed: {},
            standByWithTime: {
              on: {
                //this will call normal flow
                FIRST_FETCH: {
                  target: 'firstFetching',
                },
                SCROLL_UP: {
                  target: 'fetchingPreviousMessagesWithTime',
                },
                SCROLL_DOWN: {
                  target: 'fetchingNextMessagesWithTime',
                },
                SEND_MESSAGE: {
                  target: 'sendMessageWithTime',
                  actions: 'updateHistoryWithTimeBeforeSend',
                },
                SELECTING_FILE: {
                  target: 'selectingFileWithTime',
                  actions: 'updateSelectedFile',
                },
              },
            },
            fetchingPreviousMessagesWithTime: {
              tags: 'loading-previous',
              invoke: {
                id: 'messageBox-fetching-previous-messages',
                src: fetchPreviousMessages,
                onDone: 'fetchPreviousMessagesWithTimeSuccess',
                onError: 'fetchPreviousMessagesWithTimeFailed',
              },
            },
            fetchPreviousMessagesWithTimeSuccess: {
              on: {
                STANDBY_WITH_TIME: {
                  target: 'standByWithTime',
                },
              },
            },
            fetchPreviousMessagesWithTimeFailed: {},
            fetchingNextMessagesWithTime: {
              tags: 'loading-next',
              invoke: {
                id: 'messageBox-fetching-next-messages',
                src: fetchNextMessagesWithTime,
                onDone: {
                  target: 'fetchNextMessagesWithTimeSuccess',
                  actions: 'updateChatHistoryForNextMessage',
                },
                onError: 'fetchNextMessagesWithTimeFailed',
              },
            },
            fetchNextMessagesWithTimeSuccess: {
              on: {
                STANDBY_WITH_TIME: {
                  target: 'standByWithTime',
                },
              },
            },
            fetchNextMessagesWithTimeFailed: {},
            sendMessageWithTime: {
              invoke: {
                id: 'messagebox-send-message-with-time',
                src: (context, event) => async (cb) => {
                  const { messagePayload, actionType, trackId, newMessage, previewMessage } = event;
                  try {
                    if (actionType === 'message') {
                      const result = await sendMessageWithTime(context, event);
                      cb({
                        type: 'SEND_MESSAGE_WITH_TIME_SUCCESS',
                        data: result,
                      });
                    } else {
                      const result = await sendTemplateWithTime(context, event);
                      cb({
                        type: 'SEND_MESSAGE_WITH_TIME_SUCCESS',
                        data: result,
                      });
                    }
                  } catch (error) {
                    if (actionType === 'message') {
                      cb({
                        type: 'SEND_MESSAGE_FAILED',
                        data: messagePayload,
                        error: error,
                      });
                    } else {
                      cb({
                        type: 'SEND_TEMPLATE_FAILED',
                        data: {
                          messagePayload,
                          trackId,
                          newMessage: newMessage,
                          previewMessage: previewMessage,
                        },
                        error: error,
                      });
                    }
                  }
                },
              },
              on: {
                SEND_MESSAGE_WITH_TIME_SUCCESS: {
                  target: 'sendMessageWithTimeSuccess',
                },
              },
            },

            // fetch with time
            fetchWithTimeEvent: {
              invoke: {
                id: 'messageBox-fetch-with-time',
                src: fetchMessageWithTimeEvent,
                onDone: 'fetchWithTimeEventSuccess',
                onError: 'fetchWithTimeEventFailed',
              },
            },
            fetchWithTimeEventSuccess: {
              on: {
                STANDBY_WITH_TIME: {
                  target: 'standByWithTime',
                },
              },
            },
            fetchWithTimeEventFailed: {},

            sendMessageWithTimeSuccess: {
              on: {
                STANDBY: {
                  target: 'standby',
                },
              },
            },
            //file processing standard way
            selectingFile: {
              tags: 'file-processing',
              on: {
                CLEAR_SELECTING_FILE: {
                  target: 'standby',
                  actions: 'clearSelectedFile',
                },
                UPLOAD_FILE: {
                  target: 'uploadingFiles',
                },
              },
            },

            uploadingFiles: {
              tags: 'file-processing',
              invoke: {
                id: 'upload-files',
                src: uploadFile,
                onDone: {
                  target: 'uploadFilesSuccess',
                  actions: 'uploadFilesListAfterUpload',
                },
                onError: {
                  target: 'uploadFilesFailed',
                  actions: 'updateFileErrorMessage',
                },
              },
            },
            uploadFilesSuccess: {
              tags: 'file-processing',
              on: {
                SEND_MESSAGE: {
                  target: 'sendingFilesMessage',
                  actions: 'updateMessages',
                },
                SEND_MESSAGE_WITH_TIME: {
                  target: 'sendFilesMessageWithTime',
                  actions: 'updateHistoryWithTimeBeforeSend',
                },
              },
            },
            uploadFilesFailed: {
              tags: 'file-processing',
              after: {
                0: {
                  actions: 'filterRejectedFiles',
                },
              },
              on: {
                CLEAR_SELECTING_FILE: {
                  target: 'standby',
                  actions: 'clearSelectedFile',
                },
              },
            },
            sendingFilesMessage: {
              invoke: {
                id: 'send-files-message',
                src: (context, event) => async (cb) => {
                  const { messagePayload } = event;
                  try {
                    await sendAgentMessage(context, event);
                    cb({
                      type: 'SEND_FILE_MESSAGE_SUCCESS',
                      data: messagePayload,
                    });
                  } catch (error) {
                    cb({
                      type: 'SEND_FILE_MESSAGE_FAILED',
                      data: messagePayload,
                      error: error,
                    });
                  }
                },
              },
              on: {
                SEND_FILE_MESSAGE_SUCCESS: {
                  target: 'standby',
                  actions: ['clearSelectedFile', 'clearFilesListAfterUpload'],
                },
                SEND_FILE_MESSAGE_FAILED: {
                  target: 'sendMessageFailed',
                  actions: [
                    'updateErrorMessages',
                    'clearSelectedFile',
                    'clearFilesListAfterUpload',
                    'updateErrorResponse',
                  ],
                },
              },
            },
            sendFilesMessageSuccess: {
              on: {
                STANDBY: {
                  target: 'standby',
                },
              },
            },

            //file processing with time way
            selectingFileWithTime: {
              tags: 'file-processing',
              on: {
                CLEAR_SELECTING_FILE: {
                  target: 'standByWithTime',
                  actions: 'clearSelectedFile',
                },
                UPLOAD_FILE: {
                  target: 'uploadingFiles',
                },
              },
            },
            sendFilesMessageWithTime: {
              invoke: {
                id: 'send-files-message',
                src: (context, event) => async (cb) => {
                  const { messagePayload } = event;
                  try {
                    await sendMessageWithTime(context, event);
                    cb({
                      type: 'SEND_MESSAGE_WITH_TIME_SUCCESS',
                      data: messagePayload,
                    });
                  } catch (error) {
                    cb({
                      type: 'SEND_MESSAGE_WITH_TIME_FAILED',
                      data: messagePayload,
                      error: error,
                    });
                  }
                },
              },
              on: {
                SEND_MESSAGE_WITH_TIME_SUCCESS: {
                  target: 'sendFilesMessageSuccess',
                  actions: ['clearSelectedFile', 'clearFilesListAfterUpload'],
                },
                SEND_MESSAGE_WITH_TIME_FAILED: {
                  target: 'sendMessageFailed',
                  actions: [
                    'updateErrorMessages',
                    'clearSelectedFile',
                    'clearFilesListAfterUpload',
                    'updateErrorResponse',
                  ],
                },
              },
            },
            debouceSendReadMark: {
              id: 'debounce-send-read-mark',
              after: {
                [DEBOUNCE_MARK_READ_TIME]: {
                  target: 'sendReadMark',
                },
              },
            },
            sendReadMark: {
              id: 'send-read-mark',
              invoke: {
                id: 'messageBox-send-read-mark',
                src: (context) => markReadTicket(context, member),
                onDone: 'standby',
                onError: 'sendReadMarkFailed',
              },
            },
            sendReadMarkSuccess: {
              id: 'send-read-mark-success',
              after: {
                0: {
                  target: 'standby',
                },
              },
            },
            sendReadMarkFailed: {
              id: 'send-read-mark-failed',
              after: {
                0: {
                  target: 'standby',
                },
              },
            },
          },
        },
        socketState: {
          initial: 'standby',
          states: {
            standby: {},
            receiveMessage: {},
          },
          on: {
            SOCKET_STANDBY: {
              target: '.standby',
            },
          },
        },
      },
      on: {
        SET_CURRENT_SCROLL_POSITION: {
          actions: 'setCurrentScrollPosition',
        },

        TYPING: {
          actions: 'updateTextMessage',
        },
        FIND_AND_UPDATE_MESSAGE: {
          target: 'socketState.receiveMessage',
          actions: 'findAndUpdateMessage',
        },
        MARK_READ: {
          target: 'chatHistoryState.debouceSendReadMark',
        },
        SEND_FILES_MESSAGE: {
          target: 'chatHistoryState.sendingFilesMessage',
          actions: 'updateMessages',
        },
        // handle send template normal flow
        SEND_TEMPLATE_FAILED: {
          target: 'chatHistoryState.sendMessageFailed',
          actions: ['updateErrorTemplateMessage', 'updateErrorResponse'],
        },
        // handle send message not normal flow
        SEND_MESSAGE: {
          target: ['chatHistoryState.sendMessage'],
          actions: ['updateMessages'],
        },
        SEND_MESSAGE_SUCCESS: {
          target: 'chatHistoryState.sendMessageSuccess',
          actions: 'updateAgentMessageStatus',
        },
        SEND_MESSAGE_FAILED: {
          target: 'chatHistoryState.sendMessageFailed',
          actions: ['updateErrorMessage', 'updateErrorResponse'],
        },

        SEND_TEMPLATE_SUCCESS: {
          target: 'chatHistoryState.sendMessageSuccess',
          actions: 'updateTemplateMessageStatus',
        },

        // handle resend message not normal flow
        RESEND_MESSAGE: {
          target: 'chatHistoryState.resendMessage',
          actions: 'updateResendMessageStatus',
        },
        RESEND_MESSAGE_SUCCESS: {
          target: 'chatHistoryState.sendMessageSuccess',
          actions: 'updateMessagesAfterRetrySuccess',
        },
        RESEND_MESSAGE_FAILED: {
          target: 'chatHistoryState.sendMessageFailed',
          actions: ['updateErrorMessage', 'updateErrorResponse'],
        },

        // handle send message with time not normal flow
        FETCH_WITH_TIME_EVENT: {
          target: 'chatHistoryState.fetchWithTimeEvent',
        },
      },
    },
    {
      actions: {
        updateFileErrorMessage: assign({
          fileErrorMessage: (_, event) => {
            try {
              const { data } = event;
              return data.response?.data?.message || GLOBAL_MESSAGE.ERROR_DESCRIPTION;
            } catch (error) {
              return GLOBAL_MESSAGE.ERROR_DESCRIPTION;
            }
          },
        }),
        filterRejectedFiles: assign({
          rejectFiles: (context, _) => {
            const rejectedFiles = context.selectedFiles.filter(
              (file) => !filterFileByChannel(context.channelType as EChannelType, file),
            );
            return rejectedFiles;
          },
        }),
        resetRejectedFiles: assign({
          rejectFiles: (_) => {
            return [];
          },
        }),
        setCurrentScrollPosition: assign({
          currentScrollPosition: (_context, event) => {
            return event.currentScrollPosition;
          },
        }),
        updateHistoryWithTimeBeforeSend: assign({
          chatHistory: (context, event) => {
            const { newMessage } = event;
            // restore spare history
            if (isEmpty(context.spareHistroy)) {
              return context.chatHistory;
            }
            context.chatHistory = cloneDeep(context.spareHistroy);
            context.chatHistory.messages = messageGenerator(context.chatHistory.messages, newMessage);
            context.scrollBottom = true;
            return context.chatHistory;
          },
        }),
        updateMessages: assign({
          chatHistory: (context, event) => {
            if (event.actionType === 'template') {
              context.chatHistory.messages = messageGenerator(context.chatHistory.messages, event.newMessage);
              return context.chatHistory;
            }
            context.chatHistory.messages = messageGenerator(context.chatHistory.messages, event.newMessage);
            return context.chatHistory;
          },
        }),
        updateTextMessage: assign({
          textMessage: (_context, event) => {
            return event.value;
          },
        }),
        updateSelectedFile: assign({
          selectedFiles: (_context, event) => {
            return event.value;
          },
        }),
        clearSelectedFile: assign({
          selectedFiles: (_context, _event) => {
            return [];
          },
        }),
        uploadFilesListAfterUpload: assign({
          filesListAfterUpload: (_context, event) => {
            const { data } = event.data;
            return data;
          },
        }),
        clearFilesListAfterUpload: assign({
          filesListAfterUpload: (_context, _event) => {
            return [];
          },
        }),
        findAndUpdateMessage: assign({
          chatHistory: (context, event) => {
            const { chatHistory } = context;
            const { message } = event;
            if (message.agentId === member._id) {
              return context.chatHistory;
            }
            const found = findMessage(message, chatHistory.messages);
            if (found) {
              //update messages
              const timestamp = dayjs(message.createdAt).valueOf();
              const index = context.chatHistory.messages.findIndex((message) => message.createdDate === timestamp);
              if (index > -1) {
                if (context.chatHistory.messages[index].getRetry) {
                  context.chatHistory.messages[index].retryMessage = false;
                }
              }
              return context.chatHistory;
            }

            const createdAt = dayjs(message.createdAt).valueOf();
            const newMessage = getMessage({
              id: message._id,
              time: createdAt,
              message: {
                ...message.payload,
                id: message._id,
                createdAt: createdAt,
              },
            });
            context.chatHistory.messages = messageGenerator(context.chatHistory.messages, newMessage);
            return context.chatHistory;
          },
        }),
        updateResendMessageStatus: assign({
          chatHistory: (context, event) => {
            const { messagePayload, actionType, trackId } = event;
            if (actionType === 'template') {
              const index = context.chatHistory.messages.findIndex((message) => message.getId === trackId);

              const newMessages = cloneDeep(context.chatHistory);
              if (index > -1) {
                newMessages.messages[index].setMessageStatus = EMessageStatus.SENDING;
              }
              return newMessages;
            } else {
              const index = context.chatHistory.messages.findIndex(
                (message) => message.createdDate === messagePayload.createdAt,
              );
              const newMessages = cloneDeep(context.chatHistory);
              if (index > -1) {
                newMessages.messages[index].setMessageStatus = EMessageStatus.SENDING;
              }
              return newMessages;
            }
          },
        }),
        updateAgentMessageStatus: assign({
          chatHistory: (context, event) => {
            const { messagePayload } = event;
            const index = context.chatHistory.messages.findIndex(
              (message) => message.createdDate === messagePayload.createdAt,
            );
            const newMessages = cloneDeep(context.chatHistory);
            if (index > -1) {
              newMessages.messages[index].setMessageStatus = EMessageStatus.SENT;
            }
            return newMessages;
          },
        }),
        updateTemplateMessageStatus: assign({
          chatHistory: (context, event) => {
            const { messagePayload, status, actionType, fromResend, trackId, livechatMessage } = event;
            const newMessages = cloneDeep(context.chatHistory);

            if (actionType === 'template') {
              if (fromResend && trackId) {
                // find message and remove TemplateFailed message
                const index = newMessages.messages.findIndex((message) => message.getId === trackId);
                if (index > -1) {
                  newMessages.messages.splice(index, 1);
                }
                // update create date
                for (const i of livechatMessage) {
                  i.setCreateDate = dayjs().valueOf();
                }

                // append temp message to chat history
                newMessages.messages = messageGenerator(newMessages.messages, livechatMessage);
              }

              return newMessages;
            }
            for (const [index, item] of newMessages.messages.entries()) {
              const foundIndex = messagePayload.findIndex(({ id }: { id: string }) => id === item.getId);
              if (foundIndex > -1) {
                newMessages.messages[index].setMessageStatus = status;
              }
            }

            context.chatHistory = newMessages;
            return context.chatHistory;
          },
        }),
        updateErrorMessage: assign({
          chatHistory: (context, event) => {
            const data = event.data;
            // find message
            const newMessages = cloneDeep(context.chatHistory);
            const index = newMessages.messages.findIndex((message) => message.createdDate === data.createdAt);
            if (index > -1) {
              newMessages.messages[index].retryMessage = true;
              newMessages.messages[index].setMessageStatus = EMessageStatus.FAILED;
              return newMessages;
            }
            return newMessages;
          },
        }),
        updateErrorResponse: assign({
          errorResponse: (context, event) => {
            const name = event?.error?.error?.response?.data?.name ?? '';
            const message = event?.error?.error?.response?.data?.message ?? '';

            return {
              name: name,
              message: message,
            };
          },
        }),
        updateErrorTemplateMessage: assign({
          chatHistory: (context, event) => {
            const { messagePayload, fromResend, trackId, newMessage, previewMessage } = event.data;
            if (fromResend) {
              // update failed message status
              const index = context.chatHistory.messages.findIndex((message) => message.getId === trackId);
              if (index > -1) {
                const newChatHistory = cloneDeep(context.chatHistory);
                newChatHistory.messages[index].setMessageStatus = EMessageStatus.FAILED;
                context.chatHistory = newChatHistory;
              }
              return context.chatHistory;
            }
            // remove message that match with trackId
            // const newContext = cloneDeep(context.chatHistory);
            const result = context.chatHistory.messages.filter((message) => message.getMetadata?.trackId !== trackId);
            result.push(
              new TemplateFailed({
                id: uuid(),
                createdAt: dayjs().valueOf(),
                messageStatus: EMessageStatus.FAILED,
                payload: messagePayload,
                senderName: member.username,
                livechatMessages: newMessage,
                previewMessage: previewMessage,
                sender: 'agent',
              }),
            );

            context.chatHistory.messages = result;
            return context.chatHistory;
          },
        }),
        updateErrorMessages: assign({
          chatHistory: (context, event) => {
            const data = event.data;
            // find message
            const newMessages = cloneDeep(context.chatHistory);
            for (const [index, item] of newMessages.messages.entries()) {
              if (item.createdDate === data.createdAt) {
                newMessages.messages[index].retryMessage = true;
                newMessages.messages[index].setMessageStatus = EMessageStatus.FAILED;
              }
            }
            return newMessages;
          },
        }),
        updateMessagesAfterRetrySuccess: assign({
          chatHistory: (context, event) => {
            const data = event.data;
            // find message
            const chatHistory = cloneDeep(context.chatHistory);
            const index = chatHistory.messages.findIndex((message) => message.createdDate === data.createdAt);

            if (index > -1) {
              chatHistory.messages[index].retryMessage = false;
              chatHistory.messages[index].setMessageStatus = EMessageStatus.SENT;
              // update date
              chatHistory.messages[index].setCreateDate = dayjs().valueOf();
              chatHistory.messages = updateMessagesAfterRetrySuccess(
                chatHistory.messages,
                chatHistory.messages[index],
                index,
              );
            }
            return chatHistory;
          },
        }),
        updateChatHistoryForNextMessage: assign({
          chatHistory: (context, event) => {
            const { data } = event;
            return {
              ...context.chatHistory,
              nextRemain: data.nextRemain,
              messages: data.messages,
            };
          },
        }),
        updateChatHistory: assign({
          chatHistory: (context, event) => {
            const { data } = event;
            let cloneChatHistory = cloneDeep(context.chatHistory);
            cloneChatHistory = data.chatHistory;
            return cloneChatHistory;
          },
        }),
        updateScrollBottom: assign({
          scrollBottom: (_, event) => {
            const { data } = event;
            return data.scrollBottom;
          },
        }),
      },
      guards: {
        shouldSendReadMark: () => !!asReader,
      },
    },
  );
};

export default messageBoxMachine;
