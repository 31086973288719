import { EMessageType } from '@enums/MessageType';
import { BaseMessage, BaseMessageJson } from './BaseMessage';
import { IMessageLevel } from '@types';
import { FlexContainer } from 'flex-render';

export interface IReplyMessageData {
  platformRepliableReference: PlatformRepliableReference;
  data: Data;
}

export interface Data {
  sender: string;
  id: string;
  createdAt: string;
  senderName: string;
  senderImage: string;
  messageType: EMessageType;
  text: string;
  url: string;
  contents?: FlexContainer;
  altText: string;
}

export interface PlatformRepliableReference {
  quotedMessageId: string;
}

export type IReplyMessageInstanceData = {
  id: string;
  replyTo: IReplyMessageData;
  createdAt: number;
  level: IMessageLevel;
  retry: boolean;
  senderImage?: string;
  senderName: string;
  text: string;
};

export type ReplyMessageJson = {
  replyTo: IReplyMessageData;
  text: string;
} & BaseMessageJson;

export class ReplyMessage extends BaseMessage {
  private replyTo: IReplyMessageData;
  private level: IMessageLevel;
  private text: string;
  constructor(data: ReplyMessageJson) {
    const { id, createdAt } = data;
    super({ ...data, id, createdAt, senderImage: data.senderImage });
    this.replyTo = data.replyTo; // not garuntee that it will be first message
    this.level = 1;
    this.text = data.text;
  }

  get instanceData(): IReplyMessageInstanceData {
    return {
      id: this.id,
      replyTo: this.replyTo,
      createdAt: this.createdAt,
      level: this.level,
      retry: this.retry,
      senderImage: this.senderImage,
      senderName: this.senderName,
      text: this.text,
    };
  }

  get getText(): string {
    return this.text;
  }

  set setLevel(level: IMessageLevel) {
    this.level = level;
  }
}
