export const convertMinToMs = (min: number) => {
  return min * 60 * 1000;
};

export const convertMsToMin = (ms: number) => {
  return ms / 60000;
};

export const convertTimeToMin = (hr: number, min: number) => {
  return hr * 60 + min;
};
