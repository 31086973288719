import React from 'react';
import {
  ArrowWrap,
  BlankDayItem,
  BottomSection,
  CalendarContainer,
  ChangeMonthButton,
  CloseIcon,
  DayItem,
  Divider,
  HeaderItem,
  MonthYearSelectionWarp,
  SelectionWrap,
  TopSection,
} from './style';
import { GhostButton, PrimaryButton } from '@components/Button';
import { Font20G1W600, Font16G1W600, Font10G5W600, Font16G3W600 } from '@components/UtilsComponent';
import { faTimes, faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { uniqueId } from 'lodash';
import { useDatePicker } from '@hooks/useDatePicker';
import dayjs, { Dayjs } from 'dayjs';

type IOneYearDatePickerProps = {
  dateSelected?: string; // '2023-02-21T09:19:10.705Z'
  clearBtnTxt?: string;
  doneBtnTxt?: string;
  onUpdateTriggerDate: (date: string) => void;
  isDisabledDate?: (date: Dayjs) => boolean;
  onClose: () => void;
};

export const OneYearDatePicker = ({
  dateSelected,
  clearBtnTxt = 'Clear',
  doneBtnTxt = 'Done',
  onUpdateTriggerDate,
  isDisabledDate,
  onClose,
}: IOneYearDatePickerProps) => {
  const { currentShow, showYearOptions, selectedDate, onChangeMonth, onSelect, onConfirm, isToday, isSelectedDate } =
    useDatePicker(onUpdateTriggerDate, dateSelected);

  const currentDate = dayjs();
  const DAY_IN_WEEK = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  const _renderDate = () => {
    const items = [];
    const startDay = currentShow.startOf('month').day();
    for (let i = -startDay + 1; i < currentShow.daysInMonth() + 1; i++) {
      if (i <= 0) {
        items.push(<BlankDayItem key={uniqueId()} />);
      } else {
        const d = currentShow.date(i);
        items.push(
          <DayItem
            key={uniqueId()}
            isSeleced={isSelectedDate(i)}
            isToday={isToday(i)}
            data-testid={d.format('DD-MM-YYYY')}
            disabled={isDisabledDate ? isDisabledDate(d) : false}
            isDisabled={isDisabledDate ? isDisabledDate(d) : false}
            onClick={() => {
              onSelect(i);
            }}
          >
            {i}
          </DayItem>,
        );
      }
    }
    return items;
  };
  return (
    <div>
      <TopSection>
        <Font20G1W600>{currentDate.format('DD MMMM YYYY')}</Font20G1W600>
        <CloseIcon icon={faTimes} onClick={onClose} />
      </TopSection>
      <Divider />
      <SelectionWrap>
        <MonthYearSelectionWarp>
          <Font16G1W600>{currentShow.format('MMMM')}</Font16G1W600>
          <Font16G1W600>{currentShow.format('YYYY')}</Font16G1W600>
        </MonthYearSelectionWarp>
        <ArrowWrap>
          <ChangeMonthButton
            data-testid="prev-btn"
            onClick={() => onChangeMonth('prev')}
            // disable if current month is January
            disabled={currentShow.format('MM') === '01'}
          >
            <FontAwesomeIcon
              icon={faChevronLeft}
              style={{
                color: ' #616161',
              }}
            />
          </ChangeMonthButton>
          <ChangeMonthButton
            data-testid="next-btn"
            onClick={() => onChangeMonth('next')} // disable if current month is January
            disabled={currentShow.format('MM') === '12'}
          >
            <FontAwesomeIcon
              icon={faChevronRight}
              style={{
                color: '#616161',
              }}
            />
          </ChangeMonthButton>
        </ArrowWrap>
      </SelectionWrap>

      <CalendarContainer isShow={!showYearOptions}>
        <>
          {DAY_IN_WEEK.map((day) => {
            return (
              <HeaderItem key={day}>
                <Font10G5W600>{day}</Font10G5W600>
              </HeaderItem>
            );
          })}
          {_renderDate()}
        </>
      </CalendarContainer>
      <Divider />
      <BottomSection>
        <GhostButton size="large" onClick={onClose} disabled={!selectedDate}>
          <Font16G3W600>{clearBtnTxt}</Font16G3W600>
        </GhostButton>
        <PrimaryButton size="large" onClick={onConfirm} disabled={!selectedDate}>
          {doneBtnTxt}
        </PrimaryButton>
      </BottomSection>
    </div>
  );
};
