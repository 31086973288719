import _, { cloneDeep } from 'lodash';
import { createMachine, assign } from 'xstate';
import { getAvailableMembersIncludeTeam, getTeamData, getAllBotMembers } from '../fns';
import { updateTeam } from '../fns/updateTeam';
import { IMemberList, IMember } from '../TeamManagementPane/teamManagementMachine';
import { IPublicHolidayFE, IWorkHourDays } from '@types';
import { DEFAULT_WORKING_HOURS } from '@configs/constants';
export type IVaConfig = {
  _id: string;
  username: string;
  nid: string;
  status: string;
  socket: Array<any>;
  roles: string[];
  email: string;
  displayName: string;
  createdAt: string;
};
export type ITeamData = {
  _id: string;
  name: string;
  department: string;
  default: boolean;
  agents: IMember[];
  virtualAgentConfig?: {
    passive: IVaConfig;
    active: IVaConfig;
  };
  workHours: {
    enabled: boolean;
    enablePublicHolidays: boolean;
    enableOffHourAssignment: boolean;
    enableSLAPause: boolean;
    enableOffHourAutoReply: boolean;
    offHourAutoReplyTemplateId: string;
    days: IWorkHourDays;
    publicHolidays: IPublicHolidayFE;
  };
};

export type IEditTeamEditorContext = {
  teamId: string;
  searchTxt: string;
  availableMembers: IMemberList;
  selectedMembers: string[];
  currentPage: number;
  errorMessage: string;
  teamData: ITeamData;
};

const editTeamEditorMachine = (teamId: string) =>
  createMachine<IEditTeamEditorContext>(
    {
      id: 'team-management-edit-team-editor-machine',
      initial: 'fetchingTeamData',
      context: {
        teamId,
        selectedMembers: [],
        searchTxt: '',
        availableMembers: {} as IMemberList,
        currentPage: 1,
        errorMessage: '',
        teamData: {} as ITeamData,
      },
      states: {
        editing: {
          on: {
            SET_SELECTED_MEMBERS: {
              target: 'editing',
              actions: 'updateSelectedMembers',
            },
            SUBMIT_FORM: {
              target: 'saving',
            },
            FETCH_MEMBER: {
              target: 'fetchingAvailableMembers',
              actions: 'updateCurrentPage',
            },
            FILTER_OUT_BOT_MEMBER: {
              target: 'filteringOutBotMembers',
            },
          },
        },
        fetchingTeamData: {
          invoke: {
            id: 'fetch-team-data',
            src: getTeamData,
            onDone: {
              target: 'fetchTeamDataSuccess',
              actions: ['updateTeamData', 'updateAvailableMembers', 'updateDefaultSelectedMembers'],
            },
            onError: 'fetchTeamDataFailed',
          },
        },
        fetchTeamDataSuccess: {
          after: {
            0: {
              target: 'editing',
            },
          },
        },
        fetchTeamDataFailed: {},
        fetchingAvailableMembers: {
          invoke: {
            id: 'fetch-available-member',
            src: getAvailableMembersIncludeTeam,
            onDone: {
              target: 'editing',
              actions: 'updateAvailableMembersFromSearch',
            },
            onError: 'fetchAvailableMembersFailed',
          },
        },
        fetchAvailableMembersFailed: {},
        debounceFetchMember: {
          on: {
            SEARCH_TEXT_CHANGE: {
              target: 'debounceFetchMember',
              actions: 'updateSearchTxt',
            },
          },
          after: {
            450: {
              target: 'fetchingAvailableMembers',
            },
          },
        },
        saving: {
          invoke: {
            id: 'update-team',
            src: updateTeam,
            onDone: {
              target: 'updateTeamSuccess',
            },
            onError: {
              target: 'editing',
              actions: 'updateErrorMessage',
            },
          },
        },
        updateTeamSuccess: {
          type: 'final',
        },
        filteringOutBotMembers: {
          invoke: {
            id: 'filtering-out-bot-members',
            src: getAllBotMembers,
            onDone: {
              target: 'editing',
              actions: 'postUpdateFilteredBotMembers',
            },
            onError: {
              target: 'editing',
              actions: 'updateErrorMesssage',
            },
          },
        },
      },
      on: {
        SEARCH_TEXT_CHANGE: {
          target: '.debounceFetchMember',
          actions: 'updateSearchTxt',
        },
        UPDATE_WORK_HOURS: {
          actions: 'updateWorkHours',
        },
        UPDATE_PUBLIC_HOLIDAYS: {
          actions: 'updatePublicHolidays',
        },
        TOGGLE_PUBLIC_HOLIDAYS: {
          actions: 'togglePublicHolidays',
        },
      },
    },
    {
      actions: {
        updateTeamData: assign({
          teamData: (_context, event) => {
            const { teamResult } = event.data;
            const newTeamContext = {
              ...teamResult,
              workHours: teamResult.workHours
                ? {
                    enabled: teamResult.workHours?.enabled ?? false,
                    enablePublicHolidays: teamResult.workHours?.enablePublicHolidays ?? false,
                    enableOffHourAssignment: teamResult.workHours?.enableOffHourAssignment ?? false,
                    enableSLAPause: teamResult.workHours?.enableSLAPause ?? false,
                    enableOffHourAutoReply: teamResult.workHours?.enableOffHourAutoReply ?? false,
                    offHourAutoReplyTemplateId: '',
                    days: {
                      monday: teamResult.workHours?.days?.monday ?? cloneDeep(DEFAULT_WORKING_HOURS),
                      tuesday: teamResult.workHours?.days?.tuesday ?? cloneDeep(DEFAULT_WORKING_HOURS),
                      wednesday: teamResult.workHours?.days?.wednesday ?? cloneDeep(DEFAULT_WORKING_HOURS),
                      thursday: teamResult.workHours?.days?.thursday ?? cloneDeep(DEFAULT_WORKING_HOURS),
                      friday: teamResult.workHours?.days?.friday ?? cloneDeep(DEFAULT_WORKING_HOURS),
                      saturday: teamResult.workHours?.days?.saturday ?? cloneDeep(DEFAULT_WORKING_HOURS),
                      sunday: teamResult.workHours?.days?.sunday ?? cloneDeep(DEFAULT_WORKING_HOURS),
                    },
                    publicHolidays: {
                      enabled: teamResult.workHours?.enablePublicHolidays ?? false,
                      timeslots: teamResult.workHours?.days?.publicHolidays ?? cloneDeep(DEFAULT_WORKING_HOURS),
                    },
                  }
                : {
                    enabled: false,
                    enablePublicHolidays: false,
                    enableOffHourAssignment: false,
                    enableSLAPause: false,
                    enableOffHourAutoReply: false,
                    offHourAutoReplyTemplateId: '',
                    days: {
                      monday: cloneDeep(DEFAULT_WORKING_HOURS),
                      tuesday: cloneDeep(DEFAULT_WORKING_HOURS),
                      wednesday: cloneDeep(DEFAULT_WORKING_HOURS),
                      thursday: cloneDeep(DEFAULT_WORKING_HOURS),
                      friday: cloneDeep(DEFAULT_WORKING_HOURS),
                      saturday: cloneDeep(DEFAULT_WORKING_HOURS),
                      sunday: cloneDeep(DEFAULT_WORKING_HOURS),
                    },
                    publicHolidays: {
                      enabled: false,
                      timeslots: cloneDeep(DEFAULT_WORKING_HOURS),
                    },
                  },
            };
            return newTeamContext;
          },
        }),
        updateWorkHours: assign({
          teamData: (context, event) => {
            const { day, payload } = event;
            return {
              ...context.teamData,
              workHours: {
                ...context.teamData.workHours,
                days: {
                  ...context.teamData.workHours.days,
                  [day]: payload,
                },
              },
            };
          },
        }),
        updatePublicHolidays: assign({
          teamData: (context, event) => {
            const { payload } = event;
            return {
              ...context.teamData,
              workHours: {
                ...context.teamData.workHours,
                publicHolidays: {
                  enabled: context.teamData.workHours.publicHolidays.enabled,
                  timeslots: payload,
                },
              },
            };
          },
        }),
        togglePublicHolidays: assign({
          teamData: (context, event) => {
            const { checked } = event;
            return {
              ...context.teamData,
              workHours: {
                ...context.teamData.workHours,
                publicHolidays: {
                  enabled: checked,
                  timeslots: context.teamData.workHours.publicHolidays.timeslots,
                },
              },
            };
          },
        }),
        updateSearchTxt: assign({
          searchTxt: (context, event) => {
            return event.value;
          },
        }),
        updateAvailableMembers: assign({
          availableMembers: (context, event) => {
            const { members } = event.data;
            return members;
          },
        }),
        updateAvailableMembersFromSearch: assign({
          availableMembers: (context, event) => {
            const { data } = event.data;
            return data;
          },
        }),
        updateDefaultSelectedMembers: assign({
          selectedMembers: (context, event) => {
            const { teamData } = context;
            const selectedRowKeys = teamData?.agents?.map((agents) => agents?._id);
            return selectedRowKeys;
          },
        }),
        updateSelectedMembers: assign({
          selectedMembers: (context, event) => {
            const { data } = event;
            return data;
          },
        }),
        updateCurrentPage: assign({
          currentPage: (context, event) => {
            return event.page;
          },
        }),
        updateErrorMessage: assign({
          errorMessage: (context, event) => {
            return event.data?.response?.data?.message ?? 'Something went wrong';
          },
        }),
        postUpdateFilteredBotMembers: assign({
          selectedMembers: (context, event) => {
            const bots: (Record<string, unknown> & { _id: string })[] = event.data.data.rows;
            const keys: string[] = bots.map((bot) => bot._id);
            return _.without(context.selectedMembers, ...keys);
          },
        }),
      },
    },
  );

export default editTeamEditorMachine;
