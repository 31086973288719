import React from 'react';
import { faPlay, faReply, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useReplyMessage } from '@providers/ReplyMessageProvider';
import { Font14G1W400, Font14G5W400 } from '@components/UtilsComponent';
import { STICKER_PREFIX } from '@configs/constants';
import useTranslation from '@hooks/useTranslation';
import {
  CombineAllMessage,
  ImageMessage,
  LinkMessage,
  TextMessage,
  VideoMessage,
  ReplyMessage,
  StickerMessage,
  PostbackMessage,
} from '@model/MessageItem';
import useSticker from '@pages/components/Message/Sticker/useSticker';
export const AgentReplyMessage = () => {
  const t = useTranslation();
  const { selectedReplyMessage, setSelectedReplyMessage } = useReplyMessage();
  const getMedia = (message?: CombineAllMessage | null) => {
    if (message instanceof ImageMessage) {
      return (
        <div className="flex items-center justify-center">
          <img src={message.getUrl} alt="media" className="w-[44px] h-[44px] rounded-[4px] object-cover" />
        </div>
      );
    }
    if (message instanceof VideoMessage) {
      return (
        <div className="flex items-center justify-center relative">
          <FontAwesomeIcon
            icon={faPlay}
            className="text-[12px] absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-white z-10"
          />
          <div className="absolute w-[44px] h-[44px] rounded-[4px] bg-[rgba(0,0,0,0.5)]" />
          <div className="w-[44px] h-[44px] rounded-[4px]" />
        </div>
      );
    }

    if (message instanceof TextMessage) {
      if (message.getText.includes(STICKER_PREFIX)) {
        return <StickerReplyMessage url={message.getText || ''} />;
      }
      return null;
    }

    if (message instanceof ReplyMessage) {
      if (message.getText.includes(STICKER_PREFIX)) {
        return <StickerReplyMessage key={message.getText} url={message.getText || ''} />;
      }
      return null;
    }

    if (message instanceof StickerMessage) {
      return <StickerReplyMessage url={message.getText || ''} />;
    }

    return null;
  };
  const getDescription = (message?: CombineAllMessage | null) => {
    if (message instanceof ImageMessage) {
      return <Font14G5W400>{t('reply.message.image.label')}</Font14G5W400>;
    }
    if (message instanceof VideoMessage) {
      return <Font14G5W400>{t('reply.message.video.label')}</Font14G5W400>;
    }
    if (message instanceof LinkMessage) {
      return <Font14G5W400>{message.getUrl}</Font14G5W400>;
    }
    if (message instanceof StickerMessage) {
      return <Font14G5W400>{t('reply.message.sticker.label')}</Font14G5W400>;
    }
    if (message instanceof TextMessage && message.getText.includes(STICKER_PREFIX)) {
      return <Font14G5W400>{t('reply.message.sticker.label')}</Font14G5W400>;
    }
    if (message instanceof TextMessage) {
      return <Font14G5W400>{message.getText}</Font14G5W400>;
    }
    if (message instanceof ReplyMessage && message.getText.includes(STICKER_PREFIX)) {
      return <Font14G5W400>{t('reply.message.sticker.label')}</Font14G5W400>;
    }
    if (message instanceof ReplyMessage) {
      return <Font14G5W400>{message.getText}</Font14G5W400>;
    }

    if (message instanceof PostbackMessage) {
      return <Font14G5W400>{message.getText}</Font14G5W400>;
    }
    return null;
  };
  return (
    <div
      style={{
        display: selectedReplyMessage ? 'flex' : 'none',
      }}
      id="agent-reply-message-container"
      className="pb-[16px]"
    >
      <div className="relative w-full p-[16px] flex rounded-[8px] bg-[#F5F5F5]">
        <div className="flex w-full flex-col gap-[12px] gap-x-[8px]">
          <div className="flex gap-x-[8px] items-center">
            <FontAwesomeIcon icon={faReply} className="text-[#616161]" />
            <Font14G5W400>กำลังตอบกลับ</Font14G5W400>
            <Font14G1W400>{selectedReplyMessage?.getSenderName}</Font14G1W400>
          </div>
          <div
            style={{
              wordBreak: 'break-word',
            }}
          >
            {getDescription(selectedReplyMessage)}
          </div>
        </div>
        <div>{getMedia(selectedReplyMessage)}</div>

        <button
          className="absolute top-[-5px] right-[-5px]"
          onClick={() => setSelectedReplyMessage(null)}
          style={{
            color: 'rgba(66, 66, 66, 0.30)',
          }}
        >
          <FontAwesomeIcon icon={faTimesCircle} className="text-[18px]" />
        </button>
      </div>
    </div>
  );
};

const StickerReplyMessage = ({ url, width = 48, height = 48 }: { url: string; width?: number; height?: number }) => {
  const stickerData = url.replace(STICKER_PREFIX, '').split('_').pop() || '';
  const { ref, onError } = useSticker(stickerData);
  return (
    <img
      ref={ref}
      className="object-contain"
      style={{
        minWidth: `${width}px`,
        minHeight: `${height}px`,
        maxWidth: `${width}px`,
        maxHeight: `${height}px`,
      }}
      onError={onError}
    />
  );
};
