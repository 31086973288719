import { PrimaryButton } from '@components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

const BASE_12 = `
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.1px;
`;

const BASE_20 = `
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.4px;
`;

export const Text = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.GREY_1};
`;

export const BoldText = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.GREY_1};
`;

export const Icon = styled(FontAwesomeIcon)`
  font-size: 18px;
  color: ${({ theme }) => theme.GREY_1};
`;

export const DisabledIcon = styled(FontAwesomeIcon)`
  font-size: 18px;
  color: ${({ theme }) => theme.GREY_6};
`;

export const Spacing = styled.div<{ height: number }>`
  height: ${({ height }) => height}px;
`;

export const Font10WhiteW400 = styled.span`
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  color: ${({ theme }) => theme.WHITE};
`;

export const Font10G5W600 = styled.span`
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  color: ${({ theme }) => theme.GREY_5};
`;

export const Font20G1W600 = styled.span`
  ${BASE_20}
  font-weight: 600;
  color: ${({ theme }) => theme.GREY_1};
`;

export const Font20PrimaryW600 = styled.span`
  ${BASE_20}
  font-weight: 600;
  color: ${({ theme }) => theme.PRIMARY};
`;

export const Font20G3W600 = styled.span`
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.GREY_3};
  letter-spacing: -0.4px;
  line-height: 32px;
`;

export const Font12G3W300 = styled.span`
  font-weight: 300;
  ${BASE_12}
  color: ${({ theme }) => theme.GREY_3};
`;

export const Font12WhiteW400 = styled.span`
  font-weight: 400;
  ${BASE_12}
  color: ${({ theme }) => theme.WHITE};
`;

export const Font12WhiteW500 = styled.span`
  font-weight: 500;
  ${BASE_12}
  color: ${({ theme }) => theme.WHITE};
`;

export const Font12G1W500 = styled.span`
  font-weight: 500;
  ${BASE_12}
  color: ${({ theme }) => theme.GREY_1};
`;

export const Font12G1W700 = styled.span`
  font-weight: 700;
  ${BASE_12}
  color: ${({ theme }) => theme.GREY_1};
`;

export const Font12G3W400 = styled.span`
  font-weight: 400;
  ${BASE_12}
  color: ${({ theme }) => theme.GREY_3};
`;

export const Font12G4W400 = styled.span`
  font-weight: 400;
  ${BASE_12}
  color: ${({ theme }) => theme.GREY_4};
`;

export const Font12G3W500 = styled.span`
  font-weight: 500;
  ${BASE_12}
  color: ${({ theme }) => theme.GREY_3};
`;

export const Font12G4W500 = styled.span`
  font-weight: 500;
  ${BASE_12}
  color: ${({ theme }) => theme.GREY_4};
`;

export const Font12G4W700 = styled.span`
  font-weight: 700;
  ${BASE_12}
  color: ${({ theme }) => theme.GREY_4};
`;

export const Font12G5W400 = styled.span`
  font-weight: 400;
  ${BASE_12}
  color: ${({ theme }) => theme.GREY_5};
`;

export const Font12G1W400 = styled.span`
  font-weight: 400;
  ${BASE_12}
  color: ${({ theme }) => theme.GREY_1};
`;

export const Font12G5W500 = styled.span`
  ${BASE_12}
  line-height: 16px;
  color: ${({ theme }) => theme.GREY_5};
  margin: 0;
`;

export const Font14G3W400 = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.GREY_3};
`;

export const Font14G3W500 = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.GREY_3};
`;

export const Font14G3W600 = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.GREY_3};
`;

export const Font14G3W700 = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.GREY_3};
`;

export const Font14G4W400 = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.GREY_4};
`;

export const Font14G1W700 = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.GREY_1};
`;

export const Font14G1W500 = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.GREY_1};
`;

export const Font14G1W400 = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.GREY_1};
`;

export const Font14G1W400Outline = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.GREY_1};
  -webkit-text-stroke: 3px white;
  paint-order: stroke fill;
`;

export const Font14WhiteW500 = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.WHITE};
`;

export const Font14WhiteW600 = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.WHITE};
`;

export const Font14G1W600 = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.GREY_1};
`;

export const Font14G5W400 = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.GREY_5};
`;

export const Font14G5W600 = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.GREY_5};
`;

export const Font14G5W500 = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.GREY_5};
`;

export const Font14PrimaryW500 = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.PRIMARY};
`;

export const Font14PrimaryW600 = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.PRIMARY};
`;

export const Font14PrimaryW400 = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.PRIMARY};
`;

export const Font16G1W400 = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.GREY_1};
`;

export const Font16G1W500 = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.GREY_1};
`;

export const Font16G1W700 = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.GREY_1};
`;

export const Font16G3W400 = styled.span`
  color: ${({ theme }) => theme.GREY_3};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
`;

export const Font16G3W500 = styled.p`
  color: ${({ theme }) => theme.GREY_3};
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  white-space: pre-line;
  line-height: 24px;
`;

export const Font16G3W600 = styled.p`
  color: ${({ theme }) => theme.GREY_3};
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  white-space: pre-line;
  line-height: 24px;
`;

export const Font16G1W600 = styled.span`
  color: ${({ theme }) => theme.GREY_1};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
`;

export const Font16G4W700 = styled.span`
  color: ${({ theme }) => theme.GREY_4};
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
`;

export const Font10G3W600 = styled.span`
  color: ${({ theme }) => theme.GREY_4};
  font-size: 10px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
  line-height: 12px;
`;

export const Font24G1W700 = styled.span`
  color: ${({ theme }) => theme.GREY_1};
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  margin: 0;
  letter-spacing: -0.4px;
`;

export const Font32G1W500 = styled.span`
  color: ${({ theme }) => theme.GREY_1};
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
  margin: 0;
  letter-spacing: -0.4px;
`;

export const FullWidth = styled.div`
  width: 100%;
`;

export const RedNote = styled.span`
  color: ${({ theme }) => theme.RED};
  display: inline !important;
`;

export const ValidateContainer = styled.div<{ isInvalidate: boolean; id?: string }>`
  .select-area {
    ${({ isInvalidate }) => (isInvalidate ? `border: 1px solid red !important;` : ``)}
  }
  ${({ isInvalidate, id }) =>
    id &&
    `
    #${id} {
      border-radius: 8px;
      ${isInvalidate ? `border: 1px solid red !important;` : ``}
    }
  `}
`;

export const ValidateMessage = styled.span`
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.RED};
`;
export const MediaIconLarge = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.GREY_6};
  font-size: 72px;
  margin-bottom: 18px;
`;

export const ToastWrap = styled.div`
  margin-bottom: 8px;
  word-break: break-word;
  > div > div {
    display: flex;
  }
`;

export const Frame = styled.div<{ width: string; height: string }>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

export const ResolveBtn = styled(PrimaryButton)`
  height: 36px;
  font-size: 16px;
  width: auto;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.2px;
  text-align: left;
  .ant-btn-loading-icon {
    display: flex;
    align-items: center;
  }
`;

export const IconWrap = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #757575;
  svg {
    font-size: 16px !important;
  }
`;

export const HyperLink = styled(Link)`
  color: ${({ theme }) => theme.BLUE};
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
`;

export const FrameV2 = styled.div<{ width: number; height: number; centered?: boolean }>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  ${({ centered }) =>
    centered &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

export const TextCentered = styled.div`
  text-align: center;
`;

export const NormalBtn = styled.button`
  padding: 6px 12px;
  min-width: 42px;
  height: 32px;
  align-items: center;
  display: flex;
  cursor: pointer;
  background: transparent;
  border: none;
  justify-content: center;
  border-radius: 8px;
  :hover {
    background-color: ${({ theme }) => theme.GREY_9};
  }
`;

export const CustomButton = styled.button`
  padding: 6px 12px;
  min-width: 42px;
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: transparent;
  border: none;
  border-radius: 8px;
  :hover {
    background-color: ${({ theme }) => theme.GREY_9};
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.GREY_7};
  margin: 16px 0;
`;

export const DropdownItem = styled.li`
  cursor: pointer;
  width: 100%;
  height: 44px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    flex: 1;
    height: 100%;
    line-height: 22px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const BgDot = styled.div`
  --dot-bg: white;
  --dot-color: #e9e9e9;
  --dot-size: 3px;
  --dot-space: 40px;
  background: linear-gradient(90deg, var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center /
      var(--dot-space) var(--dot-space),
    linear-gradient(var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center / var(--dot-space)
      var(--dot-space),
    var(--dot-color);
`;

export const Counter = styled.div`
  text-align: right;
`;

export const TrashIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.RED};
`;
