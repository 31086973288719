import { client } from '@api';
import { IAccountConfig } from '@pages/desktop/settings/GeneralManagement/generalSettingMachine';
import { IConfigRequestParams, IPublicHolidayRequestParams } from '@types';
import { AxiosResponse } from 'axios';

export const getGeneralConfig = (): Promise<AxiosResponse<IAccountConfig>> => {
  return client.get('/api/accounts/config');
};

export const saveGeneralConfig = (payload: IConfigRequestParams) => {
  return client.put('/api/accounts/config', payload);
};

export const savePublicHoliday = (payload: IPublicHolidayRequestParams) => {
  return client.put('/api/accounts/config/public-holidays', payload);
};
