import React, { useEffect } from 'react';
import VaIcon from '@assets/images/bot-va-icon.svg';
import { Font12G1W400, Font12G5W400, Font14G1W400, Font14G1W600, Font16G1W600 } from '@components/UtilsComponent';
import useTranslation from '@hooks/useTranslation';
import { EDropdownSize } from '@components/Dropdown';
import { DropdownAutoSearch } from '@components/DropdownAutoSearch';
import { useVa } from './useVa';

export type IVaHandler = {
  selected: {
    id: string;
    name: string;
  };
  onSelect: (vaId: string, name: string) => void;
};

type IVaBlockProps = {
  firstResponseHandlerProp: IVaHandler;
  coPilotHandlerProp: IVaHandler;
};

export const VaBlock = ({ firstResponseHandlerProp, coPilotHandlerProp }: IVaBlockProps) => {
  const t = useTranslation();
  const { coPilotHandler } = useVa();

  useEffect(() => {
    coPilotHandler.coPilotInfinit.onOpenDropdown();
  }, []);
  return (
    <>
      <div>
        <Font16G1W600>{t('va.block.label')}</Font16G1W600>
      </div>
      <div className="mt-[16px] w-fit p-[16px] rounded-[8px] border border-solid border-line-default flex flex-col gap-y-[16px]">
        <div className="flex gap-x-[8px] items-center">
          <img src={VaIcon} className="w-[32px] h-[32px]" alt="va-icon" />
          <Font14G1W600>{t('va.block.agent.co.pilot')}</Font14G1W600>
        </div>
        {/* <div className="flex gap-x-[16px] items-center">
        <Font14G1W400 className="min-w-[100px]">{t('va.block.first.response.label')}</Font14G1W400>
        <div className="w-[300px]">
          <DropdownAutoSearch<{
            id: string;
            name: string;
            source: string;
          }>
            size={EDropdownSize.MIDDLE}
            keyword={''}
            selected={firstResponseHandler.selected.name}
            isDisabled={false}
            options={newOptions}
            emptyWord={''}
            inputPlaceholder={t('va.block.select.placeholder')}
            onScroll={function (e: React.UIEvent<HTMLDivElement, UIEvent>): void {
              throw new Error('Function not implemented.');
            }}
            onSelect={function (value: { id: string; name: string }): void {
              throw new Error('Function not implemented.');
            }}
            onClearSearchKeyword={function (): void {
              throw new Error('Function not implemented.');
            }}
            renderCustomDropdownContent={(items) => {
              return items.map((item) => {
                return (
                  <button
                    onClick={() => {
                      firstResponseHandler.onSelect(item.id, item.name);
                    }}
                    key={item.id}
                    style={{
                      backgroundColor: firstResponseHandler.selected.id === item.id ? '#f5f5f5' : 'transparent',
                    }}
                    className="px-[16px] py-[10px] flex flex-col cursor-pointer hover:bg-[#fafafa] w-full"
                  >
                    <Font14G1W400>{item.name}</Font14G1W400>
                    <Font12G5W400>{item.source}</Font12G5W400>
                  </button>
                );
              });
            }}
            onInputChange={function (value: string): void {
              throw new Error('Function not implemented.');
            }}
            onClearSelected={function (): void {
              throw new Error('Function not implemented.');
            }}
          />
        </div>
        <div></div>
      </div> */}
        <div className="flex gap-x-[16px] items-center">
          <Font14G1W400 className="min-w-[100px]">{t('va.block.agent.co.pilot.label')}</Font14G1W400>
          <div className="w-[300px]">
            <DropdownAutoSearch<{
              id: string;
              name: string;
              source: string;
            }>
              {...coPilotHandler.copilotInfinitFn}
              size={EDropdownSize.MIDDLE}
              keyword={coPilotHandler.coPilotInfinit.searchKeyword}
              selected={coPilotHandlerProp.selected.name}
              isDisabled={false}
              options={coPilotHandler.newOptions}
              emptyWord={''}
              inputPlaceholder={t('va.block.select.placeholder')}
              renderCustomDropdownContent={(items) => {
                return items.map((item) => {
                  return (
                    <button
                      onClick={() => {
                        coPilotHandlerProp.onSelect(item.id, item.name);
                      }}
                      key={item.id}
                      style={{
                        backgroundColor: coPilotHandlerProp.selected.id === item.id ? '#f5f5f5' : 'transparent',
                      }}
                      className="px-[16px] py-[10px] flex flex-col cursor-pointer hover:bg-[#fafafa] w-full"
                    >
                      <Font14G1W400>{item.name}</Font14G1W400>
                      <Font12G5W400>{item.source}</Font12G5W400>
                    </button>
                  );
                });
              }}
              onClearSelected={() => {
                coPilotHandler.copilotInfinitFn.onClearSelected(() => {
                  coPilotHandlerProp.onSelect('', '');
                });
              }}
            />
          </div>
          <div className="flex gap-x-[8px] items-center">
            <div
              className="w-[8px] h-[8px] rounded-full"
              style={{
                backgroundColor: coPilotHandlerProp.selected.name ? '#29CB72' : '#F5F5F5',
              }}
            />
            <div>
              {coPilotHandlerProp.selected.name ? (
                <Font12G1W400>{t('va.block.va.selected')}</Font12G1W400>
              ) : (
                <Font12G1W400>{t('va.block.va.not.selected')}</Font12G1W400>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
