import { sendLineLoadingAnimation } from '@api/line';
import EChannelType from '@enums/ChannelType';
import { useSetting } from '@providers/SettingProvider';
import { IChannel } from '@types';
import _ from 'lodash';
import { useCallback, useRef } from 'react';
import { useMutation } from 'react-query';

type IUseLineLoadingAnimationParams = {
  channel?: IChannel;
  userId?: string;
};

type IUseLineLoadingAnimationPayload = {
  channelId: string;
  userId: string;
};

export const useLineLoadingAnimation = ({ channel, userId }: IUseLineLoadingAnimationParams) => {
  const { setting } = useSetting();
  const sendLineAnimationMute = useMutation({
    mutationKey: ['sendLineLoadingAnimation'],
    mutationFn: async (payload: IUseLineLoadingAnimationPayload) => {
      return sendLineLoadingAnimation(payload.channelId, payload.userId);
    },
  });

  const throttledRef = useRef<_.DebouncedFunc<() => void>>();

  const sendAnimationThrottle = useCallback(() => {
    if (!throttledRef.current) {
      throttledRef.current = _.throttle(
        () => {
          if (!channel?.id || !userId || !setting.loadingAnimation || !setting.loadingAnimation?.enabled) return;
          sendLineAnimationMute.mutate({ channelId: channel.id, userId: userId });
        },
        setting.loadingAnimation.loadingSeconds * 1000,
        { trailing: false },
      );
    }
    throttledRef.current();
  }, [
    channel?.id,
    userId,
    sendLineAnimationMute,
    setting.loadingAnimation?.loadingSeconds,
    setting.loadingAnimation?.enabled,
  ]);

  const resetThrottle = useCallback(() => {
    if (throttledRef.current) {
      throttledRef.current.cancel();
      throttledRef.current = undefined;
    }
  }, []);

  return {
    onSendAnimation: (text: string) => {
      if (channel?.type !== EChannelType.LINE || !channel.id || !userId || !setting.loadingAnimation?.enabled || !text)
        return;
      sendAnimationThrottle();
    },
    resetThrottle,
  };
};
