import React from 'react';
import { Props } from 'react-input-mask';
import { TimeInput as TimeInputComp } from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-regular-svg-icons';

export const TimeInput = (props: Props) => {
  return (
    <div className="relative">
      <FontAwesomeIcon className="absolute top-1/2 -translate-y-1/2 left-[12px]" icon={faClock} />
      <TimeInputComp {...props} />
    </div>
  );
};
