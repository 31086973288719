import Line from '@assets/images/line-icon.svg';
import Facebook from '@assets/images/fb-icon.svg';
import AmityLogo from '@assets/images/amity-only-logo.svg';
import CustomChannelLogo from '@assets/images/custom-ch-logo.svg';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import bytes from 'bytes';
import { ETicketFilterStatus } from '@enums/TicketFilter';
import { ESortBy, ETicketStatus } from '@enums/TicketStatus';
import { IWorkHourTimeSlot } from '@types';

export const GLOBAL_MESSAGE = {
  ERROR_TITLE: 'Oops, something went wrong!',
  ERROR_DESCRIPTION: 'Please try again later',
  ERROR_USER_NOT_LOGIN: 'User not logged in',
};

export const ERROR_FILE_COUNT_EXCEED = {
  TITLE: 'Upload files has been rejected!',
  DESCRIPTION: 'The uploaded file could not exceed 10 files/images',
  BUTTON: 'Try Again',
};

export const ERROR_FILE_EXCEED_SPEC_SIZE = {
  TITLE: 'Upload files has been rejected!',
  DESCRIPTION: "The uploaded file size is exceed the limitation or unsupport file's extension.",
  BUTTON: 'Try Again',
};

export const ERROR_FILE_TOTAL_SIZE_EXCEED = {
  TITLE: 'Upload files has been rejected!',
  DESCRIPTION: 'The total file size could not exceed 100 MB',
  BUTTON: 'Try Again',
};

export const EXCEPTIONS_MAP: { [key: string]: string } = {
  'Incorrect username or password.': 'Incorrect username or password.',
  'Password does not conform to policy: Password must have uppercase characters':
    'Password does not conform to policy: Password must have uppercase characters',
  'Invalid verification code provided, please try again.': 'Invalid verification code provided, please try again.',
  'Password reset required for the user': 'Password reset required for the user',
};

export const COOKIE_NAME = {
  MEGABOT_TOKEN: 'megabotToken',
};

//route
//desktop route -> ticket management
export const TICKET_MANAGEMENT_ROUTE = {
  DEFAULT: '/ticket-management',
  ALL_TICKETS: '/ticket-management/all-tickets',
  CHAT_HISTORY: '/ticket-management/all-tickets/:id',
  ALL_CHATS: '/ticket-management/all-chats',
};
//mobile route
export const MOBILE_ROUTE = {
  GET_START: '/m/getstart',
  RECENTS: '/m/recents',
  RECENTS_WITH_ID: '/m/recents/:id',
  USER: '/m/user/:id',
  LOGIN: '/login',
};

export const ALL = 'all';

// custom name
export const CUSTOM_NAME_MAX_LENGTH = 50;

//tag
export const DEBOUNCE_ADD_TAG = 450;
export const MAX_TAG = 20;
export const TAG_ALERT_MESSAGE = {
  ERROR_ADD_TAG: 'Failed to add tag',
  ERROR_REMOVE_TAG: 'Failed to remove tag',
};
//livechat
export const LINE_AND_WEBCHAT_INPUT_MAX_LENGTH = 5000;
export const FACEBOOK_INPUT_MAX_LENGTH = 2000;
export const MAX_FILE_UPLOAD = 10;
export const MAX_USER_PER_PAGE = 30;
export const DEBOUNCE_TIME = 450;
export const DEBOUNCE_MARK_READ_TIME = 5000;
export const DEBOUNCE_FETCH_TASK_TIME = 5000;
export const FILTER_ITEM_DATA = [
  {
    label: 'livechat.filter.assign.to.me',
    iconName: 'inbox-in' as IconName,
    value: ETicketFilterStatus.ASSIGNED_TO_ME,
    count: 0,
  },
  {
    label: 'livechat.filter.follow.up',
    iconName: 'star' as IconName,
    value: ETicketFilterStatus.FOLLOW_UP,
    count: 0,
  },
  {
    label: 'livechat.filter.team.follow.up',
    iconName: 'flag' as IconName,
    value: ETicketFilterStatus.TEAM_FOLLOW_UP,
    count: 0,
  },
  {
    label: 'livechat.filter.assign.to.team',
    iconName: 'users' as IconName,
    value: ETicketFilterStatus.ASSIGNED_TO_TEAM,
    count: 0,
  },
  {
    label: 'livechat.non.filter.unassigned',
    iconName: 'users-medical' as IconName,
    value: ETicketFilterStatus.UNASSIGNED,
    count: 0,
  },
];

export const ALL_CHANNEL = 'All channels';

//settings
export const TABS_DATA = [
  {
    tab: 'General setting',
    key: 'general',
  },
  {
    tab: 'Ticket tag',
    key: 'ticket-tag',
  },
  {
    tab: 'Template collection',
    key: 'template-collection',
  },
  {
    tab: 'Ticket Wrap up',
    key: 'ticket-wrap-up',
  },
  {
    tab: 'Customer satisfaction',
    key: 'customer-satisfaction',
  },
] as const;

export const CHANNEL_ITEMS = [
  {
    icon: Line,
    label: 'Line',
    value: 'line',
  },
  {
    icon: Facebook,
    label: 'Facebook',
    value: 'facebook',
  },
  {
    icon: AmityLogo,
    label: 'Amity Chat',
    value: 'amity-chat',
  },
  {
    icon: CustomChannelLogo,
    label: 'Custom Channel',
    value: 'custom',
  },
];

export const ADD_CHANNEL_GROUP = [
  {
    key: 'CHOOSE YOUR CHANNEL',
    items: CHANNEL_ITEMS,
  },
];

export const SETTINGS_CHANNEL_TABLE_ACTION = [
  {
    key: '',
    items: [
      {
        icon: {
          type: 'font',
          value: ['fas', 'edit'] as [IconPrefix, IconName],
        },
        label: 'Edit',
        value: 'edit',
      },
      {
        icon: {
          type: 'font',
          value: ['fas', 'trash'] as [IconPrefix, IconName],
        },
        label: 'Delete',
        value: 'delete',
      },
    ],
  },
];

export const SETTINGS_CHANNEL_FILTER = [
  {
    key: '',
    items: [
      {
        icon: '',
        label: 'All channels',
        value: 'all',
      },
      ...CHANNEL_ITEMS,
    ],
  },
];

export const CHANNEL_CRITERIA = 'line,facebook,amity-chat,custom';

export const TEAM_MANAGEMENT_ROLES = [
  {
    label: 'Admin',
    value: 'admin',
  },
  {
    label: 'Supervisor',
    value: 'supervisor',
  },
  {
    label: 'Agent',
    value: 'agent',
  },
  {
    label: 'Bot Assistant',
    value: 'bot',
  },
];

export const TICKET_STATUS_OPTION = [
  {
    label: 'All',
    value: ETicketStatus.ALL,
  },
  {
    label: 'Opened',
    value: ETicketStatus.OPEN,
  },
  {
    label: 'Assigned',
    value: ETicketStatus.ASSIGNED,
  },
  {
    label: 'Resolved',
    value: ETicketStatus.RESOLVED,
  },
];

export const TICKET_SORT_OPTION = [
  {
    label: 'Latest message',
    value: ESortBy.LATEST_MESSAGE,
  },
  {
    label: 'Latest opened ticket',
    value: ESortBy.LATEST_OPENED_TICKET,
  },
];

//team management
export const TEAM_MANAGEMENT_ROLES_FILTER = [
  {
    label: 'All',
    value: 'admin,supervisor,agent,bot',
  },
  ...TEAM_MANAGEMENT_ROLES,
];

export const TEAM_MANAGEMENT_TABS_DATA = [
  {
    key: 'team',
    name: 'Teams',
  },
  {
    key: 'virtual-agent',
    name: 'Virtual Agents',
  },
];

// export const VA_LIST_ACTION = [
//   {
//     key: '',
//     items: [
//       {
//         label: 'Edit member',
//         value: 'edit_member',
//       },
//       {
//         label: 'Delete member',
//         value: 'delete_member',
//       },
//       {
//         label: 'Reset password',
//         value: 'reset_password',
//       },
//     ],
//   },
// ];

export const TEAM_MANAGEMENT_MEMBER_LIST_ACTIONS = [
  {
    key: '',
    items: [
      {
        label: 'Edit member',
        value: 'edit_member',
      },
      {
        label: 'Delete member',
        value: 'delete_member',
      },
      {
        label: 'Reset password',
        value: 'reset_password',
      },
    ],
  },
];
export const MEMBER_LIST_PAGE_SIZE = 30;
export const MEMBER_LIST_DEBOUNCE_TIME = 300;
//team management -> team
export const TEAM_LIST_PAGE_SIZE = 30;
export const TEAM_MANAGEMENT_TEAM_LIST_ACTIONS = [
  {
    key: '',
    items: [
      {
        label: 'Edit team',
        value: 'edit_team',
      },
      {
        label: 'Delete team',
        value: 'delete_team',
      },
    ],
  },
];
/**
 * Sticker prefix to determine in text message
 */
export const STICKER_PREFIX = '__STICKER_';

export const MAX_TOTAL_FILES_SIZE = bytes.parse('100MB');

/**
 * Max image size in bytes of `line` channel
 */
export const LINE_MAX_IMAGE_BYTES = bytes.parse('10MB');
/**
 * Max video size in bytes of `line` channel
 */
export const LINE_MAX_VIDEO_BYTES = bytes.parse('300MB');
/**
 * Max file size in bytes of `line` channel
 */
export const LINE_MAX_FILE_BYTES = bytes.parse('100MB');
/**
 * Max image size in bytes of `facebook` channel
 */
export const FACEBOOK_MAX_IMAGE_BYTES = bytes.parse('8MB');
/**
 * Max video size in bytes of `facebook` channel
 */
export const FACEBOOK_MAX_VIDEO_BYTES = bytes.parse('25MB');
/**
 * Max file size in bytes of `facebook` channel
 */
export const FACEBOOK_MAX_FILE_BYTES = bytes.parse('25MB');
/**
 * Max image size in bytes of `amity-chat` channel
 */
export const WEBCHAT_MAX_IMAGE_BYTES = bytes.parse('10MB');
/**
 * Max video size in bytes of `amity-chat` channel
 */
export const WEBCHAT_MAX_VIDEO_BYTES = bytes.parse('300MB');
/**
 * Approximate max file size in bytes of `amity-chat` channel
 */
export const WEBCHAT_MAX_FILE_BYTES = bytes.parse('100MB');
/**
 * Max image size in bytes of `custom` channel
 */
export const CUSTOM_MAX_IMAGE_BYTES = bytes.parse('10MB');
/**
 * Max video size in bytes of `custom` channel
 */
export const CUSTOM_MAX_VIDEO_BYTES = bytes.parse('300MB');
/**
 * Approximate max file size in bytes of `custom` channel
 */
export const CUSTOM_MAX_FILE_BYTES = bytes.parse('100MB');

export const DEFAULT_FACEBOOK_APP_ID = '313336160552842';

// ticket management
export const TICKET_MANAGEMENT_ASSIGNEE_SIZE = 1000;
export const TICKET_MANAGEMENT_MAX_API_SIZE = 10;
export const MAX_FETCH_TEAM = 1000;

export const MESSAGE_BOX_BOTTOM_THRESHOLD = 1000;
export const TAG_API_MAX_TAKE = 10;

export const CAROUSEL_IMAGE_ASPECT_RATIO = {
  RECTANGLE: 'rectangle' as const,
  SQUARE: 'square' as const,
};

// Template Collection
export const TEMPLATE_COLLECTION_NAME_MIN_LENGTH = 3;
export const TEMPLATE_COLLECTION_NAME_MAX_LENGTH = 200;
export const TEMPLATE_COLLECTION_SELECTION_MAX_LENGTH = 200;

// Survey
// QuickReply Point
export const QUICK_REPLY_POINT = [
  {
    label: '1 Point',
    value: '1',
  },
  {
    label: '2 Point',
    value: '2',
  },
  {
    label: '3 Point',
    value: '3',
  },
  {
    label: '4 Point',
    value: '4',
  },
  {
    label: '5 Point',
    value: '5',
  },
  {
    label: '6 Point',
    value: '6',
  },
  {
    label: '7 Point',
    value: '7',
  },
  {
    label: '8 Point',
    value: '8',
  },
  {
    label: '9 Point',
    value: '9',
  },
  {
    label: '10 Point',
    value: '10',
  },
];

export const PERMISSION_DENIED_NAME_ERROR = 'PermissionDenied';
export const MAX_QUESTION_LENGTH = 2000;
export const MAX_OPTION_LENGTH = 10;
export const MAX_OPTION_LABEL_LENGTH = 20;
export const MAX_ADDITIONAL_QUESTION_LENGTH = 2000;
export const MAX_MESSAGE_RESPONSE_LENGTH = 2000;
export const MAX_ADDITIONAL_OPTION_LABEL_LENGTH = 20;
export const MAX_GUIDELINE_LENGTH = 2000;
export const PERMISSION_DENIED = 'Permission denied';
export const REFRESH_PAGE_BUTTON = 'Refresh page';
export const ERROR_REQUIRED_ASSIGNED = "A ticket is required to be assigned before updating the team's follow-up.";

// Assignee Modal
export const MAX_TEAM_FETCH = 100;
export const PUBLIC_HOLIDAY_KEYS = 'publicHolidays';
export const DEFAULT_WORKING_HOURS: IWorkHourTimeSlot[] = [
  {
    startHour: 8,
    startMinute: 30,
    endHour: 12,
    endMinute: 0,
  },
  {
    startHour: 13,
    startMinute: 0,
    endHour: 16,
    endMinute: 30,
  },
];

export const CALENDAR_DATE_FORMAT = 'DD MMM YYYY';
