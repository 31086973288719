import React, { useMemo } from 'react';
import { MessageComposerContainer, TicketHistoryMessageBoxContainer } from './style';
import TicketHistoryMessageBox from '../TicketHistoryMessageBox';
import { InfoToast, WarningToast } from '@components/Toast';
import { ResolveActionWrap } from '../style';
import { Button } from '@components/Button';
import MessageComposer from '@pages/components/MessageComposer';
import useTranslation from '@hooks/useTranslation';
import { useMember } from '@providers/MemberProvider';
import { isTicketOpen, isNotMyTicket, isTicketResolved } from '@utils/ticketStatus';
import { useTicketHistoryChatPane } from './useTicketHistoryChatPane';
import { ConfirmModal } from '@components/Modal';
import { Font14G1W400, Font20G1W600, Spacing } from '@components/UtilsComponent';
import { ETicketStatus } from '@enums/TicketStatus';
import { useSetting } from '@providers/SettingProvider';
import { TemplatePreviewOverlay } from '@components/TemplatePreviewOverlay';
import { useComposerManager } from '@providers/ComposerManagerProvider';
import { ModalSelectTeam } from '@components/ModalSelectTeam';
import { ERole } from '@enums/Role';
import { getInputMaxLength } from '@utils/getInputMaxLength';
import { useMessageBox } from '@providers/MessageBoxProvider';
import { ReplyMessageProvider } from '@providers/ReplyMessageProvider';
export const TicketHistoryChatPane = () => {
  const {
    assigningTicket,
    assignSelfModal,
    ticketContext,
    messageBoxHandler,
    fileHandler,
    isAskReopenTicket,
    isAskOpenNewTicket,
    isReopeningTicket,
    isOpeningNewTicket,
    onCancel,
    onConfirm,
    onConfirmAssignTicket,
    onCloseAssignModal,
    onReopenTicket,
    onOpenNewTicket,
    onOpenNewTicketWithTeam: onConfirmWithAssignToTeam,
  } = useTicketHistoryChatPane();
  const { member, isSuperAdmin, isAgent, isSupervisor, isAdmin } = useMember();
  const { permission } = useSetting();
  const { preview, composer } = useComposerManager();
  const { selectedTemplate, onSelectTemplate } = preview;
  const { onToggleComposer } = composer;
  const t = useTranslation();
  const { current } = useMessageBox();
  const { chatHistory } = current.context;
  const lastMessage = useMemo(() => {
    return chatHistory.messages[chatHistory.messages.length - 1];
  }, [chatHistory.messages]);
  const inputMaxLength = useMemo(() => {
    return getInputMaxLength(ticketContext?.currentTicket.channelType);
  }, [ticketContext?.currentTicket]);
  const enableVa = useMemo(() => {
    // agent, supvervisor only assigned to me
    // admin open all
    // if BE return error display error
    if ((isAgent() || isSupervisor()) && ticketContext.currentTicket.agentId === member._id) {
      return true;
    }

    if (isAdmin()) {
      return true;
    }
    return false;
  }, [ticketContext.currentTicket]);
  const _renderToast = () => {
    const { currentTicket } = ticketContext;

    if (isTicketOpen(currentTicket) && permission.canReply) {
      return null;
    }

    if (isTicketOpen(currentTicket)) {
      return (
        <MessageComposerContainer>
          <WarningToast align="flex-start">{t('livechat.ticket.management.ticket.console.warning.toast')}</WarningToast>{' '}
        </MessageComposerContainer>
      );
    }

    if (isNotMyTicket(currentTicket, member)) {
      return (
        <MessageComposerContainer>
          <InfoToast align="flex-start">{t('livechat.ticket.management.ticket.console.info.toast')}</InfoToast>{' '}
        </MessageComposerContainer>
      );
    }

    if (isTicketResolved(currentTicket)) {
      return (
        <>
          {!isSuperAdmin() && (
            <ResolveActionWrap>
              <Button size="large" onClick={onReopenTicket}>
                {t('livechat.ticket.management.ticket.console.reopen.button')}
              </Button>
              <Button size="large" onClick={onOpenNewTicket}>
                {t('livechat.ticket.management.ticket.console.open.new.button')}
              </Button>
            </ResolveActionWrap>
          )}
        </>
      );
    }

    return null;
  };
  const _renderComposer = () => {
    // current login is agent and is not owner of ticket
    if (!permission.canReply && (isAgent() || isSupervisor()) && isNotMyTicket(ticketContext.currentTicket, member)) {
      return null;
    }
    // ticket is not resolved
    if (!isTicketResolved(ticketContext.currentTicket)) {
      return (
        <div>
          {/* <Spacing height={10} /> */}
          <MessageComposer
            lastMessage={lastMessage}
            ticketId={ticketContext.currentTicket._id}
            platform={ticketContext.currentTicket.channelType.toLocaleLowerCase()}
            messageBoxHandler={{
              ...messageBoxHandler,
              handleSendTemplate: (template) => {
                messageBoxHandler.handleSendTemplate(template);
                // close composer
                onToggleComposer();
                // close preview
                onSelectTemplate(null);
              },
            }}
            maxLength={inputMaxLength}
            fileHandler={fileHandler}
            enableVa={enableVa}
          />
        </div>
      );
    }
    return null;
  };

  const _renderModalContent = () => {
    let title = '';
    let description = '';
    if (ticketContext.currentTicket.status === ETicketStatus.OPEN && assignSelfModal) {
      title = t('livechat.ticket.management.admin.assign.ticket.to.self.title');
      description = t('livechat.ticket.management.admin.assign.ticket.to.self.description');
    } else if (
      ticketContext.currentTicket.status === ETicketStatus.RESOLVED &&
      (isAskReopenTicket || isReopeningTicket)
    ) {
      title = t('livechat.ticket.management.reopen.ticket.modal.title');
      description = t('livechat.ticket.management.reopen.ticket.modal.description');
    } else if (
      ticketContext.currentTicket.status === ETicketStatus.RESOLVED &&
      (isAskOpenNewTicket || isOpeningNewTicket)
    ) {
      title = t('livechat.ticket.management.admin.open.new.ticket.title');
      description = t('livechat.ticket.management.admin.open.new.ticket.description');
    }

    return (
      <>
        <div>
          <Font20G1W600>{title}</Font20G1W600>
        </div>
        <div>
          <Font14G1W400>{description}</Font14G1W400>
        </div>
      </>
    );
  };
  const isSupervisorOrAgent = member.roles.includes(ERole.AGENT) || member.roles.includes(ERole.SUPERVISOR);
  const isOpenSelectTeamModal =
    (isOpeningNewTicket && isSupervisorOrAgent) || (isAskOpenNewTicket && isSupervisorOrAgent);
  return (
    <>
      <ReplyMessageProvider>
        <TicketHistoryMessageBoxContainer>
          <TemplatePreviewOverlay
            visible={!!selectedTemplate}
            template={selectedTemplate}
            onClose={() => onSelectTemplate(null)}
          />
          <TicketHistoryMessageBox />
        </TicketHistoryMessageBoxContainer>
        {_renderToast()}
        {_renderComposer()}
      </ReplyMessageProvider>
      <ConfirmModal
        visible={
          assignSelfModal ||
          isAskReopenTicket ||
          isOpeningNewTicket ||
          isReopeningTicket ||
          (isAskOpenNewTicket && member.roles.includes(ERole.ADMIN))
        }
        isLoading={assigningTicket || isReopeningTicket || isOpeningNewTicket}
        handleClose={() => {
          if (assignSelfModal) {
            onCloseAssignModal();
          } else {
            onCancel();
          }
        }}
        confirmBtnTxt={t('confirm.btn')}
        cancelBtnTxt={t('cancel.btn')}
        handleConfirm={() => {
          if (assignSelfModal) {
            // confirm assign to self admin
            onConfirmAssignTicket();
          } else {
            // confirm assign reopen ticket or open new ticket
            onConfirm();
          }
        }}
      >
        {_renderModalContent()}
      </ConfirmModal>
      <ModalSelectTeam
        visible={isOpenSelectTeamModal}
        onCloseModal={onCancel}
        isLoading={assigningTicket}
        onConfirm={(internalSelect) => {
          if (!internalSelect.id) return;
          onConfirmWithAssignToTeam(internalSelect.id);
        }}
      />
    </>
  );
};
