import React, { useEffect, useMemo, useState } from 'react';
import { Card } from '@components/Card';
import { faEye, faStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTranslation from '@hooks/useTranslation';
import { Font12G3W300, Font14G1W600 } from '@components/UtilsComponent';
import { ConfigProvider, Drawer } from 'antd';
import TableList from '@components/TableList';
import Table from '@components/Table';
import { TableWrap } from './style';
import { GhostButton } from '@components/Button';
import { RatingDrawerContent } from '../RatingDrawerContent';
import { IFilterState } from '../useDashboardFilter';
import { getRating } from '@api/livechat/Analytic';
import { CancelTokenSource } from 'axios';
import { FetchWrap } from '@components/FetchWrap';
import { IRatingTableResponse, IRatingTableResponseData } from '@types';
import { CardContent } from '@components/CardWrap';
import Select from '@components/Select';

type IRatingTableProps = {
  filter: IFilterState;
};

let spare: IRatingTableResponseData[] = [];
let nextPageArr: (string | undefined)[] = [undefined];
let jobId: string | undefined;
export const RatingTable = ({ filter }: IRatingTableProps) => {
  const t = useTranslation();
  const [selectedUser, setSelectedUser] = React.useState<null | { id: string }>(null);
  const [sort, setSort] = useState<'asc' | 'desc'>('desc');
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    setCurrentPage(1);
  }, [filter]);
  const columns = useMemo(() => {
    return [
      {
        title: 'AGENT NAME',
        dataIndex: 'name',
        key: 'name',
        width: 180,
        render: (_: any, record: IRatingTableResponseData) => {
          return <Font14G1W600>{record.agentDisplayName}</Font14G1W600>;
        },
      },
      {
        title: 'AVG PT',
        dataIndex: 'avgPt',
        key: 'avgPt',
        render: (_: any, record: IRatingTableResponseData) => {
          return <Font14G1W600>{record.summary.averageScorePoint?.toFixed(2) || '-'}</Font14G1W600>;
        },
      },
      {
        title: 'PT 10',
        dataIndex: '10',
        key: '10',
        render: (_: any, record: IRatingTableResponseData) => {
          return <Font14G1W600>{record.summary.scorePointDistribution?.[10] || '-'}</Font14G1W600>;
        },
      },
      {
        title: 'PT 9',
        dataIndex: '9',
        key: '9',
        render: (_: any, record: IRatingTableResponseData) => {
          return <Font14G1W600>{record.summary.scorePointDistribution?.[9] || '-'}</Font14G1W600>;
        },
      },
      {
        title: 'PT 8',
        dataIndex: '8',
        key: '8',
        render: (_: any, record: IRatingTableResponseData) => {
          return <Font14G1W600>{record.summary.scorePointDistribution?.[8] || '-'}</Font14G1W600>;
        },
      },
      {
        title: 'PT 7',
        dataIndex: '7',
        key: '7',
        render: (_: any, record: IRatingTableResponseData) => {
          return <Font14G1W600>{record.summary.scorePointDistribution?.[7] || '-'}</Font14G1W600>;
        },
      },
      {
        title: 'PT 6',
        dataIndex: '6',
        key: '6',
        render: (_: any, record: IRatingTableResponseData) => {
          return <Font14G1W600>{record.summary.scorePointDistribution?.[6] || '-'}</Font14G1W600>;
        },
      },
      {
        title: 'PT 5',
        dataIndex: '5',
        key: '5',
        render: (_: any, record: IRatingTableResponseData) => {
          return <Font14G1W600>{record.summary.scorePointDistribution?.[5] || '-'}</Font14G1W600>;
        },
      },
      {
        title: 'PT 4',
        dataIndex: '4',
        key: '4',
        render: (_: any, record: IRatingTableResponseData) => {
          return <Font14G1W600>{record.summary.scorePointDistribution?.[4] || '-'}</Font14G1W600>;
        },
      },
      {
        title: 'PT 3',
        dataIndex: '3',
        key: '3',
        render: (_: any, record: IRatingTableResponseData) => {
          return <Font14G1W600>{record.summary.scorePointDistribution?.[3] || '-'}</Font14G1W600>;
        },
      },
      {
        title: 'PT 2',
        dataIndex: '2',
        key: '2',
        render: (_: any, record: IRatingTableResponseData) => {
          return <Font14G1W600>{record.summary.scorePointDistribution?.[2] || '-'}</Font14G1W600>;
        },
      },
      {
        title: 'PT 1',
        dataIndex: '1',
        key: '1',
        render: (_: any, record: IRatingTableResponseData) => {
          return <Font14G1W600>{record.summary.scorePointDistribution?.[1] || '-'}</Font14G1W600>;
        },
      },
      {
        title: 'FEEDBACK/',
        dataIndex: 'feedback',
        key: 'feedback',
        width: 90,
        render: (_: any, record: IRatingTableResponseData) => {
          return (
            <div className="flex gap-x-[16px] items-center">
              <div className="px-[6px] py-[4px] rounded-[4px] bg-[#F5F5F5] flex justify-center items-center text-[12px]">
                📝
              </div>
              <Font14G1W600>{record.summary.feedbackMessageCount || '-'}</Font14G1W600>
            </div>
          );
        },
      },
      {
        title: 'NO RESPONSE',
        dataIndex: 'no_response',
        key: 'no_response',
        width: 130,
        render: (_: any, record: IRatingTableResponseData) => {
          return (
            <div className="flex gap-x-[16px] items-center">
              <div className="px-[6px] py-[4px] rounded-[4px] bg-[#FEEAEC] flex justify-center items-center text-[12px]">
                ⏩
              </div>
              <Font14G1W600>{record.summary.scorePointNotExistsCount || '-'}</Font14G1W600>
            </div>
          );
        },
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        width: 70,
        render: (_: any, record: IRatingTableResponseData) => {
          return (
            <GhostButton className="flex items-center" onClick={() => setSelectedUser({ id: record.agentId })}>
              <FontAwesomeIcon icon={faEye} className="text-[16px] text-[#BDBDBD]" />
            </GhostButton>
          );
        },
      },
    ];
  }, []);

  return (
    <>
      <Card
        minHeight="420px"
        divider
        title={
          <>
            <span>{t('operational.dashboard.agent.rating.title')}</span>
            <Font12G3W300 className="ml-1 italic">{t('operational.dashboard.agent.rating.description')}</Font12G3W300>
          </>
        }
        topLeftIcon={<FontAwesomeIcon icon={faStar} />}
        _renderCustomActionList={() => {
          return (
            <div className="w-[144px]">
              <Select
                value={sort}
                onChange={(value: 'asc' | 'desc') => {
                  jobId = undefined;
                  nextPageArr = [undefined];
                  setCurrentPage(1);
                  setSort(value);
                }}
                options={[
                  {
                    label: 'Highest first',
                    value: 'desc',
                  },
                  {
                    label: 'Lowest first',
                    value: 'asc',
                  },
                ]}
              />
            </div>
          );
        }}
      >
        <FetchWrap<IRatingTableResponse, IFilterState>
          filter={filter}
          cacheTime={0}
          queryKey={['rating-table', filter, sort, currentPage]}
          fetchFn={async (
            filter: IFilterState,
            cancelToken?: CancelTokenSource | undefined,
          ): Promise<IRatingTableResponse> => {
            if (currentPage === 1) {
              jobId = undefined;
              nextPageArr = [undefined];
            }
            const { data }: { data: IRatingTableResponse } = await getRating({
              agents: filter.assignees,
              channels: filter.channels,
              tags: filter.tags,
              teams: filter.teams,
              startIsoStringDate: filter.startIsoStringDate.toISOString(),
              endIsoStringDate: filter.endIsoStringDate.toISOString(),
              sort,
              nextToken: nextPageArr.length > 1 ? nextPageArr[currentPage - 1] : undefined,
              jobId,
              cancelToken,
            });
            if (data?.jobId) {
              jobId = data.jobId;
            }
            if (data?.nextToken) {
              nextPageArr.push(data.nextToken);
            }
            spare = data?.data;
            return data;
          }}
          renderFn={function ({
            isLoading,
            data: response,
            isError,
            refetch,
          }: {
            isLoading: boolean;
            data?: IRatingTableResponse;
            isError: boolean;
            refetch: () => void;
          }): JSX.Element {
            const data = response?.data || spare;
            const nextToken = response?.nextToken;
            return (
              <div className="pb-[20px]">
                <CardContent isLoading={isLoading} isError={isError} onRetry={refetch} preserveChildren>
                  <TableWrap className="p-[24px]">
                    <ConfigProvider renderEmpty={() => <div>{t('template.collection.list.no.data')}</div>}>
                      <TableList
                        next={{
                          onClick: () => {
                            setCurrentPage((prev) => prev + 1);
                          },
                          disabled: !nextToken,
                        }}
                        prev={{
                          onClick: () => {
                            if (currentPage === 1) return;
                            setCurrentPage((prev) => {
                              if (prev === 2) {
                                nextPageArr = [undefined];
                              }
                              return prev - 1;
                            });
                          },
                          disabled: currentPage === 1,
                        }}
                      >
                        <Table
                          id="rating-table"
                          header={<></>}
                          data={data}
                          columns={columns}
                          pagination={false}
                          scroll={{ y: 'calc(100vh - 320px)' }}
                        />
                      </TableList>
                    </ConfigProvider>
                  </TableWrap>
                </CardContent>
              </div>
            );
          }}
        />
      </Card>
      <Drawer
        width={960}
        placement="right"
        closable={false}
        onClose={() => setSelectedUser(null)}
        visible={!!selectedUser}
      >
        {selectedUser ? (
          <RatingDrawerContent
            filter={filter}
            sort={sort}
            agentId={selectedUser.id}
            onClose={() => setSelectedUser(null)}
          />
        ) : null}
      </Drawer>
    </>
  );
};
