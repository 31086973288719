import React from 'react';
import useTranslation from '@hooks/useTranslation';
import EngagementMessage from '../EngagementMessage';
import EngagementUserProfile from '../EngagementUserProfile';
import useChatWrap from './useChatWrap';
import error from '@assets/images/error.svg';
import check_circle from '@assets/images/checi_circle.svg';
import LoadingCircle from '@components/Loading';
import StateMessage from '@components/StateMessage';
import { Container, EngagementMessageContainer, LoadingContainer, UserProfileContainer } from './style';
import { Link, useHistory, useParams } from 'react-router-dom';
import MessageBoxProvider from '@providers/MessageBoxProvider';
import { useMember } from '@providers/MemberProvider';
import { getQueryString } from '@utils/getQueryString';
import { ComposerManagerProvider } from '@providers/ComposerManagerProvider';
import { ReplyMessageProvider } from '@providers/ReplyMessageProvider';

const ChatWrap: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const t = useTranslation();
  const { member } = useMember();
  const history = useHistory();
  const { selectedUser, selectedTicket, currentUserData, ticketData } = useChatWrap();
  const messageTime = getQueryString(history.location.search, 'messageTime');
  const getTimeNumber = messageTime ? parseInt(messageTime) : null;
  if (ticketData.isFetching) {
    return (
      <LoadingContainer>
        <LoadingCircle width={100} height={100} />
      </LoadingContainer>
    );
  }

  if (currentUserData.isError || ticketData.isError) {
    return (
      <StateMessage
        size="large"
        type="error"
        img={error}
        label={t('livechat.error.title')}
        description={t('livechat.error.description')}
      />
    );
  }

  if (selectedTicket?.status === 'resolved') {
    return (
      <StateMessage
        size="large"
        type="success"
        img={check_circle}
        label={t('livechat.ticket.resolved.title', { ticketNumber: selectedTicket.ticketNumber })}
        description={
          <div>
            {t('livechat.ticket.resolved.description')}
            <Link to={`/ticket-management/all-tickets/${selectedTicket._id}`}> here.</Link>
          </div>
        }
      />
    );
  }

  if (selectedTicket && selectedUser) {
    return (
      <ComposerManagerProvider>
        <Container wrap={false}>
          <EngagementMessageContainer flex={1}>
            <MessageBoxProvider
              ticket={selectedTicket}
              member={member}
              user={selectedUser}
              messageTime={getTimeNumber}
              options={{ isSocket: true, asReader: true, isTicketManagementMode: false }}
            >
              <ReplyMessageProvider>
                <EngagementMessage />
              </ReplyMessageProvider>
            </MessageBoxProvider>
          </EngagementMessageContainer>
          <UserProfileContainer>
            <EngagementUserProfile
              userProfile={currentUserData.data?.data}
              ticket={selectedTicket}
              userId={id}
              onRefetch={() => {
                currentUserData.refetch();
              }}
            />
          </UserProfileContainer>
        </Container>
      </ComposerManagerProvider>
    );
  }
  return null;
};

export default ChatWrap;
