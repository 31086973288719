import React, { useCallback } from 'react';
import { useMessageBox } from '@providers/MessageBoxProvider';
import { Container, ListContainer, LoadingWrap, MessageBoxContainer } from './style';
import LoadingCircle from '@components/Loading';
import { AutoSizer } from 'react-virtualized';
import ChatMessage from '@pages/components/ChatMessage';
import { useComposerManager } from '@providers/ComposerManagerProvider';
import { TemplatePreviewOverlay } from '@components/TemplatePreviewOverlay';

const MessageBox: React.FC = () => {
  const { current, listRef, handleScroll, handleResend, _renderDownButton, onJumpToMessage } = useMessageBox();
  const { chatHistory } = current.context;
  const { preview, composer } = useComposerManager();
  const { selectedTemplate, onSelectTemplate } = preview;
  const { onToggleComposer } = composer;

  const _renderListContainer = useCallback(
    ({ height, width }) => {
      return (
        <ListContainer style={{ height, width }}>
          {chatHistory.messages?.map((message, index) => {
            return (
              <ChatMessage
                key={message.getId + index}
                message={message}
                onResend={handleResend}
                onJumpToMessage={onJumpToMessage}
              />
            );
          })}
        </ListContainer>
      );
    },
    [
      chatHistory.messages,
      current.matches({ chatHistoryState: 'sendMessageFailed' }),
      current.matches({ chatHistoryState: 'sendFilesMessageFailed' }),
    ],
  );

  return (
    <Container>
      <TemplatePreviewOverlay
        visible={!!selectedTemplate}
        template={selectedTemplate}
        onClose={() => {
          onSelectTemplate(null);
          onToggleComposer();
        }}
      />
      <MessageBoxContainer ref={listRef} onScroll={handleScroll}>
        {current.hasTag('loading-previous') && (
          <LoadingWrap>
            <LoadingCircle />
          </LoadingWrap>
        )}
        <AutoSizer>{_renderListContainer}</AutoSizer>
        {current.hasTag('loading-next') && (
          <LoadingWrap>
            <LoadingCircle />
          </LoadingWrap>
        )}
      </MessageBoxContainer>
      {_renderDownButton()}
    </Container>
  );
};

export default MessageBox;
