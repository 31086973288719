import { useEffect, Key, useState } from 'react';
import { useMachine } from '@xstate/react';
import { Form } from 'antd';
import editTeamEditorMachine from './editTeamEditorMachine';
import { useHistory, useParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import useTranslation from '@hooks/useTranslation';
import { IMember } from '@types';
import useForceUpdate from '@hooks/useForceUpdate';
import { ERole } from '@enums/Role';
import { IVaHandler } from '../VaBlock';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

type ISubmitParams = {
  name: string;
  department: string;
  default?: boolean;
  workingHourEnabled: boolean;
  enablePublicHolidays: boolean;
  enableOffHourAssignment: boolean;
  enableSLAPause: boolean;
  enableOffHourAutoReply: boolean;
};

const useEditTeamEditor = () => {
  const history = useHistory();
  const t = useTranslation();
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const alert = useAlert();
  const forceUpdate = useForceUpdate();
  const [selectedVas, setSelectedVas] = useState<{
    firstResponse: IVaHandler['selected'];
    copilot: IVaHandler['selected'];
  }>({
    firstResponse: {
      id: '',
      name: '',
    },
    copilot: {
      id: '',
      name: '',
    },
  });
  const [current, send] = useMachine(editTeamEditorMachine(id), {
    devTools: import.meta.env.DEV,
  });

  useEffect(() => {
    if (current.matches('updateTeamSuccess')) {
      alert.success(t('livechat.team.management.team.editor.edit.alert.update.team.success'), {
        timeout: 2000,
        onOpen: () => {
          history.push('/team-management');
        },
      });
    }
  }, [current]);

  useEffect(() => {
    const { teamData } = current.context;

    if (current.matches('fetchTeamDataSuccess')) {
      // set virtual agent
      setSelectedVas({
        firstResponse: {
          id: teamData.virtualAgentConfig?.active?._id || '',
          name: teamData.virtualAgentConfig?.active?.displayName || '',
        },
        copilot: {
          id: teamData.virtualAgentConfig?.passive?._id || '',
          name: teamData.virtualAgentConfig?.passive?.displayName || '',
        },
      });
      form.setFieldsValue({
        name: teamData.name,
        department: teamData.department,
        default: teamData.default,
        workingHourEnabled: teamData.workHours.enabled,
        enablePublicHolidays: teamData.workHours.enablePublicHolidays,
        enableOffHourAssignment: teamData.workHours.enableOffHourAssignment,
        enableSLAPause: teamData.workHours.enableSLAPause,
        enableOffHourAutoReply: teamData.workHours.enableOffHourAutoReply,
      });
    }
  }, [current]);

  const isCheckBoxDisabled = (record: IMember) => {
    const isDefaultTeam = form.getFieldValue('default');
    const isBotRole = record.roles.includes(ERole.BOT);

    return !isDefaultTeam && isBotRole;
  };

  const onSubmit = (values: ISubmitParams) => {
    const { selectedMembers, teamData } = current.context;
    const payload = {
      default: values.default ?? false,
      agents: selectedMembers,
      name: values.name,
      department: values.department,
      workHours: {
        enabled: values.workingHourEnabled || false,
        enablePublicHolidays: values.enablePublicHolidays || false,
        enableOffHourAssignment: values.enableOffHourAssignment || false,
        enableSLAPause: values.enableSLAPause || false,
        enableOffHourAutoReply: values.enableOffHourAutoReply || false,
        offHourAutoReplyTemplateId: '',
        days: {
          ...current.context.teamData.workHours.days,
          publicHolidays: teamData.workHours.publicHolidays.timeslots,
        },
      },
    };
    send('SUBMIT_FORM', {
      payload: {
        ...payload,
        virtualAgentConfig: {
          active: selectedVas.firstResponse.id || undefined,
          passive: selectedVas.copilot.id || undefined,
        },
      },
    });
  };

  const handleSelectMembers = (selectedRowKeys: Key[]) => {
    send('SET_SELECTED_MEMBERS', { data: selectedRowKeys });
  };

  const handlePageChange = (page: number) => {
    send('FETCH_MEMBER', { page });
  };

  const filterOutSelectedBotMembers = () => {
    if (!form.getFieldValue('default')) {
      send('FILTER_OUT_BOT_MEMBER');
    }
    forceUpdate();
  };
  return {
    current,
    form,
    vaHandler: {
      selectedVas,
      onSelectActiveVa: (id: string, name: string) => {
        setSelectedVas((prev) => ({
          ...prev,
          firstResponse: {
            id,
            name,
          },
        }));
      },
      onSelectPassiveVa: (id: string, name: string) => {
        setSelectedVas((prev) => ({
          ...prev,
          copilot: {
            id,
            name,
          },
        }));
      },
    },
    onCheckPublicHoliday: (e: CheckboxChangeEvent) => {
      send('TOGGLE_PUBLIC_HOLIDAYS', { checked: e.target.checked });
    },
    send,
    onSubmit,
    forceUpdate,
    filterOutSelectedBotMembers,
    handleSelectMembers,
    handlePageChange,
    isCheckBoxDisabled,
  };
};

export default useEditTeamEditor;
