import React, { useMemo, useRef, useState } from 'react';
import { ConfirmModal } from '@components/Modal';
import useTranslation from '@hooks/useTranslation';
import {
  Font14G1W400,
  Font14G1W600,
  Font14G3W400,
  Font16G1W600,
  Font20G1W600,
  Font20PrimaryW600,
  ValidateContainer,
  ValidateMessage,
} from '@components/UtilsComponent';
import { IPublicHolidayRequestParams } from '@types';
import { Button, GhostButton, PrimaryButton } from '@components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faPlus, faSyncAlt, faTimes } from '@fortawesome/pro-solid-svg-icons';
import Holidays from 'date-holidays';
import dayjs from 'dayjs';
import { usePopOverCustomPos } from '@components/PopoverCustomPos/usePopOverCustomPos';
import { PopoverCustomPos } from '@components/PopoverCustomPos';
import { useClickOutside } from '@hooks/useClickOutside';
import { OneYearDatePicker } from '@components/OneYearDatePicker';
import { useSetting } from '@providers/SettingProvider';
import { useMutation } from 'react-query';
import { savePublicHoliday } from '@api/livechat/Settings';
import LoadingIcon from '@components/LoadingIcon';
import { IPublicHoliday } from '@pages/desktop/settings/GeneralManagement/generalSettingMachine';
import { Fade } from '@components/Fade';
import { cloneDeep, set } from 'lodash';

type IPublicHolidayModalProps = {
  visible: boolean;
  onToggle: () => void;
};

const hd = new Holidays('TH');
hd.setLanguages('th');
const DATE_FORMAT = 'YYYY-MM-DD';
const PICKER_HEIGHT = 500;

export const PublicHolidayModal = ({ visible, onToggle }: IPublicHolidayModalProps) => {
  const t = useTranslation();
  const { setting: originSetting, onUpdateConfig } = useSetting();
  const tempSettingRef = useRef(cloneDeep(originSetting));
  const [setting, setSetting] = useState(cloneDeep(originSetting));
  const savePublicHolidayMute = useMutation({
    mutationFn: (params: IPublicHolidayRequestParams) => {
      const newPublicHolidays = {
        publicHolidays: params.publicHolidays.map((holiday) => {
          return {
            year: holiday.year,
            dates: holiday.dates.map((date) => {
              return {
                date: dayjs(date.date).format(DATE_FORMAT),
                name: date.name,
              };
            }),
          };
        }),
      };
      return savePublicHoliday(newPublicHolidays);
    },
  });
  const currentYear = new Date().getFullYear();
  const [currentElement, setCurrentElement] = useState<HTMLButtonElement | null>(null);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const { position } = usePopOverCustomPos(currentElement);
  const selectedDateIndex = useRef<number | null>(null);
  const realPosition = useMemo(() => {
    // if position is go out of screen, we need to adjust it
    if (position.top + PICKER_HEIGHT > window.innerHeight) {
      position.top = window.innerHeight - PICKER_HEIGHT;
    }
    return {
      top: position.top,
      left: position.left,
      width: position.width,
      height: position.height,
    };
  }, [position, setting.publicHolidays]);

  useClickOutside([document.getElementById('popover-custom-pos')], () => {
    setCurrentElement(null);
  });

  const onRemove = (index: number) => {
    if (setting.publicHolidays) {
      const newPublicHolidays = setting.publicHolidays.map((holiday) => {
        return {
          year: holiday.year,
          dates: holiday.dates,
        };
      });
      newPublicHolidays[0].dates.splice(index, 1);
      setSetting({
        ...setting,
        publicHolidays: newPublicHolidays,
      });
    }
  };

  const onUpdatePublicHolidayName = (index: number, name: string) => {
    if (setting.publicHolidays) {
      const newPublicHolidays = setting.publicHolidays.map((holiday) => {
        return {
          year: holiday.year,
          dates: holiday.dates,
        };
      });
      newPublicHolidays[0].dates[index].name = name;
      setSetting({
        ...setting,
        publicHolidays: newPublicHolidays,
      });
    }
  };

  const onAddNewHoliday = () => {
    if (setting.publicHolidays) {
      const newPublicHolidays = setting.publicHolidays.map((holiday) => {
        return {
          year: holiday.year,
          dates: holiday.dates,
        };
      });
      newPublicHolidays[0].dates.push({
        date: dayjs().format(DATE_FORMAT),
        name: '',
      });
      setSetting({
        ...setting,
        publicHolidays: newPublicHolidays,
      });
    }
  };

  const onResetToDefault = () => {
    const currentYear = new Date().getFullYear();
    const thailandHolidays = hd.getHolidays(currentYear);
    const publicHolidays = setting.publicHolidays?.map((holiday) => {
      return {
        year: holiday.year,
        dates: thailandHolidays.map((thaiHoliday) => {
          return {
            date: thaiHoliday.date,
            name: thaiHoliday.name,
          };
        }),
      };
    });
    onUpdateConfig({
      ...setting,
      publicHolidays,
    });
  };

  const validatePublicHolidays = (publicHolidays: IPublicHoliday) => {
    // validate if name is empty
    const newPublicHolidays = publicHolidays.dates.map((holiday, index) => {
      // find duplicate date execept itself
      const isDuplicate = publicHolidays.dates.some((otherHoliday, otherIndex) => {
        return otherIndex !== index && otherHoliday.date === holiday.date;
      });
      if (isDuplicate) {
        holiday.dateErrorMessage = t('public.holiday.modal.date.duplicate');
      } else {
        holiday.dateErrorMessage = '';
      }
      if (!holiday.name || !holiday.name.trim()) {
        holiday.nameErrorMessage = t('public.holiday.modal.name.required');
      } else {
        holiday.nameErrorMessage = '';
      }
      return holiday;
    });
    setSetting({
      ...setting,
      publicHolidays: [
        {
          year: publicHolidays.year,
          dates: newPublicHolidays,
        },
      ],
    });

    // check if there is any error
    const hasError = newPublicHolidays.some((holiday) => {
      return holiday.dateErrorMessage || holiday.nameErrorMessage;
    });
    return hasError;
  };

  return (
    <ConfirmModal
      bodyStyle={{ padding: 0 }}
      visible={visible}
      maxWidth={552}
      bodyWidth={'552px'}
      confirmBtnTxt={t('save')}
      cancelBtnTxt={t('cancel.btn')}
      handleClose={() => {
        setSetting(() => cloneDeep(tempSettingRef.current));
        onToggle();
      }}
      isDisabledConfirmBtn={savePublicHolidayMute.isLoading}
      isLoading={savePublicHolidayMute.isLoading}
      handleConfirm={() => false}
      customFooter={
        <div className="flex justify-between items-center gap-x-[16px]">
          <div>
            <GhostButton size="large" onClick={onResetToDefault}>
              {t('public.holiday.modal.reset')}
            </GhostButton>
          </div>
          <div className="flex items-center">
            <Button
              size="large"
              onClick={() => {
                setSetting(() => cloneDeep(tempSettingRef.current));
                onToggle();
              }}
            >
              {t('close')}
            </Button>
            <PrimaryButton
              size="large"
              disabled={savePublicHolidayMute.isLoading}
              onClick={async () => {
                if (setting.publicHolidays) {
                  const isInvalid = validatePublicHolidays(setting.publicHolidays[0]);
                  if (isInvalid) {
                    return;
                  }
                  const params: IPublicHolidayRequestParams = {
                    publicHolidays: setting.publicHolidays,
                  };
                  await savePublicHolidayMute.mutateAsync(params);
                  tempSettingRef.current = cloneDeep(setting);
                  onUpdateConfig(cloneDeep(setting));
                  onToggle();
                }
              }}
            >
              {savePublicHolidayMute.isLoading && <LoadingIcon icon={faSyncAlt} />}
              {t('save')}
            </PrimaryButton>
          </div>
        </div>
      }
    >
      <div className="header p-[24px] border-b border-solid border-[#E5E5E5] !w-full">
        <Font20G1W600 className="capitalize">
          {t('public.holiday.modal.title', {
            year: <Font20PrimaryW600>{currentYear}</Font20PrimaryW600>,
          })}
        </Font20G1W600>
      </div>
      <div className="body p-[24px] border-b border-solid border-[#E5E5E5] !w-full mb-[24px]">
        <div className="mb-[16px]">
          {t('public.holiday.modal.note', {
            note: <Font14G1W600>{t('public.holiday.modal.note.strong')}</Font14G1W600>,
          })}
        </div>
        <div className="flex flex-col gap-y-[16px]">
          <div className="flex justify-between items-center">
            <div className="flex gap-x-[24px]">
              <div className="text-left max-w-[120px] min-w-[120px]">
                <Font14G3W400>{t('public.holiday.modal.date.label')}</Font14G3W400>
              </div>
              <div className="text-left max-w-[120px] min-w-[120px]">
                <Font14G3W400>{t('public.holiday.modal.name.label')}</Font14G3W400>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-y-[16px] max-h-[352px] overflow-y-auto">
            {setting?.publicHolidays?.[0].dates.map((holiday, index) => {
              return (
                <div key={index}>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-x-[24px]">
                      <div className="w-[120px]">
                        <ValidateContainer isInvalidate={!!holiday.dateErrorMessage} id={`holiday-date-${index}`}>
                          <button
                            id={`holiday-date-${index}`}
                            className="px-[12px] focus:border-[#0f5cb2] focus:border-2 w-full py-[8px] rounded-[8px] border border-solid border-[#E5E5E5] flex gap-x-[8px] items-center"
                            onClick={(e) => {
                              const date = dayjs(holiday.date).format();
                              selectedDateIndex.current = index;
                              setSelectedDate(date);
                              setCurrentElement(e.currentTarget as HTMLButtonElement);
                            }}
                          >
                            <FontAwesomeIcon icon={faCalendarAlt} className="text-[#9E9E9E]" />
                            <Font14G1W400>{dayjs(holiday.date).format('MMM DD')}</Font14G1W400>
                          </button>
                        </ValidateContainer>
                      </div>
                      <div title={holiday.name}>
                        <ValidateContainer isInvalidate={!!holiday.nameErrorMessage} id={`holiday-name-${index}`}>
                          <input
                            id={`holiday-name-${index}`}
                            type="text"
                            value={holiday.name}
                            onChange={(e) => {
                              onUpdatePublicHolidayName(index, e.target.value);
                            }}
                            className="w-[270px] h-[40px] border border-solid border-[#E5E5E5] rounded-[8px] px-[12px]"
                          />
                        </ValidateContainer>
                      </div>
                    </div>
                    <div className="mr-[16px]">
                      <button onClick={() => onRemove(index)}>
                        <FontAwesomeIcon icon={faTimes} className="text-[#757575]" />
                      </button>
                    </div>
                  </div>
                  <div className="flex gap-x-[24px]">
                    <div className="w-[120px]">
                      {holiday.dateErrorMessage && <ValidateMessage>{holiday.dateErrorMessage}</ValidateMessage>}
                    </div>
                    <div>
                      {holiday.nameErrorMessage && <ValidateMessage>{holiday.nameErrorMessage}</ValidateMessage>}
                    </div>
                    <div></div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <PopoverCustomPos
          dimensions={{
            top: realPosition.top,
            left: realPosition.left,
            width: realPosition.width,
            height: realPosition.height,
          }}
        >
          <Fade isShow={!!currentElement} duration={100}>
            <div
              className="bg-white w-[300px] rounded-lg"
              style={{
                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 6px 16px',
                display: !!currentElement ? 'block' : 'none',
              }}
            >
              <OneYearDatePicker
                clearBtnTxt={t('close')}
                dateSelected={selectedDate || ''}
                onClose={() => {
                  setCurrentElement(null);
                }}
                onUpdateTriggerDate={(date?: string) => {
                  if (!date) {
                    setCurrentElement(null);
                    return;
                  }
                  if (setting.publicHolidays && selectedDateIndex.current !== null) {
                    const newPublicHolidays = setting.publicHolidays.map((holiday) => {
                      return {
                        year: holiday.year,
                        dates: holiday.dates,
                      };
                    });
                    newPublicHolidays[0].dates[selectedDateIndex.current].date = dayjs(date).format(DATE_FORMAT);
                    onUpdateConfig({
                      ...setting,
                      publicHolidays: newPublicHolidays,
                    });
                  }
                  setCurrentElement(null);
                }}
              />
            </div>
          </Fade>
        </PopoverCustomPos>
        <Button
          className="w-full text-[16px] mt-[16px] !h-[40px]"
          onClick={onAddNewHoliday}
          icon={<FontAwesomeIcon className="mr-[8px]" icon={faPlus} />}
        >
          <Font16G1W600>{t('add.new')}</Font16G1W600>
        </Button>
      </div>
    </ConfirmModal>
  );
};
