import { useEffect, useState, useRef } from 'react';
import { getCurrentDate, getDate, getStartOfMonth } from '@utils/day';
import { CALENDAR_DATE_FORMAT } from '@configs/constants';

export const useDatePicker = (onUpdateTriggerDate: (date: string) => void, dateSelected?: string) => {
  const [currentShow, setCurrentShow] = useState(
    dateSelected ? getStartOfMonth(getDate(dateSelected)) : getStartOfMonth(getCurrentDate()),
  );
  const [value, setValue] = useState(dateSelected || '');
  const [selectedDate, setSelectedDate] = useState(dateSelected ? getDate(dateSelected) : undefined);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showYearOptions, setShowYearOptions] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // if open datepicker is open and it go out off screen then put it back
    if (showDatePicker && dropdownRef.current) {
      const { top } = dropdownRef.current.getBoundingClientRect();
      if (top < 0) {
        dropdownRef.current.style.bottom = top + 'px';
      }
    }
  }, [dropdownRef, showDatePicker]);

  useEffect(() => {
    if (showDatePicker && value) {
      setSelectedDate(getDate(value, CALENDAR_DATE_FORMAT));
      setCurrentShow(getStartOfMonth(getDate(value, CALENDAR_DATE_FORMAT)));
    }
  }, [showDatePicker]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as HTMLElement)) {
        setShowDatePicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  useEffect(() => {
    setCurrentShow(dateSelected ? getStartOfMonth(getDate(dateSelected)) : getStartOfMonth(getCurrentDate()));
    setSelectedDate(dateSelected ? getDate(dateSelected) : undefined);
    setValue(dateSelected || '');
  }, [dateSelected]);

  const onSelect = (day: number) => {
    setSelectedDate(() => {
      return currentShow.date(day);
    });
  };

  const onChangeMonth = (behavior: 'prev' | 'next') => {
    if (behavior === 'next') {
      setCurrentShow((prev) => {
        return prev.add(1, 'month');
      });
    }

    if (behavior === 'prev') {
      setCurrentShow((prev) => {
        return prev.subtract(1, 'month');
      });
    }
  };

  const onChangeYear = (year: number) => {
    setCurrentShow((prev) => {
      if (getCurrentDate().isAfter(prev.year(year), 'month')) return getStartOfMonth(getCurrentDate());
      return prev.year(year);
    });
  };

  const generateYears = () => {
    return Array.from({ length: 5 }, (_, index) => {
      if (currentShow.year() > getCurrentDate().year() + 1) {
        const SHIFT = -2;
        return currentShow.year() + SHIFT + index;
      }

      return getCurrentDate().year() + index;
    });
  };

  const isDateValid = (date: string) => {
    const format = CALENDAR_DATE_FORMAT;
    const newDate = getDate(date, format);
    return (
      newDate.isValid() &&
      newDate.format(format) === date &&
      (newDate.isSame(getCurrentDate(), 'date') || newDate.isAfter(getCurrentDate(), 'date'))
    );
  };

  const onToggleDatePicker = () => setShowDatePicker(!showDatePicker);

  useEffect(() => {
    setSelectedDate(dateSelected ? getDate(dateSelected) : undefined);
  }, [dateSelected]);

  return {
    value,
    ref,
    dropdownRef,
    currentShow,
    selectedDate,
    showDatePicker,
    showYearOptions,
    setShowDatePicker,
    setCurrentShow,
    onToggleShowYearOptions: () => setShowYearOptions(!showYearOptions),
    setSelectedDate,
    onSelect,
    onChangeMonth,
    onChangeYear,
    generateYears,
    onChangeValue: (value: string) => setValue(value),
    onClearValue: () => {
      onUpdateTriggerDate('');
      setSelectedDate(undefined);
      setCurrentShow(getStartOfMonth(getCurrentDate()));
      setValue('');
    },
    onConfirm: () => {
      onUpdateTriggerDate(selectedDate?.format(CALENDAR_DATE_FORMAT) || '');
      setValue(selectedDate?.format(CALENDAR_DATE_FORMAT) || '');
      onToggleDatePicker();
    },
    onToggleDatePicker,
    isDateValid,
    isToday: (day: number) => day === getCurrentDate().date() && getCurrentDate().isSame(currentShow, 'month'),
    isSelectedDate: (day: number) => day === selectedDate?.date() && selectedDate.isSame(currentShow, 'month'),
  };
};
