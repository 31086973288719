import { CombineAllMessage } from '@model/MessageItem';
import React, { createContext } from 'react';

type IReplyMessageProviderProps = {
  children: React.ReactNode;
};

type IReplyMessageContext = {
  selectedReplyMessage: CombineAllMessage | null;
  setSelectedReplyMessage: React.Dispatch<React.SetStateAction<CombineAllMessage | null>>;
};
export const ReplyMessageContext = createContext<IReplyMessageContext | undefined>(undefined);

export const useReplyMessage = () => {
  const ctx = React.useContext(ReplyMessageContext);

  if (!ctx) {
    throw new Error('No ReplyMessageProvider use ReplyMessageProvider to set one');
  }

  return ctx;
};

export const ReplyMessageProvider = ({ children }: IReplyMessageProviderProps) => {
  const [selectedReplyMessage, setSelectedReplyMessage] = React.useState<CombineAllMessage | null>(null);
  return (
    <ReplyMessageContext.Provider
      value={{
        selectedReplyMessage,
        setSelectedReplyMessage,
      }}
    >
      {children}
    </ReplyMessageContext.Provider>
  );
};
