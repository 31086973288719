import { useState, useEffect } from 'react';

export const usePopOverCustomPos = (targetEl?: Element | null) => {
  const [position, setPosition] = useState({ top: 0, left: 0, width: 0, height: 0 });
  useEffect(() => {
    if (targetEl) {
      const rect = targetEl.getBoundingClientRect();
      setPosition({ top: rect.top, left: rect.left, width: rect.width, height: rect.height });
    }
  }, [targetEl]);
  return { position };
};
