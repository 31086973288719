import React from 'react';
import { Checkbox } from '@components/Checkbox';
import FormItem from '@components/FormItem';
import { LabelTitle } from '@components/FormItem/style';
import SearchInput from '@components/SearchInput';
import Table from '@components/Table';
import TextInput from '@components/TextInput';
import useTranslation from '@hooks/useTranslation';
import { Breadcrumb, Col, Form, Row, Typography } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import {
  Container,
  FormHeader,
  Description,
  CheckBoxWrap,
  Item,
  TableWrap,
  SearchWrap,
  ActionWrap,
  UsernameWrap,
  AvatarWrap,
  DetailWrap,
} from './style';
import useNewTeamEditor from './useNewTeamEditor';
import { Button, PrimaryButton } from '@components/Button';
import { DangerToast } from '@components/Toast';
import LoadingIcon from '@components/LoadingIcon';
import { MEMBER_LIST_PAGE_SIZE } from '@configs/constants';
import dayjs from 'dayjs';
import { LoadingWrap } from '@components/Loading/style';
import LoadingCircle from '@components/Loading';
import Avatar from '@components/Avatar';
import { ToastWrap } from '@components/Toast/styles';
import { IMember } from '@types';
import { VaBlock } from '../VaBlock';
import { WorkingHourBlock } from '../WorkingHourBlock';
import { Divider } from '@components/UtilsComponent';
import Switch from '@components/Switch';
import { faSyncAlt } from '@fortawesome/pro-solid-svg-icons';

const NewTeamEditor: React.FC = () => {
  const t = useTranslation();
  const history = useHistory();
  const {
    form,
    current,
    vaHandler,
    workHourHandler,
    send,
    onSubmit,
    filterOutSelectedBotMembers,
    handleSelectMembers,
    handlePageChange,
    isCheckBoxDisabled,
    onCheckPublicHoliday,
  } = useNewTeamEditor();
  const { selectedMembers, availableMembers, searchTxt, currentPage, errorMessage } = current.context;
  const MEMBER_COLUMNS = [
    {
      title: 'USERNAME',
      dataIndex: 'username',
      key: 'username',
      width: 200,
      // eslint-disable-next-line react/display-name
      render: (text: string, record: IMember) => {
        return (
          <UsernameWrap>
            <AvatarWrap>
              <Avatar name={record.username} width={32} height={32} memberStatus={record.status} />
            </AvatarWrap>
            <DetailWrap>
              <Typography.Text>{record.username}</Typography.Text>
              <Typography.Text>{record.email}</Typography.Text>
            </DetailWrap>
          </UsernameWrap>
        );
      },
    },
    {
      title: 'PHONE NO.',
      dataIndex: 'phone',
      key: 'phone',
      width: 200,
    },
    {
      title: 'CREATE DATE',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      render: (_: string, { createdAt }: { createdAt: string }, _index: number) => {
        return dayjs(createdAt).format('DD MMM YYYY');
      },
    },
    {
      title: 'ROLE',
      dataIndex: 'role',
      key: 'role',
      width: 200,
      render: (_: string, record: any, _index: number) => {
        return record.roles.join(',');
      },
    },
  ];

  return (
    <Container>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to="/team-management">{t('livechat.team.management.member.editor.breadcrumb.page.before')}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item href="">
          {t('livechat.team.management.team.editor.breadcrumb.page.current.new')}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col span={22} offset={2}>
          <Row>
            <FormHeader>
              <Typography.Title level={3}>{t('livechat.team.management.team.editor.title')}</Typography.Title>
            </FormHeader>

            <Form layout="vertical" onFinish={onSubmit} form={form}>
              <Row gutter={[24, 0]}>
                <Col span={11}>
                  <FormItem
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: t('livechat.team.management.team.editor.name.required'),
                      },
                    ]}
                    label={
                      <>
                        <LabelTitle>{t('livechat.team.management.team.editor.name.label')}</LabelTitle>
                      </>
                    }
                  >
                    <TextInput size="large" placeholder={t('livechat.team.management.team.editor.name.placeholder')} />
                  </FormItem>
                </Col>
                <Col span={11}>
                  <FormItem
                    name="department"
                    label={
                      <>
                        <LabelTitle>{t('livechat.team.management.team.editor.department.label')}</LabelTitle>
                      </>
                    }
                  >
                    <TextInput
                      size="large"
                      placeholder={t('livechat.team.management.team.editor.department.placeholder')}
                    />
                  </FormItem>
                </Col>
              </Row>

              <Row>
                <CheckBoxWrap>
                  <Item>
                    <FormItem noStyle name="default" valuePropName="checked">
                      <Checkbox onChange={filterOutSelectedBotMembers} />
                    </FormItem>
                  </Item>
                  <Item>
                    <LabelTitle>{t('livechat.team.management.team.editor.set.default.label')}</LabelTitle>
                    <Description>{t('livechat.team.management.team.editor.set.default.label.description')}</Description>
                  </Item>
                </CheckBoxWrap>
              </Row>

              <Col span={22}>
                <TableWrap>
                  <Item>
                    <LabelTitle>{t('livechat.team.management.team.editor.add.member.label')}</LabelTitle>
                    <Description>{t('livechat.team.management.team.editor.add.member.description')}</Description>
                  </Item>

                  <SearchWrap>
                    <SearchInput
                      value={searchTxt}
                      size="large"
                      onClear={() => send('SEARCH_TEXT_CHANGE', { value: '' })}
                      onChange={(e: any) => send('SEARCH_TEXT_CHANGE', { value: e.target.value })}
                    />
                  </SearchWrap>
                  <Table
                    scroll={{ y: 'calc(100vh - 350px)' }}
                    columns={MEMBER_COLUMNS}
                    header={<></>}
                    data={availableMembers.rows}
                    rowKey={({ _id }: { _id: string }) => _id}
                    rowSelection={{
                      getCheckboxProps(record) {
                        return {
                          disabled: isCheckBoxDisabled(record),
                        };
                      },
                      type: 'checkbox',
                      onChange: handleSelectMembers,
                      preserveSelectedRowKeys: true,
                      selectedRowKeys: selectedMembers,
                      // getCheckboxProps: (record: any) => ({
                      //   disabled: record.name === 'Disabled User', // Column configuration not to be checked
                      //   name: record.name,
                      // }),
                    }}
                    loading={
                      current.matches('fetchingAvailableMembers') && {
                        indicator: (
                          <LoadingWrap>
                            <LoadingCircle />
                          </LoadingWrap>
                        ),
                      }
                    }
                    pagination={{
                      defaultPageSize: MEMBER_LIST_PAGE_SIZE,
                      current: currentPage,
                      total: availableMembers.total,
                      onChange: handlePageChange,
                      showSizeChanger: false,
                    }}
                  />
                </TableWrap>
              </Col>

              <Row className="mt-[24px]">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row>

              <Row className="mt-[16px]">
                <Col span={22}>
                  <VaBlock
                    firstResponseHandlerProp={{
                      selected: vaHandler.selectedVas.firstResponse,
                      onSelect: function (vaId: string, name: string): void {
                        vaHandler.onSelectActiveVa(vaId, name);
                      },
                    }}
                    coPilotHandlerProp={{
                      selected: vaHandler.selectedVas.copilot,
                      onSelect: function (vaId: string, name: string): void {
                        vaHandler.onSelectPassiveVa(vaId, name);
                      },
                    }}
                  />
                </Col>
              </Row>

              <Row className="mt-[32px]">
                {/* <Col span={22}>
                  <Row gutter={[16, 0]}>
                    <Col>
                      <Item>
                        <FormItem noStyle name="workingHourEnabled" valuePropName="checked">
                          <Switch />
                        </FormItem>
                      </Item>
                    </Col>
                    <Col>
                      <Item>
                        <LabelTitle>{t('livechat.team.management.team.editor.working.hour.label')}</LabelTitle>
                      </Item>
                    </Col>
                  </Row>
                </Col>
                <Col className="mt-[32px]" span={22}>
                  <FormItem shouldUpdate>
                    {() => {
                      return form.getFieldValue('workingHourEnabled') ? (
                        <WorkingHourBlock
                          days={workHourHandler.timeSlotState}
                          onCheckPublicHoliday={onCheckPublicHoliday}
                          publicHolidayTimes={workHourHandler.publicHolidayTimes}
                          onUpdatePublicHolidayTimes={(payload) => {
                            workHourHandler.setPublicHolidayTimes((prev) => {
                              return {
                                ...prev,
                                timeslots: payload,
                              };
                            });
                          }}
                          onUpdateWorkHours={(day, payload) => {
                            workHourHandler.setTimeSlotState((prev) => {
                              return {
                                ...prev,
                                [day]: payload,
                              };
                            });
                          }}
                        />
                      ) : null;
                    }}
                  </FormItem>
                </Col> */}
              </Row>

              <Row>
                <Col span={22}>
                  {errorMessage && (
                    <ToastWrap>
                      <DangerToast align="flex-start">{errorMessage}</DangerToast>
                    </ToastWrap>
                  )}
                </Col>
              </Row>

              <ActionWrap>
                <Button onClick={() => history.push('/team-management')} size="large">
                  {t('livechat.team.management.team.editor.back.btn')}
                </Button>
                <PrimaryButton htmlType="submit" size="large" disabled={current.matches('saving')}>
                  {current.matches('saving') && <LoadingIcon icon={faSyncAlt} />}
                  {t('livechat.team.management.team.editor.submit.btn')}
                </PrimaryButton>
              </ActionWrap>
            </Form>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default NewTeamEditor;
