import styled from 'styled-components/macro';
import InputMask from 'react-input-mask';

export const TimeInput = styled(InputMask)`
  height: 36px;
  width: 100px;
  border: 1px solid ${({ theme }) => theme.GREY_7};
  border-radius: 8px;
  padding: 14px;
  text-align: right;

  ::placeholder {
    font-size: 16px;
    color: ${({ theme }) => theme.GREY_7} !important;
    opacity: 1;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }
`;
