import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const DropdownContainer = styled.div``;

export const TopSection = styled.div`
  width: 100%;
  position: relative;
  height: 70px;
  white-space: nowrap;
  span {
    position: absolute;
    top: 23px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const CloseIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 16px;
  right: 16px;
  color: #9e9e9e;
  font-size: 18px;
  cursor: pointer;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.GREY_7};
`;

export const MonthYearSelectionWarp = styled.div`
  span {
    margin-right: 4px;
  }
  svg {
    cursor: pointer;
    font-size: 18px;
    transform: rotate(0deg);
    transition: all ease-out 0.3s;
  }

  svg.active {
    transform: rotate(-180deg);
  }
`;

export const ArrowWrap = styled.div`
  display: flex;
  column-gap: 16px;
`;

export const SelectionWrap = styled.div`
  margin: 24px 24px 16px 24px;
  display: flex;
  justify-content: space-between;

  button {
    width: 24px !important;
    height: 24px;
  }
`;

export const CalendarContainer = styled.div<{ isShow: boolean }>`
  display: ${({ isShow }) => (isShow ? 'grid' : 'none')};
  margin: 24px auto;
  width: 272px;
  height: 236px;
  grid-template-columns: repeat(7, 32px);
  row-gap: 8px;
  column-gap: 8px;
  text-align: center;
  user-select: none;

  span {
    display: inline-block;
    width: 32px;
  }
`;

export const HeaderItem = styled.div`
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  color: ${({ theme }) => theme.GREY_5};
`;

export const DayItem = styled.button<{
  isSeleced: boolean;
  isToday: boolean;
  isDisabled: boolean;
}>`
  height: 32px;
  color: ${({ isDisabled, isSeleced, theme }) => (isDisabled ? theme.GREY_6 : isSeleced ? 'white' : theme.GREY_1)};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  padding: 6px;
  border-radius: 20px;
  user-select: none;
  align-self: center;
  background-color: ${({ isSeleced, theme }) => (isSeleced ? theme.PRIMARY : 'inherit')};
  border: ${({ isToday, theme }) => (isToday ? `${theme.PRIMARY} 1px solid` : 'none')};

  &:hover {
    background-color: ${({ isDisabled, isSeleced, theme }) =>
      isDisabled ? 'inherit' : !isSeleced ? theme.GREY_5 : ''};
    border: ${({ isToday, isSeleced, theme }) =>
      isToday ? `1px solid ${isSeleced ? theme.PRIMARY : theme.GREY_5}` : ''};
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  }

  transition: all 0.3s;
`;

export const BlankDayItem = styled.div`
  width: 32px;
  height: 32px;
`;

export const BottomSection = styled.div`
  height: 88px;
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
`;

export const Container = styled.div`
  height: 36px;
  /* border: 1px solid ${({ theme }) => theme.GREY_7}; */
  /* border-radius: 8px; */
  display: flex;
  align-items: center;
  padding: 12px;
  position: relative;
  cursor: pointer;
  .picker-prefix {
    svg {
      font-size: 16px;
      color: ${({ theme }) => theme.GREY_6};
    }
  }
`;

export const DatePickerInput = styled.input`
  border: none;
  flex-grow: 1;

  ::placeholder {
    color: ${({ theme }) => theme.GREY_5};
    font-size: 16px;
    font-weight: 400;
  }

  &:focus {
    outline: none;
  }
`;

export const DropDown = styled.div<{ open: boolean }>`
  position: absolute;
  display: block;
  width: 327px;
  border-radius: 12px;
  background-color: white;
  bottom: ${({ open }) => (open ? '56px' : '45px')};
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.16);
  left: 0;
  opacity: ${({ open }) => (open ? 1 : 0)};
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  transition: all 0.2s ease-in;
  z-index: 20;
`;

export const YearSelectContainer = styled.div<{ isShow: boolean }>`
  display: ${({ isShow }) => (isShow ? 'block' : 'none')};
  height: 236px;
`;

export const YearSelectItem = styled.div<{ isSelected: boolean }>`
  height: 44px;
  background-color: ${({ isSelected, theme }) => (isSelected ? theme.GREY_9 : 'white')};
  position: relative;
  cursor: pointer;

  span {
    display: inline-flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
`;

export const ChangeMonthButton = styled.button<{ disabled?: boolean }>`
  width: 24px;
  height: 24px;
  display: flex;

  svg {
    display: block;
    font-size: 18px;
    align-items: center;
    margin: auto !important;
    color: ${({ disabled, theme }) => (disabled ? theme.GREY_7 : theme.GREY_3)} !important;
    :hover {
      cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')} !important;
      color: ${({ disabled, theme }) => (disabled ? theme.GREY_7 : theme.GREY_2)} !important;
    }
  }
`;
